const rpcUrl = 'https://testnet-rpc.monad.xyz/',
    blockExplorers = 'https://testnet.monadexplorer.com/'
export default {
    id: 10143,
    name: 'Monad Testnet',
    network: 'monad-testnet',
    nativeCurrency: {name: 'MON', symbol: 'MON', decimals: 18},
    rpcUrls: {
        default: {
            http: [rpcUrl],
        },
        public: {
            http: [rpcUrl],
        },
    },
    blockExplorers: {
        etherscan: {name: 'Monadtestnet', url: blockExplorers},
        default: {name: 'Monadtestnet', url: blockExplorers},
    },
    contracts: {},
}