import React, {useCallback, useEffect, useRef, useState} from 'react'
import debounce from 'lodash.debounce';
import store from "./store"
import {
    checkData,
    checkData0,
    checkDataArray,
    checkDataBool,
    checkDataFunction,
    checkDataObject,
    checkDataString,
    connectClass,
    dispatch,
    isNotNull,
    isNotNullOrEmpty,
    requireDefault,
    square,
    ternaryArray,
    ternaryObject,
} from 'functions'
import {Navigate, Route, Routes} from 'react-router-dom'
import {
    http,
    WagmiProvider,
    useWriteContract,
    useWatchAsset
} from 'wagmi'
import {
    mainnet, base, linea, scroll, optimism, arbitrum, polygonZkEvm, sepolia
} from 'wagmi/chains'
import blast from "./otherChains/blast";
import bob from "./otherChains/bob";
import taiko from "./otherChains/taiko";
import bnb from "./otherChains/bnb";
import vizing from "./otherChains/vizing";
import vizingTestnet from "./otherChains/vizingTestnet";
import {
    signMessage,
    simulateContract,
    waitForTransactionReceipt,
} from '@wagmi/core'
import {message} from 'antd'
import axios from 'axios'
import Web3 from "web3";
import {divide as npDivide, minus as npMinus, plus as npPlus, times as npTimes} from 'number-precision'
// import SwiperCore, {Autoplay} from 'swiper';
// import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.min.css'
import erc20Abi from './erc20Abi';
// import {formatEther, parseEther} from "viem";
import {ethers} from "ethers";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {WagmiAdapter} from '@reown/appkit-adapter-wagmi'
import {
    createAppKit,
    useAppKit,
    useAppKitAccount,
    useAppKitNetwork
} from '@reown/appkit/react'
import bnbTestnet from "./otherChains/bnbTestnet";
import monadTestnet from "./otherChains/monadTestnet";
import unichainSepolia from "./otherChains/unichainSepolia";

const sessionDev = true,//sessionStorage.getItem('dev'),
    masterChain = sessionDev ? vizingTestnet : vizing,
    prdChains = [base, linea, scroll, arbitrum, optimism, polygonZkEvm, blast, bob, taiko, bnb, mainnet],
    memeSlaveContracts = [sepolia, bnbTestnet, monadTestnet], // sessionDev
    //     ? [sepolia]
    //     : prdChains,
    portfolioPrdOpen = true || sessionDev, likwidMemeTG = 'https://t.me/likwid_fi',
    topSocialLinks = [['twitter', 'https://x.com/likwid_fi'], ['telegram', likwidMemeTG]],
    socialLinks = [['medium', 'https://medium.com/@likwidofficial'], ...topSocialLinks, ['github', 'https://github.com/likwid-op/likwid-contract']], {
        location, open: windowOpen
    } = window,
    searchChain = checkDataString(new URLSearchParams(location.search).get('chain')).toLowerCase(),
    searchChains = [
        ['sep', sepolia],
        ['bsc', bnbTestnet],
        ['mon', monadTestnet],
        ['uni', unichainSepolia],
    ],
    searchChainIsIncludes = searchChains.map(([name]) => name).includes(searchChain),
    chains = (searchChainIsIncludes
        ? searchChains.filter(([name]) => name === searchChain)
        // ? searchChains.sort(([name0], [name1]) => {
        //     if (name0 === searchChain) return -1
        //     if (name1 === searchChain) return 1
        //     return 0
        // })
        : searchChains).map(v => v[1]),
    //[...memeSlaveContracts, ...ternaryArray(portfolioPrdOpen && location.pathname.slice(1).split('/')[0] === 'portfolio', [masterChain])],
    defaultChain = chains[0], chainIds = chains.map(v => v.id), initData = {},
    windowReplaceExternal = path => location.href = path,
    windowReplace = path => {
        const newUrl = new URL(`${location.origin}${path}`)
        searchChainIsIncludes && newUrl.searchParams.set('chain', searchChain)
        location.href = newUrl.href
    },
    projectId = '2006745ac580b61f1610d79f53496c97',
    // wagmiConfig = createConfig({
    //     autoConnect: true,
    //     connectors: w3mConnectors({chains, projectId, version: 2}),
    //     publicClient: configureChains(chains, [w3mProvider({projectId})]).publicClient,
    // }),
    // wagmiConfig = createConfig({
    //     autoConnect: true,
    //     connectors: w3mConnectors({chains, projectId, version: 2}),
    //     publicClient: configureChains(chains, [
    //         jsonRpcProvider({
    //             rpc: chain => ({
    //                 http: require(`./rpc/${chain.id}`).default
    //             })
    //         })
    //     ]).publicClient,
    // }),
    wagmiAdapter = new WagmiAdapter({
        projectId,
        ssr: true,
        networks: chains,
        transports: Object.fromEntries(chains.map(v => [v.id, http(require(`./rpc/${v.id}`).default)]))
    }),
    {wagmiConfig} = wagmiAdapter,
    queryClient = new QueryClient(),
    height = '.6rem', heightPhone = '2rem',
    leaveOut = (str, start = 6, end = 4) => str ? [str.slice(0, start), str.slice(str.length - end)].join('...') : '',
    contractStatuData = () => JSON.parse(store.getState().contractStatuData),
    Oracles = [
        ['No Oracle', `Does not rely on 
third-party oracles`, `Listing of Any Assets permissionless`],
        ['Project-Friendly', 'Dual liquidation mechanism', `Swap-based liquidation mechanism and repayment-based liquidation mechanism`],
        ['LP-Friendly', 'Dynamic fee', `Implementing the Swap-based liquidation mechanism 
will cause the fee to change, and liquidation penalties 
will be sent to LPs.`],
    ],
    Oracles1 = [
        ['Group 427320801', `Truncated price for 
Anti Price manipulation`, `The first implementation uses truncated price proposed by Uniswap as the liq.price to prevent flash loan attacks and price manipulation.`],
        ['cross-ring', 'Structured Liquidity', `Users can freely choose the tokens that can be 
borrowed`],
        ['Group 427320803', 'Enhanced Security', 'All LPs are managed directly by Uniswap v4\'s singleton contracts'],
    ],
    formula1 = `This simple formula bridges the gap between lending and swapping, achieving the unification of the two largest sectors in DeFi.

where:
x,y: Represents the target ERC-20 token quantity.
x',y′: A mapped derivative token created by the protocol.`,
    likwidFormula = [['Uniswap v2', 'K = 20 * 100', 100], ['Likwid formula', 'K = ( 20 + 0 ) * ( 90 + 10 )', 90, 'y’=10']],
    Roadmaps = [['2024 Q4', `Kickoff 
Team Construction
Product Prototype
Testnet Launch`], ['2025 Q1', `Security Audit
Launch β Version on Mainnet
V1 Version Testnet Launch`], ['2025 Q2', `V1 Version Mainnet Launch
DAO Governance & Voting
V2 Product Conception
V2 Version Testnet Launch`], ['2025 Q3', `V2 Version Mainnet Launch
Token Economics Proposals`],],
    lkwidHook = [['Singleton contract', 'Provide important gas savings'], ['Flash accounting( EIP-1153)', 'Bring cleaner contract designs'], ['Native ETH', 'Additional gas savings'],],
    whitepaper = () => windowOpen('https://likwidfi.gitbook.io/likwid-protocol-docs'), defaultPage = 1,
    searchSymbolOnKeyUp = (e, func = () => {
    }) => e.keyCode === 13 && func(), address0 = '0x0000000000000000000000000000000000000000',
    scientificToString = num => {
        try {
            const str = num.toString(); // 将数字转换为字符串形式
            if (!str.includes('e')) {
                // 如果不是科学计数法，直接返回原字符串
                return str;
            }

            const [base, exponent] = str.split('e'); // 分割科学计数法的基数和指数
            const sign = base.startsWith('-') ? '-' : ''; // 检查并保存符号
            const absBase = base.replace('-', '').replace('.', ''); // 去掉负号并移除小数点
            const expValue = parseInt(exponent, 10); // 指数部分

            if (expValue < 0) {
                // 处理负指数：生成小数形式
                const decimals = Math.abs(expValue);
                return `${sign}0.${'0'.repeat(decimals - 1)}${absBase}`;
            } else {
                // 处理正指数：生成大数形式
                const zerosToAdd = expValue - (absBase.length - 1);
                if (zerosToAdd >= 0) {
                    return `${sign}${absBase}${'0'.repeat(zerosToAdd)}`;
                } else {
                    const decimalPointIndex = absBase.length + zerosToAdd;
                    return `${sign}${absBase.slice(0, decimalPointIndex)}.${absBase.slice(decimalPointIndex)}`;
                }
            }
        } catch {
            return '0'
        }
    },
    toInt = num => num.toString().replace(/\.\d*$/, ''),
    trimDecimalZeros = num => num.toString()
        .replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, ''),
    toFixed = (num, decimals = defaultDecimals) =>
        trimDecimalZeros(num.toString().replace(new RegExp(`(\\.\\d{${decimals}})\\d*`), '$1')),
    num8 = num => {
        num = scientificToString(num).replace(/(\.\d{8})\d*/, '$1')
            .replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '')
        return num === '-0' ? '0' : num
    },
    amountReplace = (num = 0, removeZero = true) => {
        num = num.toString().replace(/[^\d.]/g, '').replace(/\.{2,}/g, ".")
            .replace(/^\./g, "").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
            .replace(/^(\-)*(\d+)\.(\d{8}).*$/, '$1$2.$3')
        return removeZero ? num.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1') : num
    },
    percentageReplace = (num, carry) => {
        num = times(num.toString(), 100).replace(/(\.\d{2})\d*/, '$1')
        return carry && num === '99.99' ? '100' : num
    }, defaultDecimals = 18, defaultDecimalsMinus = decimals => 10 ** minus(defaultDecimals, decimals),
    formatEther = (num, decimals = defaultDecimals) => ethers.formatUnits(scientificToString(num).replace(/\.\d*$/, ''), Number(decimals))
        .replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, ''),
    numberETH = (num = 0, decimals = defaultDecimals) => num8(formatEther(num.toString(), decimals)),
    parseEtherNum = (num, decimals = defaultDecimals) => {
        decimals = Number(decimals)
        try {
            return ethers.parseUnits(scientificToString(num).replace(new RegExp(`(\\.\\d{${decimals}})\\d*`), '$1'), decimals)
        } catch {
            return 0n
        }
    },
    apiNumETH = (num, decimals) => formatEther(num, decimals),
    lessThan = (a, b) => parseEtherNum(a) < parseEtherNum(b),
    greaterThan = (a, b) => parseEtherNum(a) > parseEtherNum(b),
    lessThanOrEqualTo = (a, b) => parseEtherNum(a) <= parseEtherNum(b),
    equalTo = (a, b) => parseEtherNum(a) === parseEtherNum(b),
    minBigInt = 0.000001,
    {utils} = Web3,
    hexToNumber = (hex = address0) => utils.hexToNumber(hex),
    plus = (a, b) => formatEther(parseEtherNum(a) + parseEtherNum(b)),
    minus = (a, b) => formatEther(parseEtherNum(a) - parseEtherNum(b)), times = (a, b) => {
        [a, b = ''] = formatEther(parseEtherNum(a) * parseEtherNum(b)).split('.')
        return `${formatEther(a)}${b}`
    }, divide = (a, b) => {
        try {
            return formatEther(parseEtherNum(parseEtherNum(a)) / parseEtherNum(b))
        } catch {
            return '0'
        }
    },
    pageSize = 10,
    needApproveButton = (needApprove, text) => `${needApprove ? 'Approve and ' : ''}${text}`,
    leverageMin = 1, leverageMax = 20,
    defaultDeadlineSecond = 1800,
    percentageNum = num => num.toString().replace(/(\.\d{2})\d*/, '$1'),
    numToPercentage = num => percentageNum(divide(num, 10000)), liquidityLevel = 4,
    Profile = connectClass(props => {
        const pathname = location.pathname.slice(1), pNs = pathname.split('/'), pNs0 = pNs[0], pNs1 = pNs[1],
            isHome = pNs0 === '', isContest = pNs0 === 'contest', isTrade = pNs0 === 'trade',
            isDashboard = pNs0 === 'dashboard',
            isSwap = isTrade && pNs1 === 'swap',
            isMargin = isTrade && !isSwap,
            showPath = isContest ? '' : pNs0,
            isPool = pNs0 === 'pool',
            isPoolCreate = isPool && pNs1 === 'create',
            isPoolAdd = isPool && pNs1 === 'add',
            isPoolIncrease = isPool && pNs1 === 'increase',
            isPoolAddIncrease = isPoolAdd || isPoolIncrease,
            isHandelLiquidity = isPoolCreate || isPoolAddIncrease,
            isPoolRemove = !isHandelLiquidity,
            [messageApi, contextHolder] = message.useMessage(),
            toast = content => messageApi.open({content, style: {marginTop: '1.5rem'}}),
            toastSuccessful = success => toast(success ? 'Successful' : 'Failed'), {isPhoneClientWidth} = store.getState(), [data, stateData] = useState({...initData}),
            copyText = (text = '', func) => {
                const input = document.createElement('input')
                input.value = text
                document.body.appendChild(input)
                input.select()
                document.execCommand('Copy')
                document.body.removeChild(input)
                isNotNull(func) ? func() : toastSuccessful(true)
            },
            setData = (d, clean) => stateData(data => ({...(clean ? {} : data), ...d})),
            navigations = [
                ['Trade', () => windowReplace('/trade')],
                ['Pool', () => windowReplace('/pool')],
                ['Dashboard', () => windowReplace('/dashboard')],
                ['Whitepaper', whitepaper]
            ],
            devCount = checkData0(data, 'devCount'),
            {address} = useAppKitAccount(),
            // address = '0x79347d7207C5c99445E6E386f1CCcbB31bfe3b1B',
            prevAddress = useRef(),
            devCountAdd = () => setData({
                devCount: devCount + 1
            }), showList = checkDataBool(data, 'showList'),
            toggleShowList = () => setData({showList: !showList}),
            {
                open = () => {
                }
            } = checkDataObject(useAppKit()),
            connectLoading = width => checkDataBool(data, 'showConnectLoading') &&
                <div className={'positionFixed flexColumnCenter'}
                     style={{
                         top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'rgba(0,0,0,.56)',
                     }}>
                    <img src={requireDefault('loading')} alt="" style={{width: `${width}rem`}}/>
                </div>,
            {caipNetwork: currentChain} = useAppKitNetwork(),
            currentChainId = checkData([currentChain, 'id'], undefined),
            wrongNetwork = !chainIds.includes(Number(currentChainId)),
            chainValue = wrongNetwork ? defaultChain : currentChain,
            chainId = checkData([chainValue, 'id'], defaultChain.id),
            blockExplorers = chainValue.blockExplorers.default.url,
            isBNBFunc = id => [bnbTestnet, bnb].map(v => v['id']).includes(Number(id)),
            coinName = checkData(
                [
                    {
                        97: bnbTestnet,
                        10143: monadTestnet
                    },
                    chainId,
                    'nativeCurrency',
                    'symbol'
                ],
                'ETH'
            ),
            isShowBorrow = [11155111, 1301].includes(chainId),
            isBorrow = isShowBorrow && isMargin && pNs1 !== 'margin',
            ethAvatar = requireDefault(coinName),
            toggleShowLoading = showLoading => setData({showLoading}),
            showLoading = checkDataBool(data, 'showLoading'),
            loading = showLoading && <div className={'positionFixed flexColumnCenter'}
                                          style={{
                                              top: 0,
                                              left: 0,
                                              right: 0,
                                              bottom: 0,
                                              zIndex: 1000,
                                              background: 'rgba(0,0,0,.56)',
                                          }}>
                <img src={requireDefault('loading')} alt="" style={{width: `${isPhoneClientWidth ? 10 : 5}rem`}}/>
            </div>,
            getSetNewAmount = (num = 0) => amountReplace(num, false),
            urlPools = checkDataArray(data, 'urlPools'),
            // isSelectToken = isSwap || isPoolCreate,
            isNeedToken = isSwap || isPoolCreate,
            isSelectToken = (!isHome && !(isPool && isPoolRemove) && !isDashboard) || isHandelLiquidity,
            isSelectTokenNotNeedToken = isSelectToken && !isNeedToken,
            isAddress0 = address => address === address0,
            ethToken = {
                name: coinName,
                symbol: coinName,
                avatar: ethAvatar,
                tokenAddress: address0,
                decimals: defaultDecimals
            },
            pools = [...urlPools, ...checkDataArray(data, 'pools')].filter((item, index, self) => self.findIndex(obj => obj.poolId === item.poolId) === index),
            poolData = checkData([data, 'poolData'], isPool && isPoolRemove ? {} : checkDataObject(pools, 0)),
            poolName = (v, i) => checkDataString(v, 'name').split('/')[i],
            getPoolData = i => {
                const currency = `currency${i}`,
                    tokenAddress = poolData[currency],
                    avatarKey = `${currency}Avatar`,
                    avatar = isAddress0(tokenAddress) ? ethAvatar : poolData[avatarKey]
                return isNotNull(tokenAddress)
                    ? {avatar, tokenAddress, [avatarKey]: avatar, symbol: poolName(poolData, i)}
                    : {}
            },
            pageTokenKey = index => `${
                isBorrow
                    ? 'borrow'
                    : (
                        isMargin
                            ? 'margin'
                            : (
                                isSwap
                                    ? 'swap'
                                    : (
                                        isPoolCreate
                                            ? 'poolCreate'
                                            : 'poolAdd'
                                    )
                            )
                    )
            }Token${index}${chainId}`,
            token0Data = {
                ...checkData(
                    [data, 'token0Data'],
                    checkData(
                        [JSON.parse(localStorage.getItem(pageTokenKey(0)))],
                        ethToken
                    )
                ),
                ...isNeedToken ? {} : getPoolData(0),
                keyName: 'token0Data'
            },
            {
                tokenAddress: token0Address,
                symbol: token0Symbol,
                newAmount: token0NewAmount,
                needApprove: token0NeedApprove,
                newAmountWei: token0NewAmountWei = 0n,
                decimals: token0Decimals = defaultDecimals
            } = token0Data,
            token0IsETH = isAddress0(token0Address),
            yourTokens = checkDataArray(data, 'yourTokens'),
            recommendTokens = [ethToken, ...checkDataArray(data, 'recommendTokens')],
            searchAddressTokens = checkDataArray(data, 'searchAddressTokens'),
            recentSearchTokens = JSON.parse(checkData([localStorage.getItem(`recentSearchTokens${chainId}`)], '[]')),
            setRecentSearchTokens = (v = {}) => {
                const tokenAddress = checkDataString(v, 'tokenAddress').toLowerCase()
                if (!isAddress0(tokenAddress) && !recentSearchTokens.map(v =>
                    checkDataString(v, 'tokenAddress').toLowerCase()).includes(tokenAddress)) {
                    const obj = {
                        ...v,
                        tokenAddress: checkDataString(v, 'tokenAddress').toLowerCase()
                    }
                    localStorage.setItem(`recentSearchTokens${chainId}`, JSON.stringify([...recentSearchTokens, obj]))
                    return obj
                }
            },
            tokens = checkDataArray(data, 'tokens').filter((item, index, self) => self.findIndex(obj => obj.tokenAddress === item.tokenAddress) === index),
            allTokens = [...tokens, ...yourTokens, ...recommendTokens, ...recentSearchTokens],
            tokenBalances = Object.fromEntries(yourTokens.map(v => [checkDataString(v, 'tokenAddress').toLowerCase(), v.balance])),
            tokenMetadatas = Object.fromEntries(tokens.map(v => [
                checkDataString(v, 'tokenAddress').toLowerCase(),
                {
                    tokenAddress: checkDataString(v, 'tokenAddress').toLowerCase(),
                    ...v
                }
            ])),
            getTokenIcon = tokenAddress => isAddress0(tokenAddress)
                ? ethAvatar
                : (() => {
                    const icon = checkData([tokenMetadatas, checkDataString(tokenAddress).toLowerCase(), 'avatar'])
                    return isNotNull(icon)
                        ? (/^ipfs:\/\/Qm[a-zA-Z0-9]{44}$/.test(icon) ? icon.replace('ipfs://', 'https://ipfs.io/ipfs/') : icon)
                        : undefined
                })(),
            tokenRecentSearchTokens = [...tokens, ...recentSearchTokens],
            tokenRecentSearchTokensMetadatas = Object.fromEntries(tokenRecentSearchTokens.map(v => [
                checkDataString(v, 'tokenAddress').toLowerCase(),
                {
                    tokenAddress: checkDataString(v, 'tokenAddress').toLowerCase(),
                    ...v
                }
            ])),
            token1Data = {
                ...checkData(
                    [data, 'token1Data'],
                    checkData(
                        [JSON.parse(localStorage.getItem(pageTokenKey(1)))],
                        checkDataObject(tokens, 0)
                    )
                ),
                ...isNeedToken ? {} : getPoolData(1),
                keyName: 'token1Data'
            },
            {
                tokenAddress: token1Address,
                symbol: token1Symbol,
                newAmount: token1NewAmount,
                needApprove: token1NeedApprove,
                newAmountWei: token1NewAmountWei = 0n,
                decimals: token1Decimals = defaultDecimals
            } = token1Data,
            token1AddressIsNotNull = isNotNull(token1Address),
            tokenDataNeedApprove = token0NeedApprove || token1NeedApprove,
            setTokenData = (v, obj) => setData({[v.keyName]: {...v, ...obj}}),
            tokenDatas = [token0Data, token1Data],
            isMarginSell = checkData([data, 'isMarginSell'], !isMargin),
            marginTokenDatas = isMarginSell ? tokenDatas : tokenDatas.reverse(),
            marginToken0Data = marginTokenDatas[0],
            marginToken1Data = marginTokenDatas[1], {
                symbol: marginToken0Symbol,
                balance: marginToken0DataBalance = 0,
                tokenAddress: marginToken0TokenAddress,
                needApprove: marginToken0DataNeedApprove,
                decimals: marginToken0DataDecimals = defaultDecimals,
            } = marginToken0Data,
            setNewAmount = (num, decimals = marginToken0DataDecimals) => {
                const newAmount = getSetNewAmount(num)
                setData({
                    inputNewAmount: num,
                    newAmount, newAmountWei: parseEtherNum(newAmount, decimals)
                })
            },
            debounceSetNewAmount = useCallback(
                debounce((value, decimals) => {
                    setNewAmount(value, decimals)
                }, 500),
                []
            ),
            setInputNewAmount = num => {
                setData({inputNewAmount: num})
                debounceSetNewAmount(num, marginToken0DataDecimals)
            },
            setNewAmountWei = newAmountWei => setData({
                newAmountWei, newAmount: numberETH(newAmountWei, marginToken0DataDecimals)
            }),
            token = () => sessionStorage.getItem('token'),
            requestApi = (url, data, method, headers = {}, noError) => {
                const t = token()
                return axios({
                    method,
                    // url: `${sessionDev ? 'https://test-api.likwid.fi/v1/' : 'https://api.likwid.fi/v1/'}${url}`,
                    url: `${false ? 'https://test-api.likwid.fi/v1/' : 'https://api.likwid.fi/v1/'}${url}`,
                    [method ? 'data' : 'params']: data,
                    headers: {
                        ...headers, ...ternaryObject(t, {'Authorization': `Bearer ${t}`})
                    },
                },).then(r => {
                    const d = checkDataObject(r, 'data'),
                        {code} = d,
                        isTokenExpires = code == '-5',
                        isSuccess = code == '1' || isTokenExpires
                    if (!isSuccess) {
                        toast(d.msg)
                        toggleShowLoading()
                    }
                    if (isTokenExpires) {
                        sessionStorage.removeItem('token')
                        toggleShowLoading()
                    }
                    return isSuccess ? checkDataObject(d, 'data') : null
                }).catch(e => {
                    toggleShowLoading()
                    if (!noError) {
                        toast('Unknown error')
                    }
                    // console.error(`------------------------------\n`, e, `------------------------------\n`)
                })
            },
            getEthUSDTPrice = () => requestApi(`tool/${coinName.toLowerCase()}/price`),
            loginThenFunc = (func = () => {
                             },
                             errorFunc = () => {
                             }) => token() ? func() : new Promise(resolve => requestApi('login/code', {address}).then(r => {
                setTimeout(() => {
                    const code = checkData([r, 'code'])
                    signMessage(wagmiConfig, {message: code}).then(sign => requestApi('login/login', {
                        code, sign, address, loginType: 'WEB',
                    }, 'post').then(r => {
                        const token = checkData([r, 'token'])
                        if (token) {
                            sessionStorage.setItem('token', token)
                            resolve(func())
                        }
                    })).catch(e => {
                        errorFunc()
                        toast(checkDataString(checkDataString(e, 'message').split('\n'), 0))
                    })
                }, 1000)
            })),
            chainName = checkData([chainValue, 'name'], defaultChain.name),
            addressIsNotNull = isNotNull(address),
            rpc = require(`./rpc/${chainId}`).default,
            addressLeaveOut = leaveOut(address),
            {eth: slaveContractETH} = new Web3(rpc),
            contractCall = (eth, address, abi, funcName, ...params) => {
                const func = checkData([new eth.Contract(abi, address), 'methods', funcName])
                return func ? func(...params).call() : new Promise(resolve => resolve())
            },
            {
                address: marginHookManagerAddress, abi: marginHookManagerAbi,
            } = require(`./oldContract/marginHookManager${sessionDev ? 'Dev' : ''}/${chainId}`).default,
            marginHookManagerCall = (funcName, ...params) => contractCall(slaveContractETH, marginHookManagerAddress, marginHookManagerAbi, funcName, ...params), {
                address: marginPositionManagerAddress, abi: marginPositionManagerAbi,
            } = require(`./oldContract/marginPositionManager${sessionDev ? 'Dev' : ''}/${chainId}`).default,
            marginPositionManagerCall = (funcName, ...params) => contractCall(slaveContractETH, marginPositionManagerAddress, marginPositionManagerAbi, funcName, ...params),
            {
                address: marginRouterAddress, abi: marginRouterAbi,
            } = require(`./oldContract/marginRouter${sessionDev ? 'Dev' : ''}/${chainId}`).default,
            {
                address: marginFeesAddress, abi: marginFeesAbi,
            } = require(`./oldContract/marginFees${sessionDev ? 'Dev' : ''}/${chainId}`).default,
            {
                address: marginLiquidityAddress, abi: marginLiquidityAbi,
            } = require(`./oldContract/marginLiquidity${sessionDev ? 'Dev' : ''}/${chainId}`).default,
            {
                address: borrowPositionManagerAddress, abi: borrowPositionManagerAbi,
            } = require(`./oldContract/borrowPositionManager${sessionDev ? 'Dev' : ''}/${chainId}`).default,
            marginLiquidityCall = (funcName, ...params) => contractCall(slaveContractETH, marginLiquidityAddress, marginLiquidityAbi, funcName, ...params),
            marginFeesCall = (funcName, ...params) => contractCall(slaveContractETH, marginFeesAddress, marginFeesAbi, funcName, ...params),
            borrowPositionManagerCall = (funcName, ...params) => contractCall(slaveContractETH, borrowPositionManagerAddress, borrowPositionManagerAbi, funcName, ...params),
            erc20Call = (contractAddress, funcName, ...params) => contractCall(slaveContractETH, contractAddress, erc20Abi, funcName, ...params),
            erc20CallName = contractAddress => erc20Call(contractAddress, 'name'),
            erc20CallSymbol = contractAddress => erc20Call(contractAddress, 'symbol'),
            marginHookManagerInterface = new ethers.Interface(marginHookManagerAbi),
            marginPositionManagerInterface = new ethers.Interface(marginPositionManagerAbi),
            erc20CallDecimals = contractAddress => isAddress0(contractAddress)
                ? new Promise(resolve => resolve(defaultDecimals))
                : (() => {
                    const tokenDecimalsList = JSON.parse(checkData([localStorage.getItem(chainId)], '{}')),
                        tokenDecimals = checkData(
                            [tokenDecimalsList, contractAddress],
                            checkData([tokenMetadatas, checkDataString(contractAddress).toLowerCase(), 'decimals'])
                        )
                    return isNotNull(tokenDecimals)
                        ? new Promise(resolve => resolve(tokenDecimals))
                        : erc20Call(contractAddress, 'decimals').then(decimals => {
                            localStorage.setItem(chainId, JSON.stringify({
                                ...tokenDecimalsList, [contractAddress]: Number(decimals)
                            }))
                            return decimals
                        })
                })(),
            erc20CallBalanceOfTokens = () => address && tokenDatas.map(v => {
                    const {tokenAddress} = v
                    return (isAddress0(tokenAddress)
                        ? slaveContractETH.getBalance(address).then(v => [v])
                        : erc20Call(tokenAddress, 'balanceOf', address)).then(balance =>
                        setTokenData(
                            v,
                            {balance: numberETH(balance, v.decimals)}
                        ))
                }
            ),
            erc20CallBalance = contractAddress => isAddress0(contractAddress)
                ? slaveContractETH.getBalance(address).then(v => [v])
                : erc20Call(contractAddress, 'balanceOf', address)
                    .then(balanceOf => erc20CallDecimals(contractAddress).then(decimals => [balanceOf, decimals])),
            openWeb3Modal = (...params) => {
                if (addressIsNotNull) {
                    open(...params)
                } else {
                    const toggleShowConnectLoading = sCL => setData({showConnectLoading: sCL})
                    toggleShowConnectLoading(true)
                    toggleShowConnectLoading()
                    open(...params)
                }
            }, selectNetworkObj = {view: 'Networks'},
            connect = () => openWeb3Modal(),
            selectNetwork = () => openWeb3Modal(selectNetworkObj),
            switchEthereumChain = (func = () => {
            }, falseFunc = () => {
            }, needConnect, chain = {}) => {
                const pleaseConnect = () => {
                    const {name} = chain
                    toast(isNotNullOrEmpty(name) ? `Please connect ${name}` : 'Please connect to the network')
                    setTimeout(() => connect(), 1000)
                }, {id: chainId1} = chain, chainId1IsNotNull = isNotNull(chainId1)
                if (addressIsNotNull) {
                    if ((!chainId1IsNotNull || (chainId1IsNotNull && chainId === chainId1)) && !wrongNetwork) {
                        func()
                    } else {
                        falseFunc()
                        pleaseConnect()
                    }
                } else {
                    falseFunc()
                    if (needConnect) {
                        pleaseConnect()
                    }
                }
            },
            inputNewAmount = checkDataString(data, 'inputNewAmount'),
            newAmountStr = checkDataString(data, 'newAmount'),
            newAmount = checkData0(data, 'newAmount'),
            newAmountNumber = Number(newAmount), showApprove = checkDataBool(data, 'showApprove'),
            toggleShowApprove = showApprove => setData({showApprove}),
            {writeContractAsync} = useWriteContract(),
            wC = (contractAddress, contractAbi, functionName, value, args = [], successFunc = () => {
            }, failFunc = () => {
            }) => {
                console.log(functionName);
                dispatch(props, {contractStatuData: localStorage.getItem('contractStatuData') || '{}'})
                const toggleShowLoadingToast = text => {
                        toggleShowLoading();
                        text && toast(text)
                    },
                    wCFunc = () => {
                        toggleShowLoading(true);
                        simulateContract(
                            wagmiConfig,
                            {
                                args,
                                value,
                                chainId,
                                functionName,
                                account: address,
                                abi: contractAbi,
                                address: contractAddress,
                                walletClient: {account: address},
                            }).then(r =>
                            writeContractAsync(r.request).then(r => {
                                const hash = r
                                return successFunc ? successFunc(hash) : toggleShowLoadingToast(hash ? 'Successful' : 'Failed')
                            })
                                .catch(e => {
                                    const error = e.message.split('\n')[0]
                                    toggleShowLoadingToast(
                                        error.includes('The current chain of the wallet')
                                            ? `Please switch the network to (${chainName}) in the wallet`
                                            : error
                                    )
                                }))
                            .catch(e => {
                                const errorStr = [1, 2].map(v => e.message.split('\n')[v]).join('\n'), obj = {}
                                let eStr
                                Object.keys(obj).map(v => {
                                    if (errorStr.includes(v)) eStr = obj[v]
                                })
                                toggleShowLoadingToast(eStr || errorStr)
                                checkDataFunction(failFunc)()
                            })
                    }
                switchEthereumChain(() => {
                    if (Object.values(checkDataObject(contractStatuData(), chainId, address)).map(v => v.functionName).includes(functionName)) {
                        setData({showPreviousTips: true, previousTipsFunc: wCFunc})
                    } else {
                        wCFunc()
                    }
                }, toggleShowLoadingToast, true)
            },
            waitForTransactionFunc = (hash, func, errFunc = () => {
            }) =>
                waitForTransactionReceipt(wagmiConfig, {chainId, hash, timeout: 60000}).then(func)
                    .catch(() => {
                        errFunc()
                        toastSuccessful()
                        toggleShowLoading()
                    }),
            marginHookManagerWriteContract = (...params) => wC(marginHookManagerAddress, marginHookManagerAbi, ...params),
            marginPositionManagerWriteContract = (...params) => wC(marginPositionManagerAddress, marginPositionManagerAbi, ...params),
            marginRouterWriteContract = (...params) => wC(marginRouterAddress, marginRouterAbi, ...params),
            borrowPositionManagerWriteContract = (...params) => wC(borrowPositionManagerAddress, borrowPositionManagerAbi, ...params),
            pageFootPhone = <div className={'paddingPhone childrenSpacingTopPhone'}>
                <div className={'flexCenter justifyContentSpaceBetween'}>
                    <div onClick={devCountAdd}
                         className={'textAlignCenter smallFontSizePhone'}
                    >
                        © All rights reserved, 2024
                    </div>
                    <div className={'flex childrenSpacingLeftPhone'}>
                        {socialLinks.map(v => <img src={requireDefault(`${v[0]}3`)} alt="" className={'cursorPointer'}
                                                   onClick={() => windowOpen(v[1])}
                                                   style={{width: '1.7rem'}}/>)}
                    </div>
                </div>
            </div>,
            launchAPPConnect = () => sessionDev ? (isHome ? windowReplace('/trade') : connect()) : toast('Sepolia will launch in December. Participate to receive an airdrop of leveraged positions.'),
            isTop = checkData([data, 'isTop'], true), isTopColor = `isTop${isTop ? 'Transparent' : 'White'}`,
            walletImageUrl = localStorage.getItem('@w3m/connected_wallet_image_url'),
            showPositionsPools = checkDataBool(data, 'showPositionsPools'),
            selectNetworkContent = address && !isHome && <div onClick={selectNetwork}
                                                              className={`borderRadius999 saf backgroundGrey flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeft'} cursorPointer`}
                                                              style={{
                                                                  height: `${isPhoneClientWidth ? 1.7 : .8}rem`,
                                                                  padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                                              }}>
                {!wrongNetwork && <img src={requireDefault(`chainIcons/${chainId}`)}
                                       alt=""
                                       style={{height: '70%'}}/>}
                <div
                    className={`${wrongNetwork ? 'colorRed' : ''} ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}>{wrongNetwork ? 'Wrong network' : chainName}</div>
                <img src={requireDefault('toBottom')} alt="" style={{height: `${isPhoneClientWidth ? 50 : 30}%`}}/>
            </div>,
            showSelectToken = checkDataBool(data, 'showSelectToken'),
            toggleShowSelectToken = showSelectToken => setData({showSelectToken}),
            showMarginSelling = checkDataBool(data, 'showMarginSelling'),
            showRepaying = checkDataBool(data, 'showRepaying'), showAdjust = checkDataBool(data, 'showAdjust'),
            ethUSDT = checkData0(data, 'ethUSDT'),
            getTokenUSDTPrice = (amount, eU = ethUSDT) => `$${times(amount, eU).replace(/(\.\d{2})\d*/, '$1')}`,
            toggleShowCloseRepay = (show, showKey, repayTokenData) => {
                toggleShowLoading(show);
                (show ? (() => {
                    const {positionId} = repayTokenData, isShowAdjust = showKey === 'showAdjust'
                    return erc20CallBalance(repayTokenData.borrowToken).then(async ([balance, decimals]) => {
                        setData({
                            ...showKey === 'showClosePosition' ? {range: 0} : {}, repayTokenData: {
                                ...repayTokenData, ...isShowAdjust ? await (async () => {
                                    const marginBalance = numberETH((await erc20CallBalance(repayTokenData.marginToken))[0], repayTokenData.marginDecimals),
                                        maxDecrease = numberETH(await marginPositionManagerCall('getMaxDecrease', positionId).catch(() => 0n), repayTokenData.marginDecimals)
                                    return {
                                        marginBalance,
                                        marginBalancePrice: getTokenUSDTPrice(isAddress0(repayTokenData.marginToken) ? marginBalance : numberETH(await marginHookManagerCall('getAmountOut', repayTokenData.poolId, repayTokenData.marginForOne, parseEtherNum(marginBalance, repayTokenData.marginDecimals)).catch(() => 0n), repayTokenData.borrowDecimals)),
                                        maxDecrease,
                                        maxDecreasePrice: getTokenUSDTPrice(isAddress0(repayTokenData.marginToken) ? maxDecrease : numberETH(await marginHookManagerCall('getAmountOut', repayTokenData.poolId, repayTokenData.marginForOne, parseEtherNum(maxDecrease, repayTokenData.marginDecimals)).catch(() => 0n), repayTokenData.borrowDecimals))
                                    }
                                })() : {}
                            }, showLoading: false
                        })
                    })
                })() : new Promise(resolve => resolve())).then(() => setData({
                    [showKey]: show, ...!show ? {
                        showApprove: false,
                        repayTokenDataNewAmount: undefined,
                        repayTokenDataNewAmountWei: undefined
                    } : {},
                }))
            },
            toggleShowAdjust = (showAdjust, repayTokenData) => toggleShowCloseRepay(showAdjust, 'showAdjust', repayTokenData),
            toggleShowRepaying = (showRepaying, repayTokenData) => toggleShowCloseRepay(showRepaying, 'showRepaying', repayTokenData),
            showClosePosition = checkDataBool(data, 'showClosePosition'),
            toggleShowClosePosition = (showClosePosition, repayTokenData) => toggleShowCloseRepay(showClosePosition, 'showClosePosition', repayTokenData),
            showSwapping = checkDataBool(data, 'showSwapping'),
            tokenIcon = (v = {}, size) => {
                const style = square(size)
                return isNotNullOrEmpty(v.poolId)
                    ? <div style={style} className={'flex'}>
                        {['', 'justifyContentFlexEnd'].map((v1, i1) => {
                            const currencyName = checkData([v, `currency${i1}Name`], checkDataString(v, 'name').split('/')[i1]),
                                icon = (isAddress0(v[`currency${i1}`]) || currencyName === coinName)
                                    ? ethAvatar
                                    : getTokenIcon(v[`currency${i1}`])
                            return <div className={`overflowHidden flex ${v1}`}
                                        style={{
                                            width: `calc(${size} / 2 )`,
                                            height: size, ...i1 === 0 ? {} : {marginLeft: `calc(${size} / 15 )`}
                                        }}>
                                {
                                    isNotNull(icon)
                                        ? <img className={'borderRadius999 flexShrink0'} src={icon} alt=""
                                               style={style}/>
                                        : <div style={{...style, fontSize: `calc(${size} / 6 )`}}
                                               className={`flexCenter ${[
                                                   'justifyContentFlexStart',
                                                   'justifyContentFlexEnd'
                                               ][i1]} backgroundGrey borderRadius999 flexShrink0 saf1`}>{
                                            checkData([v, `currency${i1}Symbol`], currencyName).slice(0, 3)
                                        }</div>
                                }
                            </div>
                        })}
                    </div>
                    : (() => {
                        const icon = getTokenIcon(v.tokenAddress)
                        return isNotNull(icon)
                            ? <img className={'borderRadius999'} src={icon} alt="" style={style}/>
                            : <div style={{...style, fontSize: `calc(${size} / 3 )`}}
                                   className={'flexCenter backgroundGrey borderRadius999 saf1'}>{
                                checkData([v, 'symbol'], checkDataString(v, 'name')).slice(0, 3)
                            }</div>
                    })()

            },
            tokenDataPoolName = tokenDatas.map(v => v.symbol).join('/'),
            reserve0 = checkData0(data, 'reserve0'),
            reserve1 = checkData0(data, 'reserve1'), swapReserve0 = checkData0(data, 'swapReserve0'),
            reserve0DivideReserve1 = divide(numberETH(reserve0, token0Decimals), numberETH(reserve1, token1Decimals)),
            swapReserve1 = checkData0(data, 'swapReserve1'),
            setTokenNewAmount = (v, balance, setOther = true, amountWei) => {
                const amountWeiIsNotNull = isNotNull(amountWei), {decimals} = v,
                    newAmount = amountWeiIsNotNull ? numberETH(amountWei, decimals) : getSetNewAmount(balance),
                    newAmountWei = amountWeiIsNotNull ? amountWei : parseEtherNum(newAmount, decimals)
                if (isNotNull(balance) || amountWeiIsNotNull) {
                    setTokenData(v, {newAmount, newAmountWei})
                } else {
                    setTokenData(v, {newAmount: undefined, newAmountWei: undefined})
                }
                if (setOther && (!isSelectToken || isPoolAddIncrease)) {
                    const ratio = divide(reserve0, reserve1)
                    if (ratio != 0) {
                        const isToken0 = v.keyName === 'token0Data',
                            num = toInt(isToken0 ? divide(newAmountWei, ratio) : times(newAmountWei, ratio))
                        setTokenNewAmount(isToken0 ? token1Data : token0Data, undefined, false, Number(num) === 0 ? undefined : num)
                    }
                }
            },
            newAmountWei = checkData([data, 'newAmountWei'], 0n),
            newBorrowAmount = checkData0(data, 'newBorrowAmount'),
            newBorrowAmountWei = checkData([data, 'newBorrowAmountWei'], 0n),
            minMarginLevel = checkData0(data, 'minMarginLevel'),
            borrowMaxAmount = checkData0(data, 'borrowMaxAmount'),
            borrowMaxAmountToast = (func = () => {
            }) => {
                if (greaterThan(newBorrowAmount, borrowMaxAmount)) {
                    toast(`The maximum borrow amount is  ${borrowMaxAmount}`)
                } else {
                    func()
                }
            },
            marginToken0IsETH = isAddress0(marginToken0TokenAddress), {
                symbol: marginToken1Symbol,
                tokenAddress: marginToken1TokenAddress,
                decimals: marginToken1DataDecimals = defaultDecimals,
            } = marginToken1Data, marginToken1IsETH = isAddress0(marginToken1TokenAddress),
            zeroForOne = hexToNumber(marginToken1TokenAddress) > hexToNumber(marginToken0TokenAddress),
            marginForOne = !zeroForOne, marginMaxLeverages = ['1x', '2x', '3x', '4x', '5x'],
            otherMarginMaxLeverage = checkData0(data, 'otherMarginMaxLeverage') || 0,
            marginMaxLeverageIndex = checkData0(data, 'marginMaxLeverageIndex'),
            currentLeverages = marginMaxLeverages[marginMaxLeverageIndex],
            leverage = Number(currentLeverages.split('x')[0]), marginMax = checkData0(data, 'marginMax'),
            erc20MarginAllowance = (marginAddress, contractAddress, amount) =>
                isAddress0(contractAddress)
                    ? new Promise(resolve => resolve(0))
                    : erc20Call(contractAddress, 'allowance', address, marginAddress)
                        .then(v => erc20CallDecimals(contractAddress).then(decimals => minus(amount, v) > 0 ? amount : 0)),
            erc20MarginPositionManagerAllowance = (...params) => erc20MarginAllowance(marginPositionManagerAddress, ...params),
            erc20MarginRouterAllowance = (...params) => erc20MarginAllowance(marginRouterAddress, ...params),
            erc20BorrowPositionAllowance = (...params) => erc20MarginAllowance(borrowPositionManagerAddress, ...params),
            marginToken0Allowance = () => (
                isBorrow
                    ? erc20BorrowPositionAllowance
                    : (isMargin ? erc20MarginPositionManagerAllowance : erc20MarginRouterAllowance)
            )(marginToken0TokenAddress, newAmountWei).then(num => {
                setTokenData(marginToken0Data, {needApprove: num > 0})
                return num
            }),
            toggleShowMarginSwap = (key, show) => (show ? (() => {
                toggleShowLoading(true)
                return (!marginToken0IsETH ? marginToken0Allowance()
                    : new Promise(resolve => resolve())).then(() => toggleShowLoading())
            })() : new Promise(resolve => resolve())).then(() => setData({[key]: show, ...!show ? {showApprove: false} : {}})),
            toggleShowSwapping = showSwapping => {
                const func = () => toggleShowMarginSwap('showSwapping', showSwapping)
                showSwapping
                    ? switchEthereumChain(func, undefined, true)
                    : func()
            },
            toggleShowMarginSelling = showMarginSelling => {
                const func = () => toggleShowMarginSwap('showMarginSelling', showMarginSelling)
                showMarginSelling
                    ? switchEthereumChain(func, undefined, true)
                    : func()
            },
            transitionPrice = checkData0(data, 'transitionPrice'),
            transitionPriceWei = checkData([data, 'transitionPriceWei'], 0n),
            poolDataPoolId = checkDataString(poolData, 'poolId'),
            isETHPool = tokenDatas.map(v => v.tokenAddress).includes(address0),
            getAmountOutFunc = (amount = parseEtherNum(1), zFO = zeroForOne, pI = poolDataPoolId) => marginHookManagerCall('getAmountOut', pI, zFO, amount).catch(() => 0n),
            getAmountOut = (amount, zFO, pI, decimals) => getAmountOutFunc(amount, zFO, pI).then(v => numberETH(v, decimals)),
            getAmountInFunc = (amount = parseEtherNum(1), zFO = zeroForOne, pI = poolDataPoolId) => marginHookManagerCall('getAmountIn', pI, zFO, amount).catch(() => 0n),
            getAmountIn = (amount, zFO, pI, decimals) => getAmountInFunc(amount, zFO, pI).then(v => numberETH(v, decimals)),
            focusInput = React.createRef(),
            autoMaxSlippage = 0.5,
            maxSlippage = checkData([data, 'maxSlippage'], autoMaxSlippage),
            maxSlippageAmount = (amount, mS = maxSlippage) => BigInt(toInt(times(amount, divide(minus(100, mS), 100)))),
            marginTotal = checkData0(data, 'marginTotal'),
            borrowAmount = checkData0(data, 'borrowAmount'),
            poolDataPoolIdIsNotNull = isNotNullOrEmpty(poolDataPoolId),
            swapMax = poolDataPoolIdIsNotNull ? checkData0(data, 'swapMax') : 0,
            marginIsComposite = newAmountNumber <= marginMax,
            leverageIsComposite = leverage >= leverageMin && leverage <= leverageMax,
            liquidationLTV = checkData0(data, 'liquidationLTV'), liquidationLTVNum = divide(liquidationLTV, 100),
            beforeAfterMergedIndex = checkData0(data, 'beforeAfterMergedIndex'),
            isAfterMerged = beforeAfterMergedIndex === 1,
            marginTotalETH = numberETH(marginTotal, marginToken0DataDecimals),
            positionMarginAmount = checkData0(data, 'positionMarginAmount'),
            afterMarginTotal = plus(marginTotal, checkData0(data, 'positionMarginTotal')),
            afterBorrowAmount = plus(borrowAmount, checkData0(data, 'positionBorrowAmount')),
            showMarginTotalETH = isAfterMerged ? numberETH(afterMarginTotal, marginToken0DataDecimals) : marginTotalETH,
            borrowAmountETH = numberETH(borrowAmount, marginToken1DataDecimals),
            showBorrowAmountETH = isAfterMerged ? numberETH(afterBorrowAmount, marginToken1DataDecimals) : borrowAmountETH,
            entryToken = poolDataPoolIdIsNotNull ? checkData0(data, 'entryToken') : 0,
            liqToken = (() => {
                const num = divide(plus(newAmount, marginTotalETH), times(borrowAmountETH, 1.1))
                return num8(isMarginSell ? num : divide(1, num))
            })(),
            showLiqToken = isAfterMerged ? (() => {
                const num = divide(plus(
                    plus(
                        newAmount,
                        numberETH(positionMarginAmount, marginToken0DataDecimals)
                    ),
                    showMarginTotalETH
                ), times(showBorrowAmountETH, 1.1))
                return num8(isMarginSell ? num : divide(1, num))
            })() : liqToken,
            showMarginEntryToken = isAfterMerged
                ? num8(
                    divide(
                        ...isNotNullOrEmpty(newAmountStr)
                            ? (() => {
                                const arr = [showBorrowAmountETH, showMarginTotalETH]
                                return isMarginSell ? arr.reverse() : arr
                            })()
                            : [numberETH(reserve0, token0Decimals), numberETH(reserve1, token1Decimals)]
                    )
                ) : entryToken,
            marginEntryPrice = isETHPool ? `(${getTokenUSDTPrice(!token0IsETH ? checkData0(data, 'entryTokenETH') : entryToken)})` : '',
            showMarginData = poolDataPoolIdIsNotNull && newAmountNumber > 0 && marginIsComposite,
            getMarginLevel = v => [
                ['marginLevelRed', 'marginLevelYellow', 'marginLevelGreen'],
                ['#FF0000', '#FFDD29', '#22C55F']
            ].map(v1 => v1[lessThan(v, 1.17) ? 0 : (lessThan(v, 1.97) ? 1 : 2)]),
            apy = `${checkData0(data, 'apy')}%`,
            borrowMarginLevel = checkData0(data, 'borrowMarginLevel'),
            marginData = isDetail => {
                const marginEntryToken = isDetail ? entryToken : showMarginEntryToken,
                    marginLevelText = `${isBorrow ? '' : 'Initial '}Margin Level`,
                    mD = [
                        [
                            marginLevelText,
                            isBorrow
                                ? borrowMarginLevel
                                : toFixed(
                                    divide(
                                        plus(
                                            plus(
                                                parseEtherNum(newAmount, marginToken0DataDecimals),
                                                (isDetail || !isAfterMerged) ? 0 : positionMarginAmount
                                            ),
                                            (isDetail || !isAfterMerged) ? marginTotal : afterMarginTotal
                                        ),
                                        times(
                                            divide(
                                                ...marginForOne ? [reserve1, reserve0] : [reserve0, reserve1]
                                            ),
                                            (isDetail || !isAfterMerged) ? borrowAmount : afterBorrowAmount
                                        )
                                    ),
                                    2
                                )
                        ],
                        ...isBorrow
                            ? []
                            : [
                                ['Liquidation Margin Level', /*`${liquidationLTV}%`*/'1.1'],
                                [`Total (Using Margin ${currentLeverages})`, `${isDetail ? marginTotalETH : showMarginTotalETH} ${marginToken0Symbol}`],
                                ['Borrow Amount', `${isDetail ? borrowAmountETH : showBorrowAmountETH} ${marginToken1Symbol}`],
                                ...isDetail ? [['Entry Price', `${marginEntryToken} ${token0Symbol}`, isAfterMerged && !isDetail ? (isETHPool ? `(${getTokenUSDTPrice(!token0IsETH ? checkData0(data, 'entryTokenETH') : (isDetail ? marginEntryToken : showMarginEntryToken))})` : '') : marginEntryPrice]] : [],
                                [
                                    'Liq.Price',
                                    `${isDetail ? liqToken : showLiqToken} ${token0Symbol}`,
                                    isETHPool ? `(${getTokenUSDTPrice(token0IsETH ? (isDetail ? liqToken : showLiqToken) : checkData0(data, isDetail ? 'liqTokenETH' : 'showLiqTokenETH'))})` : ''
                                ],
                                ['APY', apy],
                                (() => {
                                    const fee = checkData0(data, 'fee'), feeNum = divide(fee, 100),
                                        feeAmount = num8(times(newAmount, feeNum))
                                    return [`Fee (${fee}%)`, `${feeAmount} ${marginToken0Symbol}`, isETHPool ? `(${getTokenUSDTPrice(marginToken0IsETH ? feeAmount : num8(times(transitionPrice, feeNum)))})` : '']
                                })()
                            ],
                        ['Max Slippage', `${maxSlippage}%`]
                    ]
                return mD.map((v, i) => {
                    const isMarginLevel = v[0] === marginLevelText,
                        [marginLevelIcon, marginLevelColor] = getMarginLevel(v[1])
                    return <div
                        style={{opacity: (showMarginData || isBorrow) ? 1 : 0}}
                        className={'flexCenter justifyContentSpaceBetween'}>
                        <div
                            className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[0]}</div>
                        <div
                            className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                            {isMarginLevel && <img
                                src={requireDefault(marginLevelIcon)}
                                style={{
                                    width: `${isPhoneClientWidth ? 0.9 : 0.3}rem`,
                                    height: `${isPhoneClientWidth ? 0.9 : 0.3}rem`
                                }}/>}
                            {i === mD.length - 1 && maxSlippage == autoMaxSlippage && <div
                                className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>Auto</div>}
                            <div
                                style={isMarginLevel ? {color: marginLevelColor} : {}}
                                className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[1]}</div>
                            {isNotNull(v[2]) && <div
                                className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[2]}</div>}
                        </div>
                    </div>
                })
            },
            ovl = (v, key) => {
                const value = checkData0(v, key)
                return [v.currency0, v.currency1].includes(address0) ? getTokenUSDTPrice(formatEther(value)) : `${num8(apiNumETH(value, v.currency0Decimals))} ${checkDataString(v, 'currency0Symbol')}`
            },
            apr = v => numToPercentage(checkData0(v, 'apr')),
            opacityShow = checkData([data, 'opacityShow'], true),
            setNewBorrowAmount = (newAmount, borrowMaxAmount) => setData({
                newBorrowAmount: newAmount,
                newBorrowAmountWei: parseEtherNum(newAmount, marginToken1DataDecimals),
                ...isNotNull(borrowMaxAmount)
                    ? {borrowMaxAmount}
                    : {}
            }),
            selectTokenContainer = <div onClick={() => toggleShowSelectToken(true)}
                                        className={`${
                                            isPhoneClientWidth ?
                                                `${isSelectToken ? '' : 'borderSmallGrayLinePhone'}`
                                                : `${isSelectToken ? '' : 'borderSmallGrayLine'}`
                                        } ${isPhoneClientWidth ? 'borderRadius999' : 'borderRadius'} 
                                        ${isSelectToken ? (isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft') : ''} flexCenter childrenFlexGrow1 cursorPointer`}>
                {((isMargin && !isMarginSell) ? tokenDatas.reverse() : tokenDatas).map((v, i) =>
                    <div
                        onClick={() => setData({selectTokenIndex: i})}
                        style={{height: `${isPhoneClientWidth ? 3 : 1}rem`}}
                        className={`flexCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'paddingLeftRightBigPhone' : 'paddingLeftRightBig'} 
                                                            ${isSelectToken ? `borderRadius999 ${i === 0 ? 'backgroundGrey' : ''} 
                                                            ${isPhoneClientWidth ? 'borderSmallGrayLinePhone' : 'borderSmallGrayLine'}` : ''}`}>
                        {!isSelectToken && <div
                            className={i !== 0 ? 'borderLeftSmallGrayLine' : ''}
                            style={{height: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>}
                        <div
                            className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                            {tokenIcon(v, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                            <div>
                                <div
                                    className={isPhoneClientWidth ? 'bigFontSizePhone' : ''}>{v.symbol}</div>
                                {i !== 0 && isPoolCreate && <div
                                    className={`flex alignItemsCenter`}>
                                    <div
                                        style={{fontSize: `${isPhoneClientWidth ? .45 : .15}rem`}}
                                        className={'activeColor smallerFontSize'}>
                                        Quote Token
                                    </div>
                                    <img
                                        src={requireDefault('Question mark 1')}
                                        alt=""
                                        style={square(`${isPhoneClientWidth ? .45 : .15}rem`)}/>
                                </div>}
                            </div>
                        </div>
                        {(isSelectToken || i !== 0) ?
                            <img src={requireDefault('toBottom')}
                                 alt=""
                                 style={{width: `${isPhoneClientWidth ? 1 : .25}rem`}}/> :
                            <div/>}
                    </div>)}
            </div>,
            marginPositionId = checkData0(data, 'marginPositionId'),
            swapFee = (() => {
                const a = 3000, b = divide(minus(checkData0(data, '_fee'), a), 10000),
                    a1 = divide(a, 10000),
                    fee = plus(a1, b)
                return [
                    <div className={'flexCenter justifyContentSpaceBetween'}>
                        <div
                            className={`flexCenter colorGray ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone smallFontSizePhone' : 'childrenSpacingLeftSmall smallFontSize'}`}>
                            <div
                                className={`flexCenter ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>Fee
                                ({a1}%{
                                    greaterThan(b, 0) && <pre
                                        className={`colorRed ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}> + {b}%</pre>
                                })
                            </div>
                            <img
                                onClick={() => windowOpen('https://likwidfi.gitbook.io/likwid-protocol-docs/likwid.fi-whitepaper/4.-risk-management-and-strategies/dynamic-fee-strategy-against-mev-and-arbitrage-attacks')}
                                src={requireDefault('Question mark 1')} alt=""
                                className={'cursorPointer'}
                                style={square(`${isPhoneClientWidth ? .9 : .3}rem`)}/>
                        </div>
                        <div
                            className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                            {isETHPool ? getTokenUSDTPrice(times(marginToken0IsETH ? newAmount : transitionPrice, divide(fee, 100))) : ''}
                        </div>
                    </div>,
                    <div className={'flexCenter justifyContentSpaceBetween'}>
                        <div
                            className={`flexCenter colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                            Max Slippage
                        </div>
                        <div
                            className={`flexCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                            {maxSlippage == autoMaxSlippage && <div
                                className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>Auto
                            </div>}
                            <div
                                className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{maxSlippage}%
                            </div>
                        </div>
                    </div>
                ]
            })(),
            marginSwap = (() => {
                const unitPrice = checkData0(data, 'unitPrice'), uint = 1,
                    getTokenUSDTUintPrice = getTokenUSDTPrice(marginToken0IsETH ? unitPrice : uint),
                    tokenUSDTPrice = isETHPool ? getTokenUSDTPrice(marginToken0IsETH ? newAmount : transitionPrice) : '',
                    beforeAfterMerged = ['Before Merged', 'After Merged']
                return [
                    ...isShowBorrow
                        ? [
                            [
                                'Borrow',
                                () => {
                                    const showMarginMaxLeverages = checkDataBool(data, 'showMarginMaxLeverages'),
                                        toggleShowMarginMaxLeverages = () => setData({showMarginMaxLeverages: !showMarginMaxLeverages}),
                                        toggleMarginMaxLeverageIndex = marginMaxLeverageIndex => setData({marginMaxLeverageIndex}),
                                        borrowMaxBalance = lessThanOrEqualTo(marginToken0DataBalance, marginMax) ? marginToken0DataBalance : marginMax
                                    return [
                                        <div style={isPhoneClientWidth ? {} : {borderRadius: '.3rem'}}
                                             className={`backgroundWhite ${isPhoneClientWidth ? 'borderSmallGrayLinePhone paddingBigPhone childrenSpacingTopSmallPhone borderRadiusPhone' : 'borderSmallGrayLine paddingBig childrenSpacingTopSmall'}`}
                                        >
                                            <div
                                                className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div
                                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                                        Liquidity Pool TVL
                                                    </div>
                                                    <div
                                                        className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>{ovl(poolData, 'tvl')}</div>
                                                </div>
                                                {selectTokenContainer}
                                            </div>
                                            {/*<div className={'flexCenter justifyContentSpaceBetween'}>*/}
                                            {/*    <div onClick={() => toggleShowSelectToken(true)}*/}
                                            {/*         className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} cursorPointer`}>*/}
                                            {/*        {tokenIcon(poolData, `${isPhoneClientWidth ? 2.4 : .8}rem`)}*/}
                                            {/*        <div className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>*/}
                                            {/*            {poolData.name}*/}
                                            {/*        </div>*/}
                                            {/*        <img src={requireDefault('toBottom')} alt=""*/}
                                            {/*             style={{width: `${isPhoneClientWidth ? .8 : .2}rem`}}/>*/}
                                            {/*    </div>*/}
                                            {/*    <div*/}
                                            {/*        className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>{ovl(poolData, 'tvl')}</div>*/}
                                            {/*</div>*/}
                                            <img src={requireDefault('Line 2')} alt="" className={'width100'}/>
                                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                                {
                                                    [
                                                        ['APY', poolDataPoolIdIsNotNull ? apy : '0%'],
                                                        [
                                                            'Price',
                                                            `${entryToken} ${token0Symbol}`,
                                                            opacityShow ? 'opacityShow' : 'opacityHidden',
                                                            isETHPool && <div
                                                                className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                                                {marginEntryPrice}
                                                            </div>,
                                                        ]
                                                    ].map(v =>
                                                        <div
                                                            className={isPhoneClientWidth ? 'childrenSpacingTopSmallPhone' : 'childrenSpacingTopSmall'}>
                                                            <div
                                                                className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                                                {v[0]}
                                                            </div>
                                                            <div
                                                                className={`flex alignItemsCenter ${checkDataString(v, 2)} ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                                <div
                                                                    className={isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'}>
                                                                    {v[1]}
                                                                </div>
                                                                {v[3]}
                                                            </div>
                                                        </div>)
                                                }
                                            </div>
                                        </div>,
                                        <div
                                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone childrenFlexGrow1' : 'childrenSpacingLeft'}`}>
                                            {
                                                [
                                                    [token0Data, token0Symbol, false],
                                                    [token1Data, token1Symbol, true]
                                                ].map(v => {
                                                        const iMS = v[2], isCurrent = isMarginSell === iMS
                                                        return <div
                                                            className={`flexCenter borderRadius999 cursorPointer ${
                                                                isCurrent
                                                                    ? 'backgroundActiveColor'
                                                                    : (isPhoneClientWidth ? 'borderSmallGrayLinePhone' : 'borderSmallGrayLine')
                                                            } 
                                                        ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}
                                                            style={{padding: `${isPhoneClientWidth ? .45 : .15}rem ${isPhoneClientWidth ? 0 : .5}rem`}}
                                                            onClick={() => setData({isMarginSell: iMS})}
                                                        >
                                                            {tokenIcon(v[0], `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                                            <div
                                                                className={`${isCurrent ? 'colorWhite saf1' : 'colorGray'}`}>Borrow {v[1]}</div>
                                                        </div>
                                                    }
                                                )
                                            }
                                        </div>,
                                        <div className={'colorGray'}>Supply</div>,
                                        <div
                                            className={`backgroundGrey ${isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopPhone borderRadiusPhone' : 'paddingBig childrenSpacingTopBig'}`}
                                            style={isPhoneClientWidth ? {} : {borderRadius: '.3rem'}}
                                        >
                                            <div
                                                className={`flex alignItemsCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                <input type="text"
                                                       ref={focusInput}
                                                       placeholder='0'
                                                       value={inputNewAmount}
                                                       onChange={e => setInputNewAmount(e.target.value)}
                                                       className={`${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'} flexGrow1`}/>
                                                <div
                                                    className={`borderRadius999 saf backgroundWhite flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeft'}`}
                                                    style={{
                                                        height: `${isPhoneClientWidth ? 2.4 : .8}rem`,
                                                        padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                                    }}>
                                                    {tokenIcon(marginToken0Data, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                                    <div>{marginToken0Symbol}</div>
                                                </div>
                                            </div>
                                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                                <div
                                                    className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                                    {tokenUSDTPrice}
                                                </div>
                                                <div
                                                    className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                    <div
                                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                                        {borrowMaxBalance} {marginToken0Symbol}
                                                    </div>
                                                    <div
                                                        style={{fontSize: `${isPhoneClientWidth ? .6 : .15}rem`}}
                                                        onClick={() => setNewAmount(borrowMaxBalance)}
                                                        className={`backgroundActiveColor ${isPhoneClientWidth ? 'paddingSmallPhone' : 'paddingSmall'} borderRadius999 colorWhite cursorPointer`}>
                                                        MAX
                                                    </div>
                                                </div>
                                            </div>
                                        </div>,
                                        <div className={'colorGray'}>Borrow</div>,
                                        <div
                                            className={`${isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopPhone borderRadiusPhone borderSmallGrayLinePhone' : 'paddingBig childrenSpacingTopBig borderSmallGrayLine'}`}
                                            style={isPhoneClientWidth ? {} : {borderRadius: '.3rem'}}
                                        >
                                            <div
                                                className={`flex alignItemsCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                <input type="text"
                                                       placeholder='0'
                                                       value={checkDataString(data, 'newBorrowAmount')}
                                                       onChange={e => setNewBorrowAmount(getSetNewAmount(e.target.value))}
                                                       className={`${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'} flexGrow1`}/>
                                                <div
                                                    className={`borderRadius999 saf backgroundWhite flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeft'}`}
                                                    style={{
                                                        height: `${isPhoneClientWidth ? 2.4 : .8}rem`,
                                                        padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                                    }}>
                                                    {tokenIcon(marginToken1Data, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                                    <div>{marginToken1Symbol}</div>
                                                </div>
                                            </div>
                                        </div>,
                                        (() => {
                                            const [marginLevelIcon, marginLevelColor] = getMarginLevel(borrowMarginLevel)
                                            return <div
                                                className={`flexCenter justifyContentFlexEnd ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                <div>Margin Level</div>
                                                <img src={requireDefault(marginLevelIcon)} alt=""
                                                     style={{width: `${isPhoneClientWidth ? 1.2 : .4}rem`}}/>
                                                <div className={'saf1'}
                                                     style={{color: marginLevelColor}}>{borrowMarginLevel}</div>
                                            </div>
                                        })(),
                                        <div style={poolDataPoolId ? {} : {color: '#7D7D7D'}}
                                             onClick={() => poolDataPoolId && (
                                                 newAmountNumber <= borrowMaxBalance
                                                     ? borrowMaxAmountToast(() => toggleShowMarginSelling(true))
                                                     : toast(`The maximum supply amount is  ${borrowMaxBalance}`)
                                             )}
                                             className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingTopBottomPhone bigFontSizePhone' : 'borderRadius paddingTopBottomBig bigFontSize'} 
                                             flexCenter colorWhite saf1 ${poolDataPoolId ? 'backgroundActiveColor' : 'backgroundGrey'}  cursorPointer`}>
                                            Borrow
                                        </div>
                                    ]
                                }
                            ]
                        ]
                        : [],
                    [
                        'Margin',
                        () => {
                            const showMarginMaxLeverages = checkDataBool(data, 'showMarginMaxLeverages'),
                                toggleShowMarginMaxLeverages = () => setData({showMarginMaxLeverages: !showMarginMaxLeverages}),
                                toggleMarginMaxLeverageIndex = marginMaxLeverageIndex => setData({marginMaxLeverageIndex}),
                                marginMaxBalance = lessThanOrEqualTo(marginToken0DataBalance, marginMax) ? marginToken0DataBalance : marginMax
                            return [
                                <div style={isPhoneClientWidth ? {} : {borderRadius: '.3rem'}}
                                     className={`backgroundWhite ${isPhoneClientWidth ? 'borderSmallGrayLinePhone paddingBigPhone childrenSpacingTopSmallPhone borderRadiusPhone' : 'borderSmallGrayLine paddingBig childrenSpacingTopSmall'}`}
                                >
                                    <div
                                        className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                        <div className={'flexCenter justifyContentSpaceBetween'}>
                                            <div
                                                className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                                Liquidity Pool TVL
                                            </div>
                                            <div
                                                className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>{ovl(poolData, 'tvl')}</div>
                                        </div>
                                        {selectTokenContainer}
                                    </div>
                                    {/*<div className={'flexCenter justifyContentSpaceBetween'}>*/}
                                    {/*    <div onClick={() => toggleShowSelectToken(true)}*/}
                                    {/*         className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} cursorPointer`}>*/}
                                    {/*        {tokenIcon(poolData, `${isPhoneClientWidth ? 2.4 : .8}rem`)}*/}
                                    {/*        <div className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>*/}
                                    {/*            {poolData.name}*/}
                                    {/*        </div>*/}
                                    {/*        <img src={requireDefault('toBottom')} alt=""*/}
                                    {/*             style={{width: `${isPhoneClientWidth ? .8 : .2}rem`}}/>*/}
                                    {/*    </div>*/}
                                    {/*    <div*/}
                                    {/*        className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>{ovl(poolData, 'tvl')}</div>*/}
                                    {/*</div>*/}
                                    <img src={requireDefault('Line 2')} alt="" className={'width100'}/>
                                    <div
                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                        Entry Price
                                    </div>
                                    <div
                                        className={`flex alignItemsCenter ${opacityShow ? 'opacityShow' : 'opacityHidden'} ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                        <div
                                            className={isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'}>
                                            {entryToken} {token0Symbol}
                                        </div>
                                        {isETHPool && <div
                                            className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                            {marginEntryPrice}
                                        </div>}
                                    </div>
                                </div>,
                                <div
                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'justifyContentSpaceBetween childrenSpacingLeftBigPhone' : 'childrenSpacingLeft'}`}>
                                    {
                                        [
                                            [`marginBuy${!isMarginSell ? 'Active' : ''}${isPhoneClientWidth ? 'Phone' : ''}`, false],
                                            [`marginSell${isMarginSell ? 'Active' : ''}${isPhoneClientWidth ? 'Phone' : ''}`, true]
                                        ].map(v =>
                                            <img src={requireDefault(v[0])} alt=""
                                                 className={'cursorPointer'}
                                                 style={isPhoneClientWidth ? {height: '2.5rem'} : {width: '3rem'}}
                                                 onClick={() => setData({isMarginSell: v[1]})}
                                            />)
                                    }
                                </div>,
                                <div
                                    className={`backgroundGrey ${isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopPhone borderRadiusPhone' : 'paddingBig childrenSpacingTopBig'}`}
                                    style={isPhoneClientWidth ? {} : {borderRadius: '.3rem'}}
                                >
                                    <div
                                        className={`flex alignItemsCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                        <input type="text"
                                               ref={focusInput}
                                               placeholder='0'
                                               value={inputNewAmount}
                                               onChange={e => setInputNewAmount(e.target.value)}
                                               className={`${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'} flexGrow1`}/>
                                        <div
                                            className={`borderRadius999 saf backgroundWhite flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeft'}`}
                                            style={{
                                                height: `${isPhoneClientWidth ? 2.4 : .8}rem`,
                                                padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                            }}>
                                            {tokenIcon(marginToken0Data, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                            <div>{marginToken0Symbol}</div>
                                        </div>
                                    </div>
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div
                                            className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                            {tokenUSDTPrice}
                                        </div>
                                        <div
                                            className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                            <div
                                                className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                                {marginMaxBalance} {marginToken0Symbol}
                                            </div>
                                            <div
                                                style={{fontSize: `${isPhoneClientWidth ? .6 : .15}rem`}}
                                                onClick={() => setNewAmount(marginMaxBalance)}
                                                className={`backgroundActiveColor ${isPhoneClientWidth ? 'paddingSmallPhone' : 'paddingSmall'} borderRadius999 colorWhite cursorPointer`}>
                                                MAX
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                                <div
                                    className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                    Margin Max Leverage
                                </div>,
                                <div
                                    className={`flexCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                    {[// currentLeverages,
                                        ...marginMaxLeverages, // <input type="text"
                                        //        style={{width: `${isPhoneClientWidth ? 75 : 65}%`, height: '80%'}}
                                        //        className={`saf1 ${marginMaxLeverageIndex === 3 ? 'colorWhite' : ''}`}
                                        //        value={checkDataString(data, 'otherMarginMaxLeverage')}
                                        //        onChange={e => setData({otherMarginMaxLeverage: e.target.value.replace(/[^\d]/g, '')})}
                                        //        placeholder={marginMaxLeverageIndex === 3 ? '' : 'Other'}/>
                                    ].map((v, i) => {
                                        const isFirst = false// i === 0
                                        return [
                                            <div onClick={() => !isFirst && toggleMarginMaxLeverageIndex(i)}
                                                 className={`borderRadius999 flexCenter ${(isFirst || i === marginMaxLeverageIndex) ? 'backgroundActiveColor colorWhite' : 'borderSmallGrayLine'} saf1 ${isPhoneClientWidth ? '' : 'bigFontSize'} boxSizingBorderBox ${isFirst ? '' : 'cursorPointer'}`}
                                                 style={{
                                                     ...isPhoneClientWidth ? {
                                                         width: '4.5rem', height: '2.1rem',
                                                     } : {
                                                         width: '1.5rem', height: '.7rem',
                                                     }, // ...!isFirst && !showMarginMaxLeverages
                                                     //     ? {opacity: 0}
                                                     //     : {}
                                                 }}>
                                                {v}
                                            </div>,
                                            ...isFirst ? [
                                                <div
                                                    onClick={toggleShowMarginMaxLeverages}
                                                    className={'borderRadius999 flexCenter backgroundGrey cursorPointer'}
                                                    style={square(`${isPhoneClientWidth ? 1.5 : .5}rem`)}>
                                                    <img
                                                        src={requireDefault(showMarginMaxLeverages ? 'toLeft' : 'toRight')}
                                                        alt=""
                                                        style={{width: '40%'}}/>
                                                </div>
                                            ] : []
                                        ]
                                    })}
                                </div>,
                                <div style={poolDataPoolId ? {} : {color: '#7D7D7D'}}
                                     onClick={() => poolDataPoolId && (
                                         newAmountNumber <= marginMaxBalance
                                             ? toggleShowMarginSelling(true)
                                             : toast(`The maximum amount is ${marginMaxBalance}`)
                                     )}
                                     className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingTopBottomPhone bigFontSizePhone' : 'borderRadius paddingTopBottomBig bigFontSize'}
                                     ${poolDataPoolId ? 'backgroundActiveColor' : 'backgroundGrey'} 
                                     flexCenter colorWhite saf1 cursorPointer`}>
                                    Review
                                </div>,
                                showMarginData && !!marginPositionId &&
                                <div style={{padding: `${isPhoneClientWidth ? .5 : .1}rem`}}
                                     className={'borderSmallGrayLine borderRadius999 childrenFlexGrow1 flexCenter'}>
                                    {beforeAfterMerged.map((v, i) => <div
                                        onClick={() => setData({beforeAfterMergedIndex: i})}
                                        className={`borderRadius999 flexCenter ${isPhoneClientWidth ? 'paddingTopBottomPhone' : 'paddingTopBottom'} cursorPointer 
                                             ${beforeAfterMergedIndex === i ? 'backgroundGrey saf1' : 'colorGray'}`}>
                                        {v}
                                    </div>)}
                                </div>,
                                <div
                                    className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone marginTopBigPhone' : 'childrenSpacingTopBig marginTopPhone'}>
                                    {marginData()}
                                </div>
                            ]
                        }
                    ],
                    [
                        'Swap',
                        () => <div style={isPhoneClientWidth ? {paddingBottom: '21rem'} : {}}
                                   className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                            <div
                                className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'} positionRelative`}>
                                <div
                                    className={`backgroundWhite ${isPhoneClientWidth ? 'borderRadiusPhone borderSmallGrayLinePhone paddingBigPhone childrenSpacingTopBigPhone' : 'borderRadius borderSmallGrayLine paddingBig childrenSpacingTopBig'}`}
                                >
                                    <div
                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>Sell
                                    </div>
                                    <div
                                        className={`flex alignItemsCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                        <input type="text"
                                               placeholder='0'
                                               ref={focusInput}
                                               value={inputNewAmount}
                                               onChange={e => setInputNewAmount(e.target.value)}
                                               className={`flexGrow1 ${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'}`}/>
                                        <div onClick={() => {
                                            setData({selectTokenIndex: isMarginSell ? 0 : 1})
                                            toggleShowSelectToken(true)
                                        }}
                                             className={`borderRadius999 saf flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone borderSmallGrayLinePhone' : 'childrenSpacingLeft borderSmallGrayLine'} cursorPointer`}
                                             style={{
                                                 height: `${isPhoneClientWidth ? 2.4 : .8}rem`,
                                                 padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                             }}>
                                            {tokenIcon(marginToken0Data, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                            <div>{marginToken0Symbol}</div>
                                            <img src={requireDefault('toBottom')} alt=""
                                                 style={square(`${isPhoneClientWidth ? .7 : .2}rem`)}/>
                                        </div>
                                    </div>
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div
                                            className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>{tokenUSDTPrice}
                                        </div>
                                        <div
                                            className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                            {(() => {
                                                const maxBalance = lessThanOrEqualTo(marginToken0DataBalance, swapMax) ? marginToken0DataBalance : swapMax
                                                return [
                                                    <div
                                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                                        {maxBalance} {marginToken0Symbol}
                                                    </div>,
                                                    <div style={{fontSize: `${isPhoneClientWidth ? .6 : .15}rem`}}
                                                         onClick={() => setNewAmount(maxBalance)}
                                                         className={`backgroundActiveColor ${isPhoneClientWidth ? 'paddingSmallPhone' : 'paddingSmall'} borderRadius999 colorWhite cursorPointer`}>MAX
                                                    </div>
                                                ]
                                            })()}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingBigPhone childrenSpacingTopBigPhone' : 'borderRadius paddingBig childrenSpacingTopBig'} backgroundGrey`}
                                >
                                    <div
                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>Buy
                                    </div>
                                    <div
                                        className={`flex alignItemsCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                        <div style={transitionPrice > 0 ? {} : {color: '#BFBFBF'}}
                                             className={`${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'} ${opacityShow ? 'opacityShow' : 'opacityHidden'}`}>
                                            {transitionPrice}
                                        </div>
                                        <div onClick={() => {
                                            setData({selectTokenIndex: isMarginSell ? 1 : 0})
                                            toggleShowSelectToken(true)
                                        }}
                                             className={`borderRadius999 saf flexCenter backgroundWhite ${isPhoneClientWidth ? 'childrenSpacingLeftPhone borderSmallGrayLinePhone' : 'childrenSpacingLeft borderSmallGrayLine'} cursorPointer`}
                                             style={{
                                                 height: `${isPhoneClientWidth ? 2.4 : .8}rem`,
                                                 padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                             }}>
                                            {tokenIcon(marginToken1Data, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                            <div>{marginToken1Symbol}</div>
                                            <img src={requireDefault('toBottom')} alt=""
                                                 style={square(`${isPhoneClientWidth ? .7 : .2}rem`)}/>
                                        </div>
                                    </div>
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div
                                            className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>{tokenUSDTPrice}
                                        </div>
                                        <div className={'flexCenter childrenSpacingLeft'}>
                                        </div>
                                    </div>
                                </div>
                                <img style={{
                                    top: isPhoneClientWidth ? '6.7rem' : '2.3rem',
                                    left: '45%',
                                    width: `${isPhoneClientWidth ? 3.5 : 1}rem`

                                }} onClick={() => setData({
                                    isMarginSell: !isMarginSell,
                                    newAmount: 0,
                                    newAmountWei: 0n,
                                    transitionPrice: undefined
                                })}
                                     className={'positionAbsolute cursorPointer marginTop0'}
                                     src={requireDefault('swap-2')} alt=""/>
                            </div>
                            <div onClick={() => poolDataPoolId && toggleShowSwapping(true)}
                                 style={poolDataPoolId ? {} : {color: '#7D7D7D'}}
                                 className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingTopBottomPhone bigFontSizePhone' : 'borderRadius paddingTopBottomBig bigFontSize'} flexCenter colorWhite saf1 ${poolDataPoolId ? 'backgroundActiveColor' : 'backgroundGrey'} cursorPointer`}>
                                Review
                            </div>
                            {isNotNullOrEmpty(newAmountStr) && [
                                <div
                                    className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                    {`${uint} ${marginToken1Symbol} = ${unitPrice} ${marginToken0Symbol} ${isETHPool ? `(${getTokenUSDTUintPrice})` : ''}`}
                                </div>,
                                swapFee
                            ]}
                        </div>
                    ]
                ]
            })(),
            marginSwapIndex = (() => {
                const index = marginSwap.map(v => v[0].toLocaleLowerCase()).indexOf(pNs1)
                return index === -1 ? 0 : index
            })(),
            toggleMarginSwapIndex = marginSwapIndex => windowReplace(`/trade/${marginSwap[marginSwapIndex][0].toLocaleLowerCase()}`),
            pageFoot = <div style={{marginTop: '1rem'}}>
                <div style={{height: '1rem', padding: '0 2rem', left: 0, right: 0, bottom: 0}}
                     className={'positionFixed zIndex999 flexCenter justifyContentSpaceBetween boxSizingBorderBox backgroundWhite'}
                >
                    <div onClick={devCountAdd} className={'saf2'}>
                        © All rights reserved, 2024
                    </div>
                    <div className={'flex childrenSpacingLeft'}>
                        {socialLinks.map(v => <img src={requireDefault(`${v[0]}3`)} alt=""
                                                   onClick={() => windowOpen(v[1])}
                                                   className={'cursorPointer'}
                                                   style={square('.5rem')}/>)}
                    </div>
                </div>
            </div>,
            maskContent = (title, show, toggle, content, webWidth, isBack) =>
                show && <div onClick={() => toggle()}
                             className={`positionFixed ${isPhoneClientWidth ? 'flexColumn justifyContentFlexEnd' : 'flexColumnCenter'}`}
                             style={{
                                 top: 0,
                                 left: 0,
                                 right: 0,
                                 bottom: 0,
                                 zIndex: 1000,
                                 background: 'rgba(0,0,0,.56)',
                             }}>
                    {isPhoneClientWidth
                        ? <div onClick={event => event.stopPropagation()}
                               className={'backgroundWhite paddingBigPhone boxSizingBorderBox childrenSpacingTopBigPhone'}
                               style={{borderTopLeftRadius: '1.5rem', borderTopRightRadius: '1.5rem'}}>
                            {title && <div className={'flexCenter justifyContentSpaceBetween paddingTopPhone'}>
                                <div className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                    {isBack && <img onClick={() => toggle()} src={requireDefault('leftArrow')}
                                                    alt="" className={'cursorPointer'} style={{width: '1.2rem'}}/>}
                                    <div className={'saf1'}>{title}</div>
                                </div>
                                {!isBack && <img onClick={() => toggle()} src={requireDefault('close')}
                                                 alt="" className={'cursorPointer'} style={{width: '1.2rem'}}/>}
                            </div>}
                            {content}
                        </div>
                        : <div onClick={event => event.stopPropagation()}
                               className={'borderRadius backgroundWhite paddingBigger boxSizingBorderBox childrenSpacingTopBig'}
                               style={{width: `${checkData([webWidth], 8)}rem`}}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                    {isBack && <img onClick={() => toggle()} src={requireDefault('leftArrow')}
                                                    alt="" className={'cursorPointer'} style={{width: '.3rem'}}/>}
                                    <div className={'saf1'}>{title}</div>
                                </div>
                                {!isBack && <img onClick={() => toggle()} src={requireDefault('close')}
                                                 alt="" className={'cursorPointer'} style={{width: '.3rem'}}/>}
                            </div>
                            {content}
                        </div>}
                </div>,
            rightArrowText = (a, b) => <div
                className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                <div className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>{a}</div>
                <img src={requireDefault('rightArrow')} alt="" style={{width: `${isPhoneClientWidth ? .6 : .2}rem`}}/>
                <div className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>{b}</div>
            </div>,
            positionsPoolIndex = checkData0(data, 'positionsPoolIndex'),
            isBorrowPositions = isShowBorrow && positionsPoolIndex === 0,
            isHistory = !isBorrowPositions && checkDataBool(data, 'isHistory'),
            getPositions = async items => {
                const obj = await (isBorrowPositions ? borrowPositionManagerCall : marginPositionManagerCall)('getPositions', items.map(v => v.positionId))
                return Object.fromEntries(
                    items.map((v, i) => {
                        const item = obj[i],
                            {
                                marginAmount, marginTotal, borrowAmount
                            } = checkDataObject(item, 'position')
                        return [
                            v.positionId,
                            {
                                marginAmount, marginTotal, borrowAmount, pnl: checkData0(item, 'pnl')
                            }
                        ]
                    })
                )
            },
            positionDetail = async (v, position, eU = ethUSDT) => {
                const {poolId, marginForOne, borrowToken, marginToken, poolName} = v,
                    poolNameArr = checkDataString(poolName).split('/'),
                    borrowDecimals = await erc20CallDecimals(borrowToken),
                    marginDecimals = await erc20CallDecimals(marginToken),
                    marginSymbol = checkData([v, 'marginSymbol'], poolNameArr[marginForOne ? 1 : 0]),
                    borrowSymbol = checkData([v, 'borrowSymbol'], poolNameArr[marginForOne ? 0 : 1]),
                    markToken = num8(divide(checkData0(v, `reserve0`), checkData0(v, `reserve1`))),
                    {
                        marginAmount, marginTotal
                    } = position,
                    borrowAmount = isHistory ? checkData0(v, 'debtAmount') : position.borrowAmount,
                    marginTotalETH = formatEther(marginTotal, marginDecimals),
                    borrowAmountETH = formatEther(borrowAmount, borrowDecimals),
                    marginAmountETH = formatEther(marginAmount, marginDecimals),
                    t0Symbol = marginForOne ? borrowSymbol : marginSymbol,
                    entryToken = num8(divide(
                        ...marginForOne ? [borrowAmountETH, marginTotalETH] : [marginTotalETH, borrowAmountETH]
                    )),
                    liqToken = num8((() => {
                            const num = divide(
                                plus(marginAmountETH, marginTotalETH),
                                times(borrowAmountETH, 1.1)
                            )
                            return marginForOne ? divide(1, num) : num
                        })()
                    ),
                    iEA = [marginToken, borrowToken].includes(address0),
                    reserve0Decimals = marginForOne ? borrowDecimals : marginDecimals,
                    reserve1Decimals = marginForOne ? marginDecimals : borrowDecimals,
                    markTokenETH = num8(divide(
                        formatEther(checkData0(v, 'reserve0'), reserve0Decimals),
                        formatEther(checkData0(v, 'reserve1'), reserve1Decimals)
                    )),
                    truncatedTokenETH = num8(divide(
                        formatEther(checkData0(v, 'oracleReserve0'), reserve0Decimals),
                        formatEther(checkData0(v, 'oracleReserve1'), reserve1Decimals)
                    )),
                    marginTokenIs0 = isAddress0(marginToken),
                    purchaseAmount = numberETH(checkData0(v, 'purchaseAmount'), marginDecimals),
                    sellAmount = numberETH(checkData0(v, 'sellAmount'), marginDecimals)
                return {
                    ...v,
                    marginSymbol,
                    borrowDecimals,
                    marginDecimals,
                    marginTotalWei: marginTotal,
                    marginTotal: numberETH(marginTotal, marginDecimals),
                    marginAmountWei: marginAmount,
                    marginAmount: numberETH(marginAmount, marginDecimals),
                    borrowAmountWei: borrowAmount,
                    borrowSymbol,
                    entryToken: `${entryToken} ${t0Symbol}`,
                    entryPrice: iEA ? `(${getTokenUSDTPrice(entryToken, eU)})` : '',
                    markTokenNum: marginForOne ? divide(1, markToken) : markToken,
                    markToken: `${markTokenETH} ${t0Symbol}`,
                    markPrice: iEA ? `(${getTokenUSDTPrice(markTokenETH, eU)})` : '',
                    truncatedToken: `${truncatedTokenETH} ${t0Symbol}`,
                    truncatedPrice: iEA ? `(${getTokenUSDTPrice(truncatedTokenETH, eU)})` : '',
                    liqToken: `${liqToken} ${t0Symbol}`,
                    liqPrice: iEA ? `(${getTokenUSDTPrice(liqToken, eU)})` : '',
                    ...isHistory
                        ? {}
                        : {
                            apy: await marginFeesCall('getBorrowRate', marginHookManagerAddress, poolId, marginForOne)
                                .then(v => numToPercentage(v)),
                            borrowBalance: numberETH((await erc20CallBalance(borrowToken))[0], borrowDecimals),
                        },
                    borrowAmount: (() => {
                        const num = formatEther(borrowAmount, borrowDecimals)
                        return num8(/^\d+\.\d{9,}$/.test(num) ? plus(num, '0.00000001') : num)
                    })(),
                    estimatedPNL: numberETH(checkData0(isHistory ? v : position, 'pnl'), marginDecimals),
                    purchaseAmountETH: `${purchaseAmount} ${marginSymbol}`,
                    purchaseAmountPrice: marginTokenIs0 ? `(${getTokenUSDTPrice(purchaseAmount, eU)})` : '',
                    sellAmountETH: `${sellAmount} ${marginSymbol}`,
                    sellAmountPrice: marginTokenIs0 ? `(${getTokenUSDTPrice(sellAmount, eU)})` : '',
                }
            },
            positionsKey = isBorrowPositions ? 'borrowPositions' : (isHistory ? 'marginPositionsHistory' : 'marginPositions'),
            positions = checkDataArray(data, positionsKey),
            positionsOpacityShow = checkDataObject(data, 'positionsOpacityShow'),
            closePositionData = (isDetail, item = {}) => [
                ...[
                    ['Debt', `${checkData0(item, 'borrowAmount')} ${checkDataString(item, 'borrowSymbol')}`],
                    isHistory ? ['ROI', `${numToPercentage(checkData0(item, 'roi'))}%`] : ['APY', `${checkData0(item, 'apy')}%`],
                    ...isHistory
                        ? [
                            ['Total Purchase Amount', checkData0(item, 'purchaseAmountETH'), checkData0(item, 'purchaseAmountPrice')],
                            ['Total Sell Amount', checkData0(item, 'sellAmountETH'), checkData0(item, 'sellAmountPrice')],
                        ]
                        : [
                            ...isBorrowPositions
                                ? []
                                : [
                                    ['Entry Price', checkData0(item, 'entryToken'), checkData0(item, 'entryPrice')],
                                    ['Mark Price', checkData0(item, 'markToken'), checkData0(item, 'markPrice')],
                                    ['Truncated Price', checkData0(item, 'truncatedToken')],
                                ],
                            ['Liq.Price', checkData0(item, 'liqToken'), checkData0(item, 'liqPrice')],
                            ...isBorrowPositions
                                ? []
                                : [
                                    ['Margin Total', `${checkData0(item, 'marginTotal')} ${item.marginSymbol}`],
                                    [
                                        'Margin Level',
                                        toFixed(
                                            divide(
                                                plus(checkData0(item, 'marginAmountWei'), checkData0(item, 'marginTotalWei')),
                                                times(checkData0(item, 'markTokenNum'), checkData0(item, 'borrowAmountWei'))
                                            ),
                                            2
                                        )
                                    ]
                                ]
                        ],
                ].map(v => {
                        const [label] = v,
                            isMarginLevel = label === 'Margin Level',
                            [marginLevelIcon, marginLevelColor] = getMarginLevel(v[1])
                        return [
                            label,
                            <div
                                className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                {isMarginLevel && <img
                                    src={requireDefault(marginLevelIcon)}
                                    style={{
                                        width: `${isPhoneClientWidth ? 0.9 : 0.3}rem`,
                                        height: `${isPhoneClientWidth ? 0.9 : 0.3}rem`
                                    }}/>}
                                <div style={isMarginLevel ? {color: marginLevelColor} : {}}
                                     className={`${isMarginLevel ? 'saf1' : ''} ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[1]}</div>
                                {isNotNull(v[2]) && <div
                                    className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[2]}</div>}
                            </div>
                        ]
                    }
                ),
                ...isDetail || isBorrowPositions
                    ? []
                    : [
                        [
                            `${isHistory ? '' : 'Estimated '}PNL`,
                            (() => {
                                const estimatedPNL = checkDataString(item, 'estimatedPNL')
                                return <div
                                    className={`saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} 
                                    ${Number(estimatedPNL) < 0 ? 'colorRed' : 'colorGreen'}`}>{estimatedPNL} {checkDataString(item, 'marginSymbol')}</div>
                            })()
                        ]
                    ]
            ].map(v => {
                const [label] = v,
                    isTruncatedPrice = !isDetail && label === 'Truncated Price'
                return <div
                    className={`flexCenter justifyContentSpaceBetween ${isTruncatedPrice ? `${isPhoneClientWidth ? 'paddingPhone' : 'padding'}` : ''}`}
                    style={
                        isTruncatedPrice
                            ? {
                                borderRadius: `${isPhoneClientWidth ? .6 : .2}rem`,
                                background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 142, 157, 0.2) 100%)'
                            }
                            : {}
                    }
                >
                    <div
                        className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                        {
                            isTruncatedPrice &&
                            <div className={'borderRadius999 backgroundActiveColor'}
                                 style={square(`${isPhoneClientWidth ? .45 : .15}rem`)}/>
                        }
                        <div
                            className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{label}</div>
                        {
                            isTruncatedPrice &&
                            <img onClick={() =>
                                windowOpen('https://likwidfi.gitbook.io/likwid-protocol-docs/likwid.fi-whitepaper/3.-protocol-mechanics/3.9-truncated-oracles')
                            } className={'cursorPointer'} src={requireDefault('tips1')} alt=""
                                 style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}/>
                        }
                    </div>
                    <div
                        className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                        <div
                            className={checkData([positionsOpacityShow, item.id], true) ? 'opacityShow' : 'opacityHidden'}>{v[1]}</div>
                        {
                            isTruncatedPrice &&
                            <img className={'cursorPointer'}
                                 onClick={
                                     () => {
                                         if (isTruncatedPrice) {
                                             const {id} = item
                                             setData({
                                                 positionsOpacityShow: {
                                                     ...positionsOpacityShow,
                                                     [id]: false
                                                 }
                                             })
                                             requestApi(`margin/position/${id}`).then(async v => {
                                                 const p = [...positions]
                                                 p[positions.map(v => v.id).indexOf(id)] = await positionDetail(
                                                     v,
                                                     checkDataObject(await getPositions([v]), v.positionId)
                                                 )
                                                 setData({[positionsKey]: p})
                                             }).finally(() => setTimeout(
                                                 () => setData({
                                                     positionsOpacityShow: {
                                                         ...positionsOpacityShow,
                                                         [id]: true
                                                     }
                                                 }),
                                                 500
                                             ))
                                         }
                                     }
                                 }
                                 src={requireDefault('refresh-one 1')} alt=""
                                 style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}/>
                        }
                    </div>
                </div>
            }),
            range = checkData0(data, 'range'), rangeStep = 25, defaultMaxRange = 100,
            maxRange = showClosePosition ? defaultMaxRange : checkData([data, 'maxRange'], defaultMaxRange),
            setRange = range => {
                range = greaterThan(range, maxRange) ? maxRange : range
                setData({range})
                return range
            }, rangeContent = (changeEnd = () => {
            }) => {
                const size = `${isPhoneClientWidth ? .9 : .3}rem`
                return <div className={'positionRelative zIndex1'}
                            style={{margin: `${isPhoneClientWidth ? 2.5 : .7}rem 0 ${isPhoneClientWidth ? 2 : .5}rem 0`}}>
                    <input
                        min={0}
                        step={1}
                        max={defaultMaxRange}
                        type='range'
                        value={range}
                        onMouseUp={() => changeEnd(range)}
                        className={isPhoneClientWidth ? 'customRangePhone' : 'customRange'}
                        style={{'--value': `${range}%`}}
                        onChange={e => setRange(e.target.value)}
                    />
                    <div
                        className={`positionAbsolute width100 backgroundRed zIndex-1 ${isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}`}
                        style={{
                            bottom: `${isPhoneClientWidth ? 2.2 : .63}rem`, height: 0
                        }}
                    >
                        <div className={'flexCenter justifyContentSpaceBetween'}>
                            {Array.from({length: 101}, (_, i) => i).map(v => {
                                const isCurrent = Math.round(range) == v
                                return <div
                                    style={{width: isCurrent ? (`${isPhoneClientWidth ? .9 : .3}rem`) : 0}}
                                    className={`flexColumnCenter ${isCurrent ? '' : 'overflowHidden'}`}>
                                    <div
                                        className={`${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallerFontSize'}`}>{range}%
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className={'flexCenter justifyContentSpaceBetween positionRelative'}>
                            {Array.from({length: (100 / rangeStep) + 1}, (_, i) => rangeStep * i).map(v => {
                                const isCurrent = range == v
                                return <div
                                    style={{width: size}}
                                    className={`flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}`}>
                                    <div
                                        onClick={() => {
                                            changeEnd(setRange(v))
                                        }}
                                        className={'borderRadius999 cursorPointer'}
                                        style={{
                                            ...square(size), background: range > v ? '#FFB9C2' : '#EFEFEF'
                                        }}/>
                                    <div
                                        className={`${isCurrent ? '' : 'colorGray'} ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallerFontSize'}`}>{v}%
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className={'positionAbsolute width100'}
                         style={{
                             pointerEvents: 'none', top: `${isPhoneClientWidth ? .95 : .27}rem`, height: 0
                         }}
                    >
                        <div className={'flexCenter justifyContentSpaceBetween positionRelative'}>
                            {lessThan(maxRange, defaultMaxRange) && <div className={'positionAbsolute flexCenter'}
                                                                         style={{
                                                                             zIndex: 100,
                                                                             width: 0,
                                                                             height: 0,
                                                                             left: maxRange <= 0 ? `calc(${size}/2)` : (maxRange < 50 ? `calc(${maxRange}% + ${size}/2*${divide(maxRange * 2, 100)} )` : (maxRange == 50 ? `${maxRange}%` : (maxRange < 100 ? `calc(${maxRange}% - ${size}/2*${divide(maxRange / (maxRange > 75 ? 1 : 1.25), 100)} )` : `calc(${maxRange}% - ${size}/2)`)))
                                                                         }}
                            >
                                <div
                                    style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}
                                    className={`flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}`}>
                                    <div
                                        onClick={() => {
                                            changeEnd(setRange(maxRange))
                                        }}
                                        className={'borderRadius999 cursorPointer'}
                                        style={{
                                            ...square(`${isPhoneClientWidth ? .9 : .3}rem`), background: '#22C55F',
                                        }}/>
                                    <div style={{opacity: 0}}
                                         className={`${range == maxRange ? '' : 'colorGreen'} ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallerFontSize'}`}>{maxRange}%
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            },
            poolPositions = checkDataArray(data, 'poolPositions'),
            erc20MarginHookManagerAllowance = (...params) => erc20MarginAllowance(marginHookManagerAddress, ...params),
            token0Allowance = () => erc20MarginHookManagerAllowance(token0Address, token0NewAmountWei).then(num => {
                setTokenData(token0Data, {needApprove: num > 0})
                return num
            }),
            token1Allowance = () => erc20MarginHookManagerAllowance(token1Address, token1NewAmountWei).then(num => {
                setTokenData(token1Data, {needApprove: num > 0})
                return num
            }),
            toggleShowLiquidity = showLiquidity => (showLiquidity && !isPoolRemove ? (() => {
                toggleShowLoading(true)
                return (!token0IsETH ? (token0Allowance().then(() => token1Allowance())) : token1Allowance()).then(() => toggleShowLoading())
            })() : new Promise(resolve => resolve())).then(() => setData({showLiquidity, ...!showLiquidity ? {showApprove: false} : {}})),
            // toggleShowLiquidity = showLiquidity => (showLiquidity && !isPoolRemove ? (() => {
            //     toggleShowLoading(true)
            //     return (!token0IsETH
            //             ? token0Allowance().then(() =>
            //                 (
            //                     isAddress0(token1Address)
            //                         ? new Promise(resolve => resolve())
            //                         : token1Allowance()
            //                 )
            //             )
            //             : (
            //                 isAddress0(token1Address)
            //                     ? new Promise(resolve => resolve())
            //                     : token1Allowance()
            //             )
            //     ).then(() => toggleShowLoading())
            // })() : new Promise(resolve => resolve())).then(() => setData({showLiquidity, ...!showLiquidity ? {showApprove: false} : {}})),
            page = checkData([data, 'page'], defaultPage), isFirstPage = (p = page) => p === defaultPage,
            positionPage = checkData([data, 'positionPage'], defaultPage),
            poolPositionPage = checkData([data, 'poolPositionPage'], defaultPage),
            positionPoolData = [
                [`margin/${isBorrowPositions ? 'borrow/' : ''}position/list?burned=${isHistory}`, positionPage, positionsKey, positions],
                ['margin/pool/liquidity/list', poolPositionPage, 'poolPositions', poolPositions]
            ],
            estimatePNL = (positionId, range) => marginPositionManagerCall('estimatePNL', positionId, BigInt(toInt(times(range, 10000)))).catch(() => 0),
            getPositionPool = i => (isNotNull(i) ? [positionPoolData[i]] : positionPoolData).map((v, i1) => {
                const page = v[1]
                requestApi(v[0], {chainId, page, pageSize: 5, owner: address}, undefined, undefined, true).then(v1 => {
                    const isPositions = (isNotNull(i) ? i : i1) === 0, items = checkDataArray(v1, 'items'), arr = [];
                    (isPositions ? getEthUSDTPrice().then(eU => new Promise(async resolve => {
                        if (isNotNullOrEmpty(items)) {
                            const positions = isHistory ? [] : (await getPositions(items.filter(v => !v.liquidated)))
                            for (const i in items) {
                                const v = items[i]
                                arr.push(await positionDetail(v, checkDataObject(positions, v.positionId), eU));
                                i === minus(items.length, 1) && resolve(arr)
                            }
                        } else {
                            resolve(arr)
                        }
                    })) : new Promise(async resolve => {
                        if (isNotNullOrEmpty(items)) {
                            for (const i in items) {
                                const v = items[i]
                                arr.push({
                                    ...v,
                                    currency0Decimals: await erc20CallDecimals(v.currency0),
                                    currency1Decimals: await erc20CallDecimals(v.currency1),
                                });
                                i === minus(items.length, 1) && resolve(arr)
                            }
                        } else {
                            resolve(arr)
                        }
                    })).then(() => {
                        setData({
                            listLoading: false,
                            [v[2]]: [...isFirstPage(page) ? [] : v[3], ...arr,],
                            [`${v[2]}Total`]: checkData0(v1, 'total')
                        })
                        toggleShowLoading()
                    })
                })
            }),
            searchPositionPool = i => {
                if (isFirstPage()) {
                    getPositionPool(i)
                } else {
                    (isNotNull(i) ? [positionPoolData[i]] : positionPoolData).map(v => setData({[v[2]]: defaultPage}))
                }
            },
            toggleShowPositionsPools = () => {
                if (!showPositionsPools) {
                    setData({listLoading: undefined, [positionPoolData[0][2]]: undefined})
                    searchPositionPool(0)
                }
                setData({showPositionsPools: !showPositionsPools})
            },
            docs = <div onClick={whitepaper}
                        className={`borderRadius999 cursorPointer flexCenter positionRelative ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}
                        style={{
                            height: `${isPhoneClientWidth ? 1.5 : .8}rem`,
                            padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                            background: isHome ? '#FF8E9D' : (!isHome && address) ? '#F9F9F9' : '#FFF4F5',
                        }}>
                <img
                    src={requireDefault('book')}
                    alt="" style={{height: `${isPhoneClientWidth ? 60 : 50}%`}}/>
                <div
                    className={`${isHome ? 'colorWhite saf1' : ((!isHome && address) ? '' : 'saf1 activeColor')} ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}`}>Docs
                </div>
            </div>,
            addressData = isDetail => {
                const click = () => isDetail ? () => {
                    } : ((address && !isHome) ? toggleShowPositionsPools : launchAPPConnect)(),
                    showMarginPositions = !isDetail && isMargin && checkDataBool(data, 'marginSuccess') && !localStorage.getItem('isFirstMargin')
                return <div onClick={() => {
                    if (!isDetail) {
                        setData({marginSuccess: false})
                        localStorage.setItem('isFirstMargin', true)
                    }
                }}>
                    {
                        showMarginPositions &&
                        <div className={'positionFixed flexColumnCenter'}
                             style={{
                                 top: 0,
                                 left: 0,
                                 right: 0,
                                 bottom: 0,
                                 background: 'rgba(0,0,0,.56)'
                             }}>
                            <img className={'positionAbsolute'}
                                 style={{
                                     right: `${isPhoneClientWidth ? 1 : 1.3}rem`,
                                     top: `${isPhoneClientWidth ? 3 : 1.2}rem`,
                                     width: `${isPhoneClientWidth ? 23 : 5}rem`
                                 }}
                                 src={requireDefault(isPhoneClientWidth ? 'marginPositionsPhone' : 'marginPositions')}
                            />
                        </div>
                    }
                    {
                        (!isHome && address && isPhoneClientWidth && !isDetail) ?
                            <img onClick={click}
                                 className={`cursorPointer positionRelative borderRadius999 ${showMarginPositions ? (isPhoneClientWidth ? 'borderActiveColorPhone' : 'borderActiveColor') : ''}`}
                                 src={requireDefault('account')} alt=""
                                 style={{height: '1.7rem'}}/>
                            : <div onClick={click}
                                   className={`borderRadius999 flexCenter positionRelative ${isDetail ? '' : 'cursorPointer'} ${showMarginPositions ? (isPhoneClientWidth ? 'borderActiveColorPhone' : 'borderActiveColor') : ''} ${
                                       isHome ? (isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall') : (isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft')
                                   }`}
                                   style={{
                                       ...isDetail ? {
                                           height: `${isPhoneClientWidth ? 1.5 : .8}rem`,
                                       } : {
                                           height: `${isPhoneClientWidth ? 1.5 : .8}rem`,
                                           padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                           background: isHome ? '#FF8E9D' : (!isHome && address) ? '#F9F9F9' : '#FFF4F5',
                                       },
                                   }}>
                                {isHome
                                    ? <img
                                        src={requireDefault('phone')}
                                        alt="" style={{height: `${isPhoneClientWidth ? 60 : 50}%`}}/>
                                    : <div className={'positionRelative height100 flexCenter'}>
                                        <img
                                            src={requireDefault(address ? 'account' : 'wallet')}
                                            alt="" style={{height: isDetail ? '100%' : '70%'}}/>
                                        {address && walletImageUrl && <img
                                            src={walletImageUrl}
                                            className={'positionAbsolute'}
                                            alt=""
                                            style={{
                                                height: isDetail ? '30%' : '20%',
                                                bottom: isDetail ? 0 : `${isPhoneClientWidth ? .3 : .1}rem`,
                                                right: '0'
                                            }}/>}
                                    </div>}
                                <div
                                    className={`${isHome ? 'colorWhite saf1' : ((!isHome && address) ? '' : 'saf1 activeColor')} ${isDetail ? (isPhoneClientWidth ? '' : 'bigFontSize') : (isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize')}`}>{isHome ? 'Launch APP' : (address ? addressLeaveOut : 'Connect')}</div>
                            </div>
                    }
                </div>
            },
            pageTopPhone = <div className={'positionFixed zIndex999'} style={{left: 0, right: 0}}>
                <div
                    className={`width100 boxSizingBorderBox flex justifyContentSpaceBetween alignItemsCenter paddingLeftRightBigPhone ${isTopColor}`}
                    style={{height: '3rem'}}>
                    <img onClick={replaceHome} src={requireDefault('logo')}
                         style={{height: '1.5rem'}} alt=""/>
                    <div className={'childrenSpacingLeftPhone flexCenter'}>
                        {selectNetworkContent}
                        {!isHome && addressData()}
                        {!isHome && <img onClick={toggleShowList} src={requireDefault('list')} alt=""
                                         className={'cursorPointer'} style={{height: '1.7rem'}}/>}
                    </div>
                </div>
                {showList && <div onClick={toggleShowList}
                                  style={{height: '100vh', background: 'rgba(0, 0, 0, 0.5)'}}
                >
                    <div className={'backgroundWhite overflowYScroll'}
                         style={{
                             maxHeight: '85vh',
                             borderBottomRightRadius: '1rem',
                             borderBottomLeftRadius: '1rem',
                             padding: '2rem 0'
                         }}
                    >
                        {navigations.map(v => {
                            const path = v[0],
                                isCurrent = (path === 'home' ? '' : path).toLocaleLowerCase() === showPath
                            return <div
                                className={`flexColumnCenter childrenSpacingTopPhone ${isCurrent ? 'backgroundGrey' : ''}`}
                                style={{padding: '1.5rem'}}
                                onClick={() => {
                                    v[1]()
                                    toggleShowList()
                                }}
                            >
                                <div className={isCurrent ? 'saf1' : 'colorGray'}>{path}</div>
                            </div>
                        })}
                    </div>
                </div>}
            </div>,
            pageTopFootPhone = (content, showEnd = true) => <div>
                {pageTopPhone}
                {content}
                {showEnd && pageFootPhone}
                {connectLoading(10)}
            </div>,
            pageTop = [
                <div
                    className={`ttf positionFixed flex alignItemsCenter zIndex999 ${isTopColor}`}
                    style={{
                        left: 0, right: 0, height: '1.2rem', padding: '0 2rem'
                    }}>
                    <img onClick={replaceHome} src={requireDefault('logo')}
                         style={{height}} alt="" className={'cursorPointer'}/>
                    <div style={{padding: '0 2rem'}}
                         className={'flexGrow1 boxSizingBorderBox childrenSpacingLeftBigPhone flex alignItemsCenter'}>
                        {!isHome && navigations.map(v => {
                            const [path] = v, pathIsTrade = path === 'Trade'
                            return <div onClick={v[1]}
                                        onMouseEnter={() => pathIsTrade && setData({showTopMarginSwap: true})}
                                        onMouseLeave={() => pathIsTrade && setData({showTopMarginSwap: false})}
                                        className={`cursorPointer positionRelative flexCenter ${pNs0 === path.toLocaleLowerCase() ? 'saf1' : ''}`}>
                                {path}
                                {pathIsTrade && checkDataBool(data, 'showTopMarginSwap') &&
                                    <div className={'positionAbsolute'}
                                         style={{
                                             top: '.35rem',
                                             paddingTop: '.15rem'
                                         }}>
                                        <div
                                            className={'borderRadius boxShadow paddingBig childrenSpacingTop backgroundWhite boxSizingBorderBox'}
                                            style={{width: '3.5rem'}}>
                                            {marginSwap.map((v, i) => <div
                                                onClick={event => {
                                                    event.stopPropagation()
                                                    toggleMarginSwapIndex(i)
                                                }}
                                                className={'borderRadius999 boxSizingBorderBox backgroundGrey flex alignItemsCenter childrenSpacingLeft marginSwap'}
                                                style={{height: '.7rem', paddingLeft: '20%'}}
                                            >
                                                <img src={requireDefault(v[0])} alt="" style={{height: '50%'}}/>
                                                <div className={'saf1'}>{v[0]}</div>
                                            </div>)}
                                        </div>
                                    </div>}
                            </div>
                        })}
                    </div>
                    <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                        {selectNetworkContent}
                        {isHome && docs}
                        {addressData()}
                    </div>
                </div>,
                connectLoading(5)
            ],
            addRemoveApi = (name, hash, poolId, liquidityFunc, amountsFunc) =>
                waitForTransactionFunc(
                    hash,
                    ({logs}) => {
                        searchPositionPool(1)
                        toastSuccessful(true)
                        toggleShowLoading()
                        toggleShowLiquidity()
                        !isPoolRemove && windowReplace('/pool')
                    }
                    // loginThenFunc(() => requestApi(
                    //     `margin/pool/liquidity/${name}`,
                    //     {
                    //         poolId,
                    //         chainId,
                    //         owner: address,
                    //         liquidity: liquidityFunc(logs),
                    //         ...Object.fromEntries(amountsFunc(logs).map((v, i) => [`amount${i}`, v])),
                    //     },
                    //     'post'
                    // ).then(r => {
                    //     searchPositionPool(1)
                    //     toastSuccessful(r)
                    //     toggleShowLoading()
                    //     toggleShowLiquidity()
                    // }))
                ),
            setTransitionPrice = opacity => newAmountWei > 0 ? getAmountOutFunc(newAmountWei, undefined, undefined)
                .then(transitionPriceWei => setData({
                    transitionPriceWei,
                    transitionPrice: numberETH(transitionPriceWei, marginToken1DataDecimals), ...opacity ? {opacityShow: true} : {}
                })).catch(e => {
                    setData({transitionPrice: undefined, transitionPriceWei: undefined})
                    opacity && setData({opacityShow: true}) //: toast(checkDataString(e, 'cause', 'errorArgs', 'message'))
                }) : (() => {
                setData({transitionPrice: undefined, transitionPriceWei: undefined})
                opacity && setData({opacityShow: true})
            })(),
            selectTokenIndex = checkData0(data, 'selectTokenIndex'),
            onScrollFunc = (ref, list, total, setPage) => {
                const {current} = ref
                if (list.length < total && (current['scrollHeight'] - current['scrollTop'] - 1 <= current['clientHeight']) && !showLoading) {
                    setPage()
                    toggleShowLoading(true)
                }
            }, poolPositionsScrollRight = React.createRef(), poolPositionsScroll = React.createRef(),
            poolPositionsList = (className = '', style = {}, isRightList) => {
                const ref = isRightList ? poolPositionsScrollRight : poolPositionsScroll
                return <div
                    style={style}
                    ref={ref}
                    onScroll={() => onScrollFunc(ref, poolPositions, checkData0(data, 'poolPositionsTotal'), () => setData({poolPositionPage: poolPositionPage + 1}))}
                    className={`${className} overflowYScroll boxSizingBorderBox ${isPhoneClientWidth ? 'paddingBigPhone' : 'paddingBig'}`}>
                    {poolPositions.map((v, i) => <div
                        className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone paddingBottomBigPhone borderBottomSmallGrayLinePhone' : 'childrenSpacingTopBig paddingBottomBig borderBottomSmallGrayLine'} ${i !== 0 ? (isPhoneClientWidth ? 'paddingTopBigPhone' : 'paddingTopBig') : ''}`}>
                        <div
                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'justifyContentSpaceBetween childrenSpacingLeftBiggerPhone' : 'childrenSpacingLeftBigger'}`}>
                            <div
                                className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                {tokenIcon((() => {
                                    const currency = checkDataString(v, 'name').split('/')
                                    return {
                                        ...v, currency0Name: currency[0], currency1Name: currency[1],
                                    }
                                })(), `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                <div>{v.name}</div>
                            </div>
                            {/*<div className={'colorGray'}>*/}
                            {/*    APY: {numToPercentage(checkData0(v, 'apy'),)}%*/}
                            {/*</div>*/}
                        </div>
                        <div
                            className={`${(isRightList || isPhoneClientWidth) ? (isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig') : (isRightList ? 'childrenSpacingTop' : 'flexCenter childrenSpacingLeft')}`}>
                            <div
                                className={`${(isRightList && !isPhoneClientWidth) ? 'childrenSpacingTop' : `flexCenter childrenFlexGrow1 ${isPhoneClientWidth ? '' : 'flexGrow1'}`}`}>
                                {[
                                    [
                                        'Your Pools Share',
                                        ...Array.from({length: 2}).map((_, i) =>
                                            num8(apiNumETH(checkData0(v, `amount${i}`), v[`currency${i}Decimals`]))),
                                        `${percentageNum(times(divide(checkData0(v, 'liquidity'), checkData0(v, 'totalSupply')), 100))}%`
                                    ],
                                    ['Fees', ...Array.from({length: 2}).map((_, i) => num8(apiNumETH(checkData0(v, `fee${i}`), v[`currency${i}Decimals`])))],].map(v1 =>
                                    <div
                                        className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTopSmall'}>
                                        <div
                                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                            <div
                                                className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                                {v1[0]}
                                            </div>
                                            {isNotNull(v1[3]) && <div
                                                className={`saf1 borderRadius999 activeColor ${isPhoneClientWidth ? 'paddingSmallPhone smallerFontSizePhone' : 'paddingSmall smallerFontSize'}`}
                                                style={{background: '#FFF4F5'}}>
                                                {v1[3]}
                                            </div>}
                                        </div>
                                        <div
                                            className={isPhoneClientWidth ? 'childrenSpacingTopSmallPhone' : 'flex alignItemsCenter childrenSpacingLeftBigger'}>
                                            {[1, 2].map((v2, i2) => <div
                                                className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>{poolName(v, i2)}: {v1[v2]}</div>)}
                                        </div>
                                    </div>)}
                            </div>
                            <div
                                className={`flex alignItemsCenter ${(isRightList || isPhoneClientWidth) ? `justifyContentCenter ${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone' : 'childrenSpacingLeftBig'}` : `${isRightList ? 'justifyContentCenter' : 'justifyContentFlexEnd'} childrenSpacingLeft`}`}>
                                {[
                                    ['Increase liquidity', () => windowReplace(`/pool/increase?poolid=${v.poolId}`)],
                                    ['Remove liquidity', async () => {
                                        const liquidity = divide(checkData0(v, 'liquidity'), checkData0(v, 'totalSupply')),
                                            amount0 = times(liquidity, checkData0(v, 'reserve0')),
                                            amount1 = times(liquidity, checkData0(v, 'reserve1')),
                                            realReserve0 = checkData0(v, 'realReserve0'),
                                            range0 = lessThanOrEqualTo(amount0, realReserve0) ? 100 : toInt(times(divide(realReserve0, amount0), 100)),
                                            realReserve1 = checkData0(v, 'realReserve1'),
                                            range1 = lessThanOrEqualTo(amount1, realReserve1) ? 100 : toInt(times(divide(realReserve1, amount1), 100))
                                        setData({
                                            range: 0, maxRange: lessThan(range0, range1) ? range0 : range1, poolData: {
                                                ...v,
                                                amount0: parseEtherNum(amount0, await erc20CallDecimals(v.currency0)),
                                                amount1: parseEtherNum(amount1, await erc20CallDecimals(v.currency1)),
                                            }
                                        })
                                        toggleShowLiquidity(true)
                                    }]].map(v => <div style={{
                                    background: '#FFF4F5',
                                    width: `${isPhoneClientWidth ? '45%' : '2.7rem'}`,
                                    height: `${isPhoneClientWidth ? 3 : .7}rem`
                                }}
                                                      onClick={v[1]}
                                                      className={`borderRadius999 saf1 activeColor ${isPhoneClientWidth ? 'paddingPhone' : 'padding smallFontSize'} flexCenter cursorPointer boxSizingBorderBox`}>
                                    {v[0]}
                                </div>)}
                            </div>
                        </div>
                    </div>)}
                </div>
            },
            erc20WriteContract = (contractAddress, ...params) => wC(contractAddress, erc20Abi, ...params),
            erc20MarginApprove = (marginAddress, contractAddress, amount, func) => erc20WriteContract(contractAddress, 'approve', undefined, [marginAddress, 115792089237316195423570985008687907853269984665640564039457584007913129639935n], func),
            erc20MarginHookManagerApprove = (...params) => erc20MarginApprove(marginHookManagerAddress, ...params),
            erc20MarginPositionManagerApprove = (...params) => erc20MarginApprove(marginPositionManagerAddress, ...params),
            erc20MarginRouterApprove = (...params) => erc20MarginApprove(marginRouterAddress, ...params),
            erc20BorrowPositionApprove = (...params) => erc20MarginApprove(borrowPositionManagerAddress, ...params),
            liquidityTitle = isPoolCreate ? 'Create a Pair' : (isPoolAdd ? 'Add liquidity' : (isPoolIncrease ? 'Increase liquidity' : 'Remove liquidity')),
            liquidityButton = isPoolCreate ? 'Create' : (isPoolAdd ? 'Add' : (isPoolIncrease ? 'Increase' : 'Remove')),
            percentageToNum = num => times(num, 10000),
            defaultDeadline = plus(Math.floor(npDivide(Date.now(), 1000)), defaultDeadlineSecond),
            approveContent = arr => <div
                className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                {(() => {
                    const size = `${isPhoneClientWidth ? 1.5 : .5}rem`,
                        isCreateInitializeEnd = checkDataBool(data, 'isCreateInitializeEnd')
                    return [...isPoolCreate ? [['Initialize', isCreateInitializeEnd ? 'Group 427320704-2' : 'rotatingLoading', !isCreateInitializeEnd]] : [], ...arr.map(v => [`Approve ${v.needApprove ? 'pending' : ''}`, v.needApprove ? ((!isPoolCreate || isCreateInitializeEnd) ? 'rotatingLoading' : 'Group 427320704') : 'Group 427320704-2', !v.needApprove]), ['Confirm', !arr.map(v => v.needApprove).includes(true) ? 'rotatingLoading' : 'Confirm Swap']].map((v, i) => [i !== 0 &&
                    <div style={{width: size, height: `calc(${size} / 2 )`}}
                         className={'flexCenter'}>
                        <div className={'height100'} style={{width: '10%', background: '#C0C0C0'}}/>
                    </div>, <div
                        className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                        <img src={requireDefault(v[1])} alt="" style={square(size)}/>
                        <div
                            className={`${v[2] ? '' : 'colorGray'} ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[0]}</div>
                    </div>])
                })()}
            </div>, transactionDeadline = checkData([data, 'transactionDeadline'], defaultDeadlineSecond / 60),
            deadline = Number(plus(Math.floor(divide(Date.now(), 1000)), times(transactionDeadline || 0, 60))),
            liquidityContent = (() => {
                const addLiquidity = poolId => {
                    const tick = percentageToNum(maxSlippage)
                    // console.log([
                    //     poolId,
                    //     ...(hexToNumber(token0Address) > hexToNumber(token1Address)
                    //         ? [token1NewAmount, token0NewAmount]
                    //         : tokenDatas).map(v => v.newAmountWei),
                    //     tick, tick, address, deadline
                    // ])
                    return marginHookManagerWriteContract('addLiquidity', token0IsETH ? token0NewAmountWei : undefined, [[poolId, ...(hexToNumber(token0Address) > hexToNumber(token1Address) ? [token1NewAmount, token0NewAmount] : tokenDatas).map(v => v.newAmountWei), tick, tick, liquidityLevel, address, deadline]], hash =>
                        addRemoveApi('add', hash, poolId, logs => {
                        }, () => {
                        }))
                }, currentShares = checkData0(data, 'currentShares'), removeShares = divide(range, 100)
                return maskContent(
                    liquidityTitle,
                    checkDataBool(data, 'showLiquidity'),
                    toggleShowLiquidity,
                    <div
                        style={{marginTop: `${isPhoneClientWidth ? 1.5 : .5}rem`}}
                        className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}>
                        <div
                            className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                            {!isPoolRemove && [
                                <div
                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                    {tokenIcon(isPoolCreate ? (() => {
                                        let obj = {poolId: 'poolId', name: tokenDataPoolName}
                                        tokenDatas.map((v, i) => obj = {
                                            ...obj,
                                            [`currency${i}`]: v.tokenAddress,
                                            [`currency${i}Avatar`]: v.avatar,
                                            [`currency${i}Name`]: v.name,
                                            [`currency${i}Symbol`]: v.symbol,
                                        })
                                        return obj
                                    })() : poolData, `${isPhoneClientWidth ? 1.5 : .4}rem`)}
                                    <div
                                        className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>
                                        {tokenDataPoolName}
                                    </div>
                                </div>,
                                <div
                                    className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone paddingBigPhone borderRadiusPhone borderSmallGrayLinePhone' : 'childrenSpacingTopBig paddingBig borderRadius borderSmallGrayLine'}`}>
                                    {tokenDatas.map(v => <div
                                        className={'flexCenter justifyContentSpaceBetween'}>
                                        <div
                                            className={`saf flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                            {tokenIcon(v, `${isPhoneClientWidth ? 2.5 : .56}rem`)}
                                            <div
                                                className={isPhoneClientWidth ? '' : 'smallFontSize'}>
                                                {v.symbol}
                                            </div>
                                        </div>
                                        <div
                                            className={isPhoneClientWidth ? '' : 'smallFontSize'}>{checkData0(v, 'newAmount')}</div>
                                    </div>)}
                                </div>]}
                            {!isPoolCreate && <div
                                className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingBigPhone childrenSpacingTopPhone borderSmallGrayLinePhone' : 'borderRadius paddingBig childrenSpacingTopSmall borderSmallGrayLine'} flexColumnCenter backgroundGrey`}>
                                <div style={{color: '#7D7D7D'}}
                                     className={isPhoneClientWidth ? '' : 'smallerFontSize'}>
                                    Shares
                                </div>
                                <div
                                    className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                    <div className={isPhoneClientWidth ? 'bigFontSizePhone' : ''}>
                                        {percentageReplace(currentShares, true)}%
                                    </div>
                                    <img src={requireDefault('doubleArrow')} alt=""
                                         style={{width: `${isPhoneClientWidth ? 1.7 : .4}rem`}}/>
                                    <div className={isPhoneClientWidth ? 'bigFontSizePhone' : ''}>
                                        {(() => {
                                            const {liquidity = 0} = poolData,
                                                numWei = parseEtherNum(token0NewAmount, token0Decimals)
                                            return isPoolAdd
                                                ? (
                                                    percentageReplace(
                                                        divide(
                                                            plus(
                                                                numWei,
                                                                times(
                                                                    currentShares, reserve0
                                                                )
                                                            ),
                                                            plus(
                                                                reserve0, numWei
                                                            )
                                                        )
                                                    )
                                                )
                                                : (percentageReplace(
                                                    isPoolRemove
                                                        ? (() => {
                                                            return divide(
                                                                times(
                                                                    liquidity, minus(1, removeShares)
                                                                ),
                                                                minus(
                                                                    checkData0(poolData, 'totalSupply'),
                                                                    times(
                                                                        liquidity, removeShares
                                                                    )
                                                                )
                                                            )
                                                        })()
                                                        : (() => {
                                                            const p = plus(
                                                                currentShares,
                                                                divide(
                                                                    parseEtherNum(token0NewAmount, token0Decimals),
                                                                    reserve0
                                                                )
                                                            )
                                                            return p > 1 ? 1 : p
                                                        })(),
                                                    true
                                                ))
                                        })()}%
                                    </div>
                                </div>
                            </div>}
                            {isPoolRemove && <div
                                className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone paddingBigPhone borderRadiusPhone borderSmallGrayLinePhone' : 'childrenSpacingTopBig paddingBig borderRadius borderSmallGrayLine'}`}>
                                <div className={'flex childrenFlexGrow1'}>
                                    {tokenDatas.map((v, i) => <div
                                        className={'flexColumnCenter childrenSpacingTop'}>
                                        <div
                                            className={isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'}>{numberETH(times(formatEther(checkData0(poolData, `amount${i}`), v.decimals), removeShares), v.decimals)}</div>
                                        <div
                                            className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                            {tokenIcon(v, `${isPhoneClientWidth ? 1.7 : .4}rem`)}
                                            <div
                                                className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>{v.symbol}</div>
                                        </div>
                                    </div>)}
                                </div>
                                {rangeContent()}
                            </div>}
                        </div>
                        {showApprove ? approveContent([{
                                symbol: tokenDataPoolName,
                                needApprove: tokenDataNeedApprove
                            }]) :
                            <div style={isPhoneClientWidth ? {marginBottom: '2rem'} : {}}
                                 onClick={async () => {
                                     if (isPoolRemove) {
                                         const liquidity = BigInt(toInt(times(checkData0(data, 'ownerLiquidity'), removeShares)))
                                         // console.log([poolDataPoolId, liquidityLevel, liquidity, defaultDeadline])
                                         marginHookManagerWriteContract('removeLiquidity', undefined, [
                                                 [poolDataPoolId, liquidityLevel, liquidity, defaultDeadline]],
                                             hash => addRemoveApi('remove', hash, poolDataPoolId, () => {
                                             }, logs => {
                                             }))
                                     } else {
                                         toggleShowApprove(tokenDataNeedApprove)
                                         let poolId = poolDataPoolId
                                         const t1Allowance = () => token1Allowance().then(t1A => t1A > 0 ? erc20MarginHookManagerApprove(token1Address, t1A, hash => waitForTransactionFunc(hash, t1Allowance)) : addLiquidity(poolId)),
                                             t0Allowance = () => token0Allowance().then(t0A => t0A > 0 ? erc20MarginHookManagerApprove(token0Address, t0A, hash => waitForTransactionFunc(hash, t0Allowance)) : t1Allowance()),
                                             allowance = token0IsETH ? t1Allowance : t0Allowance;
                                         isPoolCreate ? marginHookManagerWriteContract('initialize', undefined, [[...hexToNumber(token0Address) > hexToNumber(token1Address) ? [token1Address, token0Address] : tokenDatas.map(v => v.tokenAddress), 3000, 1, marginHookManagerAddress]], hash => waitForTransactionFunc(hash, // () => requestApi(
                                             //     'margin/pool/initialize',
                                             //     {
                                             //         tx: hash,
                                             //         chainId,
                                             //         sender: address,
                                             //         name: tokenDataPoolName
                                             //     },
                                             //     'post'
                                             // ).then(pI => {
                                             //     poolId = pI
                                             //     setData({isCreateInitializeEnd: true})
                                             //     allowance()
                                             // })
                                             ({logs}) => {
                                                 poolId = logs[0].topics[1]
                                                 setData({isCreateInitializeEnd: true})
                                                 allowance()
                                             })) : allowance()
                                     }
                                 }}
                                 className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingTopBottomPhone' : 'borderRadius paddingTopBottomBig'} flexCenter colorWhite saf1 backgroundActiveColor cursorPointer`}>
                                {needApproveButton(tokenDataNeedApprove, liquidityButton)}
                            </div>}
                    </div>)
            })(),
            positionsScroll = React.createRef(),
            positionsPools = [
                ...isShowBorrow
                    ? [
                        [
                            'Borrow\nPositions',
                            <div style={isPhoneClientWidth ? {maxHeight: '50vh'} : {}}
                                 ref={positionsScroll}
                                 onScroll={() => onScrollFunc(positionsScroll, positions, checkData0(data, `${positionsKey}Total`), () => setData({positionPage: positionPage + 1}))}
                                 className={`${isPhoneClientWidth ? 'borderRadiusPhone borderSmallGrayLinePhone paddingBigPhone childrenSpacingTopPhone' : 'borderRadius borderSmallGrayLine flexGrow1Column paddingBig childrenSpacingTop'} overflowYScroll boxSizingBorderBox backgroundWhite`}>
                                {
                                    checkData([data, 'listLoading'], true)
                                        ? <div
                                            className={`height100 flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}`}>
                                            <img src={requireDefault('loading')}
                                                 alt=""
                                                 style={{width: `${isPhoneClientWidth ? 12 : 4}rem`}}/>
                                        </div>
                                        : (() => {
                                            const arr = isHistory ? positions : positions.filter(v => !v.liquidated)
                                            return arr.length > 0
                                                ? arr.map((v, i) => <div
                                                    className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                                    {i !== 0 && <img src={requireDefault('Line 3')} alt=""
                                                                     className={'width100'}/>}
                                                    <div className={'flex alignItemsCenter justifyContentSpaceBetween'}>
                                                        <div
                                                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                            {(() => {
                                                                const text = isBorrowPositions ? 'borrowIcon' : (v.marginForOne ? 'Buy' : 'Sell')
                                                                return [<img src={requireDefault(text)} alt=""
                                                                             style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>,
                                                                    <div
                                                                        className={`${isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'} saf1`}>{isBorrowPositions ? 'Borrow' : text}</div>]
                                                            })()}
                                                        </div>
                                                        {isHistory &&
                                                            (() => {
                                                                const burnType = v.burnType === 0
                                                                return <div
                                                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                                                    <div
                                                                        className={`borderRadius999 ${burnType ? 'backgroundRed' : 'backGroundBlueGray'}`}
                                                                        style={square(`${isPhoneClientWidth ? .5 : .15}rem`)}/>
                                                                    <div
                                                                        className={`${burnType ? 'colorRed' : 'colorGray'} saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                                                        {burnType ? 'Closed' : 'Liquidated'}
                                                                    </div>
                                                                </div>
                                                            })()}
                                                    </div>
                                                    {
                                                        !isBorrowPositions && (() => {
                                                            const marginForOne = v.marginForOne,
                                                                currency0Name = v[marginForOne ? 'borrowSymbol' : 'marginSymbol'],
                                                                currency1Name = v[marginForOne ? 'marginSymbol' : 'borrowSymbol']
                                                            return <div
                                                                className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                                                {
                                                                    tokenIcon(
                                                                        {
                                                                            poolId: v.poolId,
                                                                            currency0: v[marginForOne ? 'borrowToken' : 'marginToken'],
                                                                            currency1: v[marginForOne ? 'marginToken' : 'borrowToken'],
                                                                            currency0Name,
                                                                            currency1Name,
                                                                            currency0Avatar: v[marginForOne ? 'borrowAvatar' : 'marginAvatar'],
                                                                            currency1Avatar: v[marginForOne ? 'marginAvatar' : 'borrowAvatar'],
                                                                        },
                                                                        `${isPhoneClientWidth ? 1.8 : .6}rem`)
                                                                }
                                                                <div>
                                                                    {v.poolName}
                                                                </div>
                                                            </div>
                                                        })()
                                                    }
                                                    <div
                                                        className={isHistory ? (isPhoneClientWidth ? 'childrenSpacingTopSmallPhone' : 'childrenSpacingTopSmall') : ''}>
                                                        <div
                                                            className={`colorGray ${isPhoneClientWidth ? '' : 'smallFontSize'}`}>
                                                            Margin Amount
                                                        </div>
                                                        <div className={'flexCenter justifyContentSpaceBetween'}>
                                                            <div
                                                                className={`saf1 ${isPhoneClientWidth ? '' : 'smallFontSize'}`}>{v.marginAmount} {v.marginSymbol}</div>
                                                            {isHistory || isBorrowPositions
                                                                ? <div/>
                                                                : <div
                                                                    style={{padding: `${isPhoneClientWidth ? .15 : .05}rem ${isPhoneClientWidth ? .45 : .15}rem`}}
                                                                    onClick={() => toggleShowAdjust(true, v)}
                                                                    className={`borderSmallGrayLine flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} borderRadius999 cursorPointer`}>
                                                                    <img src={requireDefault('Adjust')} alt=""
                                                                         style={square(`${isPhoneClientWidth ? .9 : .3}rem`)}/>
                                                                    <div
                                                                        className={`activeColor ${isPhoneClientWidth ? '' : 'smallFontSize'}`}>Adjust
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {closePositionData(false, v)}
                                                    {!isHistory && <div
                                                        className={`flexCenter justifyContentFlexEnd ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                        {[
                                                            ...isBorrowPositions
                                                                ? []
                                                                : [['Close', () => toggleShowClosePosition(true, v)]],
                                                            ['Repay', () => toggleShowRepaying(true, v)]
                                                        ].map(v =>
                                                            <div onClick={v[1]}
                                                                 className={`borderRadius999 backgroundActiveColor flexCenter saf1 colorWhite ${isPhoneClientWidth ? '' : 'smallFontSize'} cursorPointer`}
                                                                 style={{
                                                                     width: `${isPhoneClientWidth ? 6.5 : 1.5}rem`,
                                                                     height: `${isPhoneClientWidth ? 2.5 : .5}rem`
                                                                 }}
                                                            >{v[0]}</div>)}
                                                    </div>}
                                                </div>)
                                                : <div
                                                    className={`height100 flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}`}>
                                                    <img src={requireDefault('Group 427320250')}
                                                         alt=""
                                                         style={{width: `${isPhoneClientWidth ? 6 : 2}rem`}}/>
                                                    <pre
                                                        className={`textAlignCenter ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{`Your active margin positions will 
appear here.`}</pre>
                                                </div>

                                        })()
                                }
                            </div>
                        ],
                    ]
                    : [],
                [
                    'Margin\nPositions',
                    <div style={isPhoneClientWidth ? {maxHeight: '50vh'} : {}}
                         ref={positionsScroll}
                         onScroll={() => onScrollFunc(positionsScroll, positions, checkData0(data, `${positionsKey}Total`), () => setData({positionPage: positionPage + 1}))}
                         className={`${isPhoneClientWidth ? 'borderRadiusPhone borderSmallGrayLinePhone paddingBigPhone childrenSpacingTopPhone' : 'borderRadius borderSmallGrayLine flexGrow1Column paddingBig childrenSpacingTop'} overflowYScroll boxSizingBorderBox backgroundWhite`}>
                        {
                            checkData([data, 'listLoading'], true)
                                ? <div
                                    className={`height100 flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}`}>
                                    <img src={requireDefault('loading')}
                                         alt=""
                                         style={{width: `${isPhoneClientWidth ? 12 : 4}rem`}}/>
                                </div>
                                : (() => {
                                    const arr = isHistory ? positions : positions.filter(v => !v.liquidated)
                                    return arr.length > 0
                                        ? arr.map((v, i) => <div
                                            className={`${isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}`}>
                                            {i !== 0 && <img src={requireDefault('Line 3')} alt=""
                                                             className={'width100'}/>}
                                            <div className={'flex alignItemsCenter justifyContentSpaceBetween'}>
                                                <div
                                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                    {(() => {
                                                        const text = v.marginForOne ? 'Buy' : 'Sell'
                                                        return [<img src={requireDefault(text)} alt=""
                                                                     style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>,
                                                            <div
                                                                className={`${isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'} saf1`}>{text}</div>]
                                                    })()}
                                                </div>
                                                {isHistory &&
                                                    (() => {
                                                        const burnType = v.burnType === 0
                                                        return <div
                                                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                                            <div
                                                                className={`borderRadius999 ${burnType ? 'backgroundRed' : 'backGroundBlueGray'}`}
                                                                style={square(`${isPhoneClientWidth ? .5 : .15}rem`)}/>
                                                            <div
                                                                className={`${burnType ? 'colorRed' : 'colorGray'} saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                                                {burnType ? 'Closed' : 'Liquidated'}
                                                            </div>
                                                        </div>
                                                    })()}
                                            </div>
                                            {
                                                (() => {
                                                    const marginForOne = v.marginForOne,
                                                        currency0Name = v[marginForOne ? 'borrowSymbol' : 'marginSymbol'],
                                                        currency1Name = v[marginForOne ? 'marginSymbol' : 'borrowSymbol']
                                                    return <div
                                                        className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                                        {
                                                            tokenIcon(
                                                                {
                                                                    poolId: v.poolId,
                                                                    currency0: v[marginForOne ? 'borrowToken' : 'marginToken'],
                                                                    currency1: v[marginForOne ? 'marginToken' : 'borrowToken'],
                                                                    currency0Name,
                                                                    currency1Name,
                                                                    currency0Avatar: v[marginForOne ? 'borrowAvatar' : 'marginAvatar'],
                                                                    currency1Avatar: v[marginForOne ? 'marginAvatar' : 'borrowAvatar'],
                                                                },
                                                                `${isPhoneClientWidth ? 1.8 : .6}rem`)
                                                        }
                                                        <div>
                                                            {v.poolName}
                                                        </div>
                                                    </div>
                                                })()
                                            }
                                            <div
                                                className={isHistory ? (isPhoneClientWidth ? 'childrenSpacingTopSmallPhone' : 'childrenSpacingTopSmall') : ''}>
                                                <div
                                                    className={`colorGray ${isPhoneClientWidth ? '' : 'smallFontSize'}`}>
                                                    Margin Amount
                                                </div>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div
                                                        className={`saf1 ${isPhoneClientWidth ? '' : 'smallFontSize'} ${checkData([positionsOpacityShow, v.id], true) ? 'opacityShow' : 'opacityHidden'}`}>{v.marginAmount} {v.marginSymbol}</div>
                                                    {isHistory
                                                        ? <div/>
                                                        : <div
                                                            style={{padding: `${isPhoneClientWidth ? .15 : .05}rem ${isPhoneClientWidth ? .45 : .15}rem`}}
                                                            onClick={() => toggleShowAdjust(true, v)}
                                                            className={`borderSmallGrayLine flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} borderRadius999 cursorPointer`}>
                                                            <img src={requireDefault('Adjust')} alt=""
                                                                 style={square(`${isPhoneClientWidth ? .9 : .3}rem`)}/>
                                                            <div
                                                                className={`activeColor ${isPhoneClientWidth ? '' : 'smallFontSize'}`}>Adjust
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {closePositionData(false, v)}
                                            {!isHistory && <div
                                                className={`flexCenter justifyContentFlexEnd ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                {[['Close', () => toggleShowClosePosition(true, v)], ['Repay', () => toggleShowRepaying(true, v)],].map(v =>
                                                    <div onClick={v[1]}
                                                         className={`borderRadius999 backgroundActiveColor flexCenter saf1 colorWhite ${isPhoneClientWidth ? '' : 'smallFontSize'} cursorPointer`}
                                                         style={{
                                                             width: `${isPhoneClientWidth ? 6.5 : 1.5}rem`,
                                                             height: `${isPhoneClientWidth ? 2.5 : .5}rem`
                                                         }}
                                                    >{v[0]}</div>)}
                                            </div>}
                                        </div>)
                                        : <div
                                            className={`height100 flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}`}>
                                            <img src={requireDefault('Group 427320250')}
                                                 alt=""
                                                 style={{width: `${isPhoneClientWidth ? 6 : 2}rem`}}/>
                                            <pre
                                                className={`textAlignCenter ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{`Your active margin positions will 
appear here.`}</pre>
                                        </div>

                                })()
                        }
                    </div>
                ],
                [
                    '',//'Pools',
                    poolPositionsList(undefined, isPhoneClientWidth ? {maxHeight: '50vh'} : {}, true)
                ],
                ...isShowBorrow
                    ? []
                    : [[]]
            ],
            togglePositionsPoolIndex = i => !equalTo(positionsPoolIndex, i) && setData({
                positionsPoolIndex: i,
                listLoading: undefined,
                positionPage: defaultPage,
            }),
            repayTokenData = checkDataObject(data, 'repayTokenData'), {
                poolId: repayTokenDataPoolId,
                borrowToken: repayTokenDataBorrowToken,
                marginToken: repayTokenDataMarginToken,
                borrowSymbol: repayTokenDataBorrowSymbol = '',
                marginSymbol: repayTokenDataMarginSymbol = '',
                borrowAmountWei: repayTokenDataBorrowAmountWei = 0,
                borrowDecimals: repayTokenDataBorrowDecimals = defaultDecimals,
                marginDecimals: repayTokenDataMarginDecimals = defaultDecimals,
                positionId: repayTokenDataPositionId,
                marginForOne: repayTokenDataMarginForOne
            } = repayTokenData,
            repayTokenDataBorrowTokenIsETH = isAddress0(repayTokenDataBorrowToken),
            repayTokenDataNewAmount = checkData0(data, 'repayTokenDataNewAmount'),
            repayTokenDataNewAmountWei = checkData([data, 'repayTokenDataNewAmountWei'], 0n),
            repayTokenDataBorrowAmount = checkData0(repayTokenData, 'borrowAmount'),
            repayTokenDataNewAmountEligible = Number(repayTokenDataNewAmount) <= repayTokenDataBorrowAmount,
            afterRepayTokenDataBorrowAmount = minus(repayTokenDataBorrowAmount, repayTokenDataNewAmount),
            getRepayTokenPrice = amount => hexToNumber(repayTokenDataBorrowToken) > hexToNumber(repayTokenDataMarginToken) ? getAmountOut(amount, false, repayTokenDataPoolId, repayTokenDataMarginDecimals) : new Promise(resolve => resolve),
            ethBalance = checkData0(data, 'ethBalance'),
            getTokenBalance = tokenAddress => isAddress0(tokenAddress)
                ? ethBalance
                : checkDataString(tokenBalances, tokenAddress.toLowerCase()),
            positionsPoolsContent = (() => {
                const marginPositionsIndex = isShowBorrow ? 1 : 0,
                    isMarginPositions = positionsPoolIndex === marginPositionsIndex,
                    isNoMarginPositions = !isMarginPositions,
                    content = <div style={{borderRadius: '.5rem'}}
                                   className={`boxSizingBorderBox ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'backgroundWhite height100 flexGrow1 boxShadow paddingBig childrenSpacingTopBig'} flexColumn`}>
                        <div className={'flexCenter justifyContentSpaceBetween'}>
                            {addressData(true)}
                            <img onClick={() => {
                                toggleShowPositionsPools()
                                connect()
                            }} className={'cursorPointer'}
                                 src={requireDefault('Group 427320608')} alt=""
                                 style={{width: `${isPhoneClientWidth ? 2 : .7}rem`}}/>
                        </div>
                        <div
                            className={isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'}>
                            {ethBalance} {coinName}
                        </div>
                        <div className={`flexColumn ${isPhoneClientWidth ? '' : 'flexGrow1Column'}`}>
                            <div style={{
                                paddingTop: `${isPhoneClientWidth ? .3 : .1}rem`,
                                marginBottom: `-${isPhoneClientWidth ? (isNoMarginPositions ? 5 : .9) : (isNoMarginPositions ? 1.2 : .3)}rem`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundImage: `url(${requireDefault(`oldImage/positions${positionsPoolIndex}`)})`
                            }} className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                <div
                                    className={`flex alignItemsCenter childrenFlexGrow1 ${isPhoneClientWidth ? '' : 'marginTopPhone'}`}>
                                    {positionsPools.map((v, i) =>
                                        <pre
                                            onClick={() => positionsPoolIndex <= marginPositionsIndex && togglePositionsPoolIndex(i)}
                                            className={`${isPhoneClientWidth ? 'bigFontSizePhone' : ''} ${i === positionsPoolIndex ? 'saf1 activeColor' : ''} textAlignCenter cursorPointer`}>
                                            {v[0]}
                                        </pre>)}
                                </div>
                                {
                                    <div style={{
                                        paddingTop: `${isPhoneClientWidth ? (isNoMarginPositions ? 3.5 : 1) : (isNoMarginPositions ? .85 : .2)}rem`,
                                        paddingBottom: `${isPhoneClientWidth ? 2.1 : .52}rem`
                                    }}
                                         className={`${isPhoneClientWidth ? 'paddingPhone' : 'padding'} childrenSpacingLeft flexCenter childrenFlexGrow1`}>
                                        {isMarginPositions && [
                                            ['Current Positions', !isHistory, false],
                                            ['Position History', isHistory, true],
                                        ].map(v => <div style={{padding: `${isPhoneClientWidth ? .7 : .15}rem 0`}}
                                                        onClick={() => !!isHistory !== v[2] && setData({
                                                            isHistory: v[2],
                                                            listLoading: undefined,
                                                            positionPage: defaultPage,
                                                        })}
                                                        className={`flexCenter borderRadius999 ${v[1] ? `backgroundWhite ${isPhoneClientWidth ? 'borderSmallGrayLinePhone' : 'borderSmallGrayLine'}` : ''} ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} cursorPointer`}>{v[0]}</div>)}
                                    </div>
                                }
                            </div>
                            {positionsPools[positionsPoolIndex][1]}
                        </div>
                    </div>,
                    increaseDecreaseIndex = checkData0(data, 'increaseDecreaseIndex'),
                    isDecrease = increaseDecreaseIndex === 1,
                    repayAdjust = showApprove ? approveContent([{
                        ...repayTokenData,
                        symbol: showRepaying ? repayTokenDataBorrowSymbol : repayTokenDataMarginSymbol
                    }]) : <div style={isPhoneClientWidth ? {marginBottom: '2rem'} : {}}
                               onClick={() => {
                                   const repayToken = showRepaying ? repayTokenDataBorrowToken : repayTokenDataMarginToken,
                                       amountWei = showRepaying
                                           ? toInt(
                                               plus(
                                                   repayTokenDataNewAmountWei,
                                                   divide(
                                                       times(
                                                           repayTokenDataNewAmountWei,
                                                           divide(
                                                               checkData0(repayTokenData, 'apy'),
                                                               100
                                                           ),
                                                       ),
                                                       times(
                                                           divide(
                                                               divide(
                                                                   365,
                                                                   24,
                                                               ),
                                                               60
                                                           ),
                                                           10
                                                       )
                                                   )
                                               )
                                           )
                                           : (BigInt(times(repayTokenDataNewAmountWei, isDecrease ? -1 : 1))),
                                       iA0 = isAddress0(repayToken),
                                       repay = () => (
                                           isBorrowPositions ? borrowPositionManagerWriteContract : marginPositionManagerWriteContract
                                       )(
                                           showRepaying ? 'repay' : 'modify',
                                           (iA0 && amountWei > 0) ? amountWei : undefined,
                                           [repayTokenDataPositionId, amountWei, ...showRepaying ? [defaultDeadline] : []],
                                           hash => {
                                               searchPositionPool(0)
                                               toggleShowLoading()
                                               toggleShowRepaying()
                                               toastSuccessful(hash)
                                           }),
                                       rTAllowance = isSet => (showRepaying ? erc20MarginHookManagerAllowance : erc20MarginPositionManagerAllowance)(repayToken, amountWei)
                                           .then(a => {
                                               const needApprove = a > 0
                                               setData({
                                                   repayTokenData: {
                                                       ...repayTokenData, needApprove
                                                   }
                                               });
                                               (needApprove ? () => {
                                                   isSet && toggleShowApprove(isSet);
                                                   (showRepaying ? erc20MarginHookManagerApprove : erc20MarginPositionManagerApprove)(repayToken, a, hash => waitForTransactionFunc(hash, rTAllowance))
                                               } : repay)()
                                           });
                                   iA0 ? repay() : rTAllowance(true)
                               }}
                               className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingTopBottomPhone' : 'borderRadius paddingTopBottomBig'} flexCenter colorWhite saf1 backgroundActiveColor cursorPointer`}>
                        {showRepaying ? 'Repay' : 'Adjust'}
                    </div>,
                    setRepayTokenNewAmount = async (nA, newAmountWei, changeRange = true) => {
                        const newAmountWeiIsNotNull = isNotNull(newAmountWei),
                            newAmount = newAmountWeiIsNotNull ? numberETH(newAmountWei, showAdjust ? repayTokenDataMarginDecimals : repayTokenDataBorrowDecimals) : getSetNewAmount(nA)
                        newAmountWei = newAmountWeiIsNotNull ? newAmountWei : parseEtherNum(newAmount, showAdjust ? repayTokenDataMarginDecimals : repayTokenDataBorrowDecimals)
                        setData({
                            repayTokenDataNewAmount: newAmount,
                            repayTokenDataNewAmountWei: newAmountWei,
                            ...showClosePosition && changeRange ? {
                                range: toInt(times(divide(newAmount, repayTokenDataBorrowAmount), 100))
                            } : {}
                        })
                    }, increaseDecrease = ['Increase', 'Decrease']
                return [isPhoneClientWidth ? maskContent(undefined, showPositionsPools, toggleShowPositionsPools, content) :
                    <div
                        className={`positionFixed boxSizingBorderBox flex childrenSpacingLeft positionsPools${showPositionsPools ? 'Show' : ''}`}
                        style={{
                            top: 0,
                            zIndex: 999,
                            width: '29.5vw',
                            height: '100vh',
                            padding: '.2rem',
                            paddingBottom: '1rem'
                        }}
                    >
                        <img onClick={toggleShowPositionsPools} src={requireDefault('retract')} alt=""
                             className={'cursorPointer'}
                             style={{...square('.7rem'), marginTop: '.7rem'}}/>
                        {content}
                    </div>,
                    maskContent(`Adjust ${isBorrowPositions ? 'Borrow' : 'Margin'}`, showAdjust, toggleShowAdjust, (() => {
                        const repayTokenDataBalance = checkData0(repayTokenData, 'balance'),
                            repayTokenIsETHPool = [repayTokenDataBorrowToken, repayTokenDataMarginToken].includes(address0), {
                                marginBalance, maxDecrease
                            } = repayTokenData,
                            maxBalance = isDecrease ? marginBalance <= maxDecrease ? marginBalance : maxDecrease : marginBalance,
                            repayTokenDataMarginIsETH = isAddress0(repayTokenDataMarginToken)
                        return <div
                            className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}>
                            <div/>
                            <div
                                className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                <div
                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                    {increaseDecrease.map((v, i) => <div
                                        style={{padding: isPhoneClientWidth ? '.3rem .9rem' : '.1rem .3rem'}}
                                        onClick={() => setData({increaseDecreaseIndex: i})}
                                        className={`cursorPointer borderRadius999 ${increaseDecreaseIndex === i ? 'backgroundGrey saf1' : ''}`}>
                                        {v}
                                    </div>)}
                                </div>
                                <div
                                    className={`backgroundGrey ${isPhoneClientWidth ? 'paddingBigPhone borderRadiusPhone borderSmallGrayLinePhone childrenSpacingTopPhone' : 'paddingBig borderRadius borderSmallGrayLine childrenSpacingTop'}`}>
                                    <div
                                        className={`flex alignItemsCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                        <input type="text"
                                               placeholder='0'
                                               value={checkData([data, 'repayTokenDataNewAmount'])}
                                               onChange={e => setRepayTokenNewAmount(e.target.value)}
                                               className={`${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'} flexGrow1`}/>
                                        <div
                                            className={`borderRadius999 saf flexCenter ${isPhoneClientWidth ? 'borderSmallGrayLinePhone childrenSpacingLeftPhone' : 'borderSmallGrayLine childrenSpacingLeft'}`}
                                            style={{
                                                height: `${isPhoneClientWidth ? 2.5 : .8}rem`,
                                                padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                            }}>
                                            {tokenIcon({
                                                tokenAddress: repayTokenDataMarginToken,
                                                symbol: repayTokenDataMarginSymbol,
                                                avatar: repayTokenDataMarginIsETH ? ethAvatar : repayTokenData.marginAvatar
                                            }, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                            <div>{repayTokenDataMarginSymbol}</div>
                                        </div>
                                    </div>
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div
                                            className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                            {repayTokenIsETHPool ? checkData0(repayTokenData, isDecrease ? 'maxDecreasePrice' : 'marginBalancePrice') : ''}
                                        </div>
                                        <div
                                            className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                            {(() => {
                                                return [
                                                    <div
                                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                                        {maxBalance} {repayTokenDataMarginSymbol}
                                                    </div>,
                                                    <div style={{fontSize: `${isPhoneClientWidth ? .6 : .15}rem`}}
                                                         onClick={() => setRepayTokenNewAmount(maxBalance)}
                                                         className={`backgroundActiveColor ${isPhoneClientWidth ? 'paddingSmallPhone' : 'paddingSmall'} borderRadius999 colorWhite cursorPointer`}>
                                                        MAX
                                                    </div>
                                                ]
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(() => {
                                const markPrice = divide(apiNumETH(checkData0(repayTokenData, `reserve${repayTokenDataMarginForOne ? 1 : 0}`), repayTokenDataMarginDecimals), apiNumETH(checkData0(repayTokenData, `reserve${repayTokenDataMarginForOne ? 0 : 1}`), repayTokenDataBorrowDecimals)), {
                                        marginAmount, borrowAmount, marginTotal
                                    } = repayTokenData,
                                    afterMarginAmount = (isDecrease ? minus : plus)(marginAmount, repayTokenDataNewAmount)
                                return <div
                                    className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div
                                            className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                            Liq.Price
                                        </div>
                                        {rightArrowText(...[marginAmount, afterMarginAmount].map(v => {
                                            const num = divide(plus(marginTotal, times(liquidationLTVNum, v)), borrowAmount)
                                            return [`${num8(num, repayTokenDataMarginDecimals)} ${checkDataString(repayTokenDataMarginSymbol)}`,
                                                <span
                                                    className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{repayTokenDataMarginIsETH ? ` (${getTokenUSDTPrice(num)})` : ''}</span>]
                                        }))}
                                    </div>
                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                        <div
                                            className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                            Margin Level
                                        </div>
                                        {rightArrowText(...[marginAmount, afterMarginAmount].map(v => {
                                            return `${num8(divide(minus(times(markPrice, borrowAmount), marginTotal), v))}`
                                        }))}
                                    </div>
                                </div>
                            })()}
                            {repayAdjust}
                        </div>
                    })()),
                    maskContent('You’re Repaying', showRepaying, toggleShowRepaying, (() => {
                        const repayTokenDataBalance = checkData0(repayTokenData, 'borrowBalance'),
                            repayTokenIsETHPool = [repayTokenDataBorrowToken, repayTokenDataMarginToken].includes(address0)
                        return <div
                            className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}>
                            <div/>
                            <div
                                className={isPhoneClientWidth ? 'paddingBigPhone borderRadiusPhone borderSmallGrayLinePhone childrenSpacingTopPhone' : 'paddingBig borderRadius borderSmallGrayLine childrenSpacingTop'}>
                                <div
                                    className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>Amount
                                </div>
                                <div
                                    className={`flex alignItemsCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                    <input type="text"
                                           placeholder='0'
                                           value={checkData([data, 'repayTokenDataNewAmount'])}
                                           onChange={e => setRepayTokenNewAmount(e.target.value)}
                                           className={`${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'} flexGrow1`}/>
                                    <div
                                        className={`borderRadius999 saf flexCenter ${isPhoneClientWidth ? 'borderSmallGrayLinePhone childrenSpacingLeftPhone' : 'borderSmallGrayLine childrenSpacingLeft'}`}
                                        style={{
                                            height: `${isPhoneClientWidth ? 2.5 : .8}rem`,
                                            padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                        }}>
                                        {tokenIcon({
                                            tokenAddress: repayTokenDataBorrowToken,
                                            symbol: repayTokenDataBorrowSymbol, avatar: repayTokenData.borrowAvatar
                                        }, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                        <div>{repayTokenDataBorrowSymbol}</div>
                                    </div>
                                </div>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div
                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                        {repayTokenIsETHPool ? getTokenUSDTPrice(repayTokenDataBorrowTokenIsETH ? repayTokenDataNewAmount : checkData0(repayTokenData, 'price')) : ''}
                                    </div>
                                    <div
                                        className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                        {(() => {
                                            const maxBalance = lessThanOrEqualTo(repayTokenDataBalance, repayTokenDataBorrowAmount) ? repayTokenDataBalance : repayTokenDataBorrowAmount
                                            return [<div
                                                className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                                {maxBalance} {repayTokenDataBorrowSymbol}
                                            </div>, <div style={{fontSize: `${isPhoneClientWidth ? .6 : .15}rem`}}
                                                         onClick={() => setRepayTokenNewAmount(maxBalance)}
                                                         className={`backgroundActiveColor ${isPhoneClientWidth ? 'paddingSmallPhone' : 'paddingSmall'} borderRadius999 colorWhite cursorPointer`}>MAX
                                            </div>]
                                        })()}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallerFontSize'}>
                                Transaction overview
                            </div>
                            <div
                                className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div
                                        className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>Remaining
                                    </div>
                                    {rightArrowText(...[repayTokenDataBorrowAmount, afterRepayTokenDataBorrowAmount].map(v => `${v} ${checkDataString(repayTokenDataBorrowSymbol)}`))}
                                </div>
                                {repayTokenIsETHPool && <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div
                                        className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>Debt
                                    </div>
                                    <div
                                        className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                        {repayTokenDataBorrowTokenIsETH ? getTokenUSDTPrice(repayTokenDataBorrowAmount) : `$${checkData0(repayTokenData, 'borrowPrice')}`} → {repayTokenDataBorrowTokenIsETH ? getTokenUSDTPrice(minus(afterRepayTokenDataBorrowAmount)) : `$${checkData0(repayTokenData, 'afterBorrowPrice')}`}
                                    </div>
                                </div>}
                            </div>
                            <div
                                className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div
                                        className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                        Liq.Price
                                    </div>
                                    <div
                                        className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>{checkDataString(repayTokenData, 'liqToken')}</div>
                                </div>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div
                                        className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}></div>
                                    <div
                                        className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                        {repayTokenIsETHPool ? `${checkDataString(repayTokenData, 'liqPrice')}` : ''}
                                    </div>
                                </div>
                            </div>
                            <img src={requireDefault('Line 4')} alt="" className={'width100'}/>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div
                                    className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>Margin
                                </div>
                                <div
                                    className={`colorGreen saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                    {num8(
                                        times(
                                            divide(repayTokenDataNewAmount, repayTokenDataBorrowAmount),
                                            plus(checkData0(repayTokenData, 'marginAmount'), checkData0(repayTokenData, 'marginTotal'))
                                        )
                                    )} {repayTokenDataMarginSymbol}
                                </div>
                            </div>
                            {repayAdjust}
                        </div>
                    })()),
                    maskContent('Close Position', showClosePosition, toggleShowClosePosition, (() => {
                        const closePNL = checkData0(data, 'closePNL'), closePNLLessThan = lessThan(closePNL, 0)
                        return <div
                            className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}>
                            <div
                                className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                {closePositionData(true, repayTokenData)}
                            </div>
                            <div
                                className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone paddingBigPhone borderRadiusPhone borderSmallGrayLinePhone' : 'childrenSpacingTopBig paddingBig borderRadius borderSmallGrayLine'}`}>
                                <div
                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} justifyContentSpaceBetween`}>
                                    <input type="text"
                                           placeholder='0'
                                           value={checkData([data, 'repayTokenDataNewAmount'])}
                                           onChange={e => setRepayTokenNewAmount(e.target.value)}
                                           className={`${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'} flexGrow1`}/>
                                    <div
                                        className={`borderRadius999 saf flexCenter ${isPhoneClientWidth ? 'borderSmallGrayLinePhone childrenSpacingLeftPhone' : 'borderSmallGrayLine childrenSpacingLeft'}`}
                                        style={{
                                            height: `${isPhoneClientWidth ? 2.5 : .8}rem`,
                                            padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                        }}>
                                        {tokenIcon({
                                            tokenAddress: repayTokenDataBorrowToken,
                                            symbol: repayTokenDataBorrowSymbol,
                                            avatar: isAddress0(repayTokenDataBorrowToken) ? ethAvatar : repayTokenData.borrowAvatar
                                        }, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                        <div>{repayTokenDataBorrowSymbol}</div>
                                    </div>
                                </div>
                                {/*<Slider min={0} max={100} step={25} value={range}*/}
                                {/*        dots={true}*/}
                                {/*        onChange={range => setData({range})}*/}
                                {/*/>*/}
                                {rangeContent(range => setRepayTokenNewAmount(undefined, times(repayTokenDataBorrowAmountWei, divide(range, 100)), false))}
                            </div>
                            <div
                                className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                {[['Estimated PNL', <div
                                    className={`saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} 
                                    ${closePNLLessThan ? 'colorRed' : 'colorGreen'}`}>{numberETH(closePNL, repayTokenDataMarginDecimals)} {checkDataString(repayTokenData.marginSymbol)}</div>], ['Max Slippage',
                                    <div
                                        className={`flexCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                        <div
                                            className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>Auto
                                        </div>
                                        <div
                                            className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{autoMaxSlippage}%
                                        </div>
                                    </div>],].map(v => <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div
                                        className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[0]}</div>
                                    {v[1]}
                                </div>)}
                            </div>
                            <div style={isPhoneClientWidth ? {marginBottom: '2rem'} : {}}
                                 onClick={() => marginPositionManagerWriteContract(
                                     'close',
                                     undefined,
                                     [
                                         repayTokenDataPositionId, BigInt(toInt(times(range, 10000))),
                                         closePNLLessThan ? BigInt(toInt(times(closePNL, divide(plus(100, autoMaxSlippage), 100)))) : maxSlippageAmount(closePNL, autoMaxSlippage),
                                         defaultDeadline
                                     ],
                                     hash => {
                                         searchPositionPool(0)
                                         toggleShowLoading()
                                         toggleShowRepaying()
                                         toastSuccessful(hash)
                                     })}
                                 className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingTopBottomPhone' : 'borderRadius paddingTopBottomBig'} flexCenter colorWhite saf1 backgroundActiveColor cursorPointer`}>
                                Close
                            </div>
                        </div>
                    })()), liquidityContent]
            })(),
            searchName = checkData([data, 'searchName']),
            searchNameToLowerCase = checkDataString(searchName).toLowerCase(),
            allTokensIncludesSearchName = allTokens.map(v => checkDataString(v, 'tokenAddress').toLowerCase()).includes(searchNameToLowerCase),
            searchNameIsNotNull = isNotNullOrEmpty(searchName),
            setPage = (page = defaultPage) => setData({page}),
            isETHAddress = (tokenAddress = '') => utils.isAddress(tokenAddress.toLowerCase()) && tokenAddress.includes('0x'),
            searchNameIsETHAddress = isETHAddress(searchName),
            erc20ContractData = async (tokenAddress, noLoading) => {
                !noLoading && toggleShowLoading(true)
                // 进一步验证是否符合 ERC-20 标准 (可选)
                try {
                    const obj = {tokenAddress}
                    for (const method of ['name', 'symbol', 'decimals', 'totalSupply', 'balanceOf']) {
                        const value = await (new slaveContractETH.Contract(erc20Abi, tokenAddress)).methods[method](...method === 'balanceOf' ? [address0] : []).call();
                        if (['name', 'symbol', 'decimals'].includes(method)) {
                            obj[method] = checkDataString(value).toString()
                        }
                    }
                    !noLoading && toggleShowLoading()
                    return obj; // 符合 ERC-20 接口
                } catch (error) {
                    !noLoading && toggleShowLoading()
                    return null; // 不符合 ERC-20 标准
                }
            },
            toggleShowTokenDetail = (showTokenDetail, v) => setData({showTokenDetail, tokenDetail: v}),
            {watchAssetAsync} = useWatchAsset(),
            watchAsset = (arr, symbol, decimals) => switchEthereumChain(
                async () => {
                    toggleShowLoading(true)
                    watchAssetAsync({
                        type: 'ERC20',
                        options: {
                            address: arr,
                            ...isNotNull(symbol) && isNotNull(decimals)
                                ? {
                                    symbol,
                                    decimals
                                }
                                : checkDataObject(await erc20ContractData(arr, true)),
                            image: getTokenIcon(arr)
                        }
                    }).then(() => {
                        toggleShowLoading()
                        toastSuccessful(true)
                    }).catch(e => {
                        toggleShowLoading()
                        toast(checkDataString(e, 'details'))
                    })
                },
                () => {
                    toggleShowSelectToken()
                    toggleShowTokenDetail()
                },
                true
            ),
            urlPoolId = new URLSearchParams(location.search).get('poolid'),
            getTokensPools = (iST = isSelectToken) => {
                const iFP = isFirstPage();
                (
                    iFP && urlPoolId
                        ? requestApi(`margin/pool/${chainId}/${urlPoolId}`, undefined, undefined, undefined, true)
                        : new Promise(resolve => resolve())
                ).then(async urlPool => {
                    if (iST && searchNameIsNotNull && await searchNameIsETHAddress) {
                        erc20ContractData(searchName).then(v => {
                            const vIsNotNull = isNotNull(v)
                            setData({tokens: vIsNotNull ? [v] : [], tokensTotal: vIsNotNull ? 1 : 0})
                        })
                    } else {
                        const obj = {chainId, ...searchNameIsNotNull ? {[iST ? 'symbol' : 'name']: searchName} : {}}
                        requestApi(
                            ...iST
                                ? [`token/trending/${chainId}`, undefined]
                                : [
                                    'margin/pool',
                                    {page, pageSize, ...obj}
                                ], undefined, undefined, true).then(async v => {
                            const arr = checkDataArray(...iST ? [v] : [v, 'items'])
                            setData({
                                [iST ? 'tokens' : 'pools']:
                                    [
                                        ...iFP ? [] : pools,
                                        ...iST
                                            ? arr.map(v => ({
                                                ...v,
                                                avatar: v.logo,
                                                tokenAddress: v.address,
                                            }))
                                            : await (async () => {
                                                const arr1 = []
                                                await new Promise(async resolve => {
                                                    if (isNotNullOrEmpty(arr)) {
                                                        for (const i in arr) {
                                                            const v = arr[i]
                                                            arr1.push({
                                                                ...v,
                                                                currency0Decimals: await erc20CallDecimals(v.currency0),
                                                            });
                                                            i === minus(arr.length, 1) && resolve(arr1)
                                                        }
                                                    } else {
                                                        resolve(arr1)
                                                    }
                                                })
                                                return arr1
                                            })()
                                    ],
                                ...isNotNull(urlPool) ? {urlPools: [urlPool], urlPoolGetEnd: true} : {},
                                [iST ? 'tokensTotal' : 'poolsTotal']: iST ? arr.length : checkData0(v, 'total')
                            })
                            toggleShowLoading()
                        })
                    }
                })
            },
            searchToken = iST => isFirstPage() ? getTokensPools(iST) : setPage(),
            tokensPoolsScroll = React.createRef(),
            tokensPoolsOnScroll = () => onScrollFunc(tokensPoolsScroll, isSelectToken ? tokens : pools, checkData0(data, isSelectToken ? 'tokensTotal' : 'poolsTotal'), () => setPage(page + 1)),
            tokenABPool = (tokenA, tokenB, setShowLoading) => {
                setShowLoading && toggleShowLoading(true)
                return requestApi('margin/token/pool', {
                    chainId, tokenA, tokenB
                }).then(v => {
                    setShowLoading && toggleShowLoading()
                    return checkData([v, 0])
                })
            },
            searchAddressTokening = checkDataBool(data, 'searchAddressTokening'),
            selectTokenContent = (() => {
                const selectTokenOrPool = async v => {
                        const tokenAddress = checkDataString(v, 'tokenAddress'),
                            otherSelectTokenIndex = selectTokenIndex ? 0 : 1
                        let pD = {}
                        if (isSelectTokenNotNeedToken) {
                            const params = [tokenAddress, checkDataString(tokenDatas, otherSelectTokenIndex, 'tokenAddress')]
                            pD = checkDataObject(await tokenABPool(...selectTokenIndex ? params.reverse() : params, true))
                            Object.keys(pD).length === 0 && toast('Pool does not exist')
                        }
                        if (isSelectToken) {
                            setRecentSearchTokens(v)
                            if (!isPoolIncrease) {
                                localStorage.setItem(pageTokenKey(selectTokenIndex), JSON.stringify(v))
                            }
                        }
                        setData({
                            [isSelectToken ? `token${selectTokenIndex}Data` : 'poolData']: {
                                ...v,
                                ...isSelectToken
                                    ? {
                                        newAmount: undefined,
                                        newAmountWei: undefined,
                                        ...(address && tokenAddress.toLocaleLowerCase() === checkDataString(tokenDatas, selectTokenIndex, 'tokenAddress').toLocaleLowerCase())
                                            ? await erc20CallBalance(tokenAddress).then(([balance, decimals]) => ({
                                                decimals, balance: numberETH(balance, decimals)
                                            }))
                                            : {}
                                    }
                                    : {}
                            },
                            ...isSelectTokenNotNeedToken
                                ? {poolData: pD}
                                : {},
                            // ...(isSelectToken && tokenAddress.toLocaleLowerCase() === checkDataString(tokenDatas, otherSelectTokenIndex, 'tokenAddress').toLocaleLowerCase())
                            //     ? {
                            //         [`token${otherSelectTokenIndex}Data`]: checkDataObject(tokenDatas, selectTokenIndex)
                            //     }
                            //     : {},
                            ...isSwap
                                ? {
                                    newAmount: undefined,
                                    newAmountWei: undefined,
                                    transitionPrice: undefined
                                }
                                : {}
                        })
                        if (!isSelectToken) {
                            setTokenData(token0Data, {
                                newAmount: undefined,
                                newAmountWei: undefined
                            })
                            setTokenData(token1Data, {
                                newAmount: undefined,
                                newAmountWei: undefined
                            })
                        }
                        toggleShowSelectToken()
                    },
                    toggleShowImportToken = (showImportToken, v) => setData({showImportToken, tokenDetail: v}),
                    tokenDetail = checkDataObject(data, 'tokenDetail')
                return [
                    maskContent(
                        `Select a ${isSelectToken ? 'Token' : 'Pool'}`,
                        showSelectToken,
                        toggleShowSelectToken,
                        [
                            <div
                                className={`borderRadius999 ${isPhoneClientWidth ? 'paddingPhone childrenSpacingLeftPhone' : 'padding childrenSpacingLeft'} flex alignItemsCenter backgroundGrey`}>
                                <img src={requireDefault('search')}
                                     alt=""
                                    // className={'cursorPointer'}
                                    // onClick={() => searchToken()}
                                     style={{width: `${isPhoneClientWidth ? 1.5 : .3}rem`}}/>
                                <input placeholder={`Search ${isSelectToken ? 'by name or address' : 'Pool'}`}
                                       type="text"
                                       value={searchName}
                                       className={'flexGrow1'}
                                    // onKeyUp={e => searchSymbolOnKeyUp(e, searchToken)}
                                       onChange={e => setData({searchName: e.target.value})}
                                />
                            </div>,
                            <div ref={tokensPoolsScroll}
                                 onScroll={() => !isSelectToken && tokensPoolsOnScroll()}
                                 className={`overflowYScroll ${isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}`}
                                 style={{[(isSelectToken ? 'height' : 'maxHeight')]: isPhoneClientWidth ? '60vh' : '10rem'}}>
                                {
                                    isSelectToken && searchAddressTokening
                                        ? <div className={'flexCenter height100'}>
                                            <img src={requireDefault('loading')} alt=""
                                                 style={{width: `${isPhoneClientWidth ? 9 : 3}rem`}}/>
                                        </div>
                                        : [
                                            isSelectToken && !searchNameIsNotNull && recommendTokens.length > 0 &&
                                            <div
                                                className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} overflowXScroll`}>
                                                {
                                                    recommendTokens.map(v =>
                                                        <div style={{background: '#F7F7F7'}}
                                                             onClick={() => selectTokenOrPool(v)}
                                                             className={`flexColumnCenter ${isPhoneClientWidth
                                                                 ? 'borderRadiusPhone paddingPhone childrenSpacingTopSmallPhone'
                                                                 : 'borderRadius padding childrenSpacingTopSmall'} flexShrink0 cursorPointer`}>
                                                            {tokenIcon(v, `${isPhoneClientWidth ? 2.4 : .8}rem`)}
                                                            <div
                                                                className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>{checkDataString(v, 'symbol')}</div>
                                                        </div>
                                                    )
                                                }
                                            </div>,
                                            (
                                                isSelectToken
                                                    ? (
                                                        searchNameIsNotNull
                                                            ? [
                                                                [
                                                                    '',
                                                                    [
                                                                        ...allTokens.filter(item =>
                                                                            checkDataString(
                                                                                item,
                                                                                searchNameIsETHAddress ? 'tokenAddress' : 'symbol'
                                                                            ).toLowerCase().includes(searchNameToLowerCase)
                                                                        ),
                                                                        ...searchAddressTokens
                                                                    ].filter((item, index, self) => {
                                                                            const tokenAddress = checkDataString(item, 'tokenAddress').toLowerCase()
                                                                            return !isAddress0(tokenAddress) &&
                                                                                self.findIndex(obj => checkDataString(obj, 'tokenAddress').toLowerCase() === tokenAddress) === index
                                                                        }
                                                                    )
                                                                ]
                                                            ]
                                                            : [
                                                                [
                                                                    'Your Tokens',
                                                                    [
                                                                        ...greaterThan(ethBalance)
                                                                            ? [ethToken]
                                                                            : [],
                                                                        ...yourTokens
                                                                    ]
                                                                ],
                                                                //.filter(v => v.tokenAddress != checkData([[token1Data, token0Data], selectTokenIndex, 'tokenAddress']))
                                                                ['Recent Searches', recentSearchTokens],
                                                                ['Trending Tokens', tokens]
                                                            ]
                                                    )
                                                    : [['', pools]]
                                            ).map(v => v[1].length > 0 && <div
                                                className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                                {v[0] && <div className={'colorGray'}>{v[0]}</div>}
                                                {
                                                    v[1].map(v => {
                                                        const isImport = searchNameIsNotNull && !(allTokensIncludesSearchName ||
                                                                allTokens.map(v => checkDataString(v, 'tokenAddress').toLowerCase()).includes(checkDataString(v, 'tokenAddress').toLowerCase())),
                                                            tokenAddress = v.tokenAddress,
                                                            tokenAddressIsAddress0 = isAddress0(tokenAddress)
                                                        return <div
                                                            onClick={() => !isImport && selectTokenOrPool(v)}
                                                            className={'flexCenter justifyContentSpaceBetween cursorPointer'}>
                                                            <div
                                                                className={`flexCenter flexGrow1 ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                                {tokenIcon(v, `${isPhoneClientWidth ? 2.4 : .8}rem`)}
                                                                <div
                                                                    className={`${isPhoneClientWidth ? 'childrenSpacingTopSmallPhone' : 'childrenSpacingTopSmall'} flexGrow1`}>
                                                                    <div>{
                                                                        checkDataString(v, isSelectToken ? 'symbol' : 'name')
                                                                    }</div>
                                                                    {isSelectToken && <div
                                                                        className={`colorGray textOverflowEllipsis ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{
                                                                        checkDataString(v, 'name')
                                                                    }</div>}
                                                                </div>
                                                            </div>
                                                            {/*{!isSelectToken && <div*/}
                                                            {/*    className={'activeColor'}>{apr(v)}%</div>}*/}
                                                            {isSelectToken
                                                                ? (isImport
                                                                        ? <div onClick={
                                                                            event => {
                                                                                event.stopPropagation()
                                                                                toggleShowImportToken(true, v)
                                                                            }
                                                                        }
                                                                               style={{padding: `${isPhoneClientWidth ? .3 : .1}rem ${isPhoneClientWidth ? .9 : .3}rem`}}
                                                                               className={`borderRadius999 backgroundActiveColor colorWhite ${
                                                                                   isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'
                                                                               } cursorPointer`}>
                                                                            Import
                                                                        </div>
                                                                        : <div
                                                                            className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                                            <div
                                                                                className={isPhoneClientWidth ? 'childrenSpacingTopSmallPhone' : 'childrenSpacingTopSmall'}>
                                                                                <div
                                                                                    className={'textAlignRight'}>{getTokenBalance(tokenAddress)}</div>
                                                                                <div style={{opacity: 0}}
                                                                                     className={`colorGray textAlignRight ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                                                                    $3465.78
                                                                                </div>
                                                                            </div>
                                                                            <img className={'cursorPointer'}
                                                                                 src={requireDefault('More info')}
                                                                                 alt=""
                                                                                 onClick={event => {
                                                                                     if (!tokenAddressIsAddress0) {
                                                                                         event.stopPropagation()
                                                                                         toggleShowTokenDetail(true, v)
                                                                                     }
                                                                                 }}
                                                                                 style={{width: `${isPhoneClientWidth ? 1.2 : .4}rem`, ...tokenAddressIsAddress0 ? {opacity: 0} : {}}}/>
                                                                        </div>
                                                                )
                                                                : <div>{ovl(v, 'tvl')}</div>}
                                                        </div>
                                                    })
                                                }
                                            </div>)
                                        ]
                                }
                            </div>
                        ],
                        9
                    ),
                    ...isSelectToken
                        ? [
                            maskContent(
                                checkDataString(tokenDetail, 'symbol'),
                                checkDataBool(data, 'showTokenDetail'),
                                toggleShowTokenDetail,
                                (() => {
                                    const tokenAddress = checkDataString(tokenDetail, 'tokenAddress')
                                    return [
                                        <div
                                            className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                            <div className={'colorGray'}>Contract Address</div>
                                            <div
                                                className={`borderRadius999 backgroundGrey flex alignItemsCenter ${isPhoneClientWidth ? 'paddingPhone childrenSpacingLeftPhone' : 'padding childrenSpacingLeft'}`}>
                                                <div
                                                    className={'flexGrow1'}>{leaveOut(tokenAddress, 9, 9)}</div>
                                                <img onClick={() => copyText(tokenAddress)}
                                                     src={requireDefault('copy1')} className={'cursorPointer'} alt=""
                                                     style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}/>
                                            </div>
                                        </div>,
                                        <div style={isPhoneClientWidth ? {marginBottom: '8rem'} : {}}
                                             className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} childrenFlexGrow1`}>
                                            {
                                                [
                                                    ['Group 427320833', 'Open on Explorer', () => windowOpen(`${blockExplorers}/address/${tokenAddress}`)],
                                                    ['Group 427320834', 'Add to your wallet', () => watchAsset(tokenAddress, tokenDetail.symbol, tokenDetail.decimals)]
                                                ].map(v => <div onClick={v[2]}
                                                                className={`${isPhoneClientWidth ? 'borderSmallGrayLinePhone borderRadiusPhone paddingPhone childrenSpacingTopPhone' : 'borderSmallGrayLine borderRadius padding childrenSpacingTop'} flexColumnCenter cursorPointer`}>
                                                    <img src={requireDefault(v[0])} alt=""
                                                         style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                                    <div
                                                        className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>{v[1]}</div>
                                                </div>)
                                            }
                                        </div>
                                    ]
                                })(),
                                undefined,
                                true
                            ),
                            maskContent(
                                'Import token',
                                checkDataBool(data, 'showImportToken'),
                                toggleShowImportToken,
                                [
                                    <div
                                        className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                        Anyone can create a token, including creating fake versions of existing tokens
                                        that claim to represent projects. If you purchase this token, you may not be
                                        able to sell it back.
                                    </div>,
                                    <div
                                        className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}>
                                        <div
                                            className={isPhoneClientWidth ? 'borderRadiusPhone paddingBigPhone childrenSpacingTopPhone borderSmallGrayLinePhone' : 'borderRadius paddingBig childrenSpacingTop borderSmallGrayLine'}>
                                            {
                                                [
                                                    ['Name', checkDataString(tokenDetail, 'name')],
                                                    ['Symbol', checkDataString(tokenDetail, 'symbol')],
                                                    ['Address', leaveOut(checkDataString(tokenDetail, 'tokenAddress')), 'activeColor']
                                                ].map(v => <div
                                                    className={'flex alignItemsCenter justifyContentSpaceBetween'}>
                                                    <div
                                                        className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[0]}</div>
                                                    <div
                                                        className={`${checkDataString(v[2])} ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[1]}</div>
                                                </div>)
                                            }
                                        </div>
                                        <div style={isPhoneClientWidth ? {marginTop: '8rem'} : {}}
                                             onClick={() => {
                                                 toggleShowImportToken()
                                                 const obj = setRecentSearchTokens(tokenDetail)
                                                 if (obj) {
                                                     toggleShowSelectToken()
                                                     selectTokenOrPool(obj)
                                                 }
                                                 setData({searchName: ''})
                                             }}
                                             className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingTopBottomPhone bigFontSizePhone' : 'borderRadius paddingTopBottomBig bigFontSize'} 
                                             flexCenter colorWhite saf1 backgroundActiveColor  cursorPointer`}>
                                            I Understand
                                        </div>
                                    </div>
                                ]
                            )
                        ]
                        : []
                ]
            })(),
            settingContent = isPool => {
                const showSettings = checkDataBool(data, 'showSettings'),
                    setMaxSlippage = (maxSlippage, isAuto) => setData({maxSlippage, isAuto: !!isAuto}),
                    toggleShowSettings = () => setData({showSettings: !showSettings}),
                    setMaxSlippageInput = e => setMaxSlippage(e.target.value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, ".")
                        .replace(/^\./g, "").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
                        .replace(/^(\-)*(\d+)\.(\d{1}).*$/, '$1$2.$3')),
                    setTransactionDeadline = e => setData({transactionDeadline: e.target.value.replace(/[^\d]/g, '')}),
                    isAuto = checkData([data, 'isAuto'], true)
                return <div className={'positionRelative flexCenter'}>
                    <img onClick={toggleShowSettings} className={'cursorPointer'}
                         src={requireDefault('setting')}
                         alt=""
                         style={{width: `${isPhoneClientWidth ? 2.1 : .7}rem`}}/>
                    {showSettings && <div style={{
                        width: `${isPhoneClientWidth ? 26 : 6}rem`,
                        top: `${isPhoneClientWidth ? 2.5 : .7}rem`, ...isPhoneClientWidth ? {right: '40%'} : {}
                    }}
                                          className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingBigPhone childrenSpacingTopBiggerPhone' : 'borderRadius paddingBig childrenSpacingTopBig'} positionAbsolute  boxShadow  backgroundWhite  boxSizingBorderBox zIndex1`}>
                        <div className={'saf1 flexCenter'}>Swap Settings</div>
                        <div
                            className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                            {[
                                [
                                    'Max Slippage',
                                    <div
                                        className={'flexCenter justifyContentSpaceBetween'}
                                        style={square('100%')}>
                                        <div onClick={() => setMaxSlippage(autoMaxSlippage, true)}
                                             className={`borderRadius999 ${isPhoneClientWidth ? 'smallFontSizePhone paddingSmallPhone' : 'smallFontSize paddingSmall'} flexCenter ${isAuto ? 'activeColor' : 'colorGray'} saf1 cursorPointer`}
                                             style={{background: isAuto ? '#FFF4F5' : '#F9F9F9'}}>Auto
                                        </div>
                                        <div className={'flexCenter childrenSpacingLeft'}>
                                            <input type="text"
                                                   className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} 
                                                       ${isAuto ? 'colorGray' : ''} textAlignRight`}
                                                   value={maxSlippage}
                                                   onChange={setMaxSlippageInput}
                                                   style={{width: isPhoneClientWidth ? '3rem' : '.7rem'}}
                                            />
                                            <div
                                                className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>%
                                            </div>
                                        </div>
                                    </div>
                                ],
                                [
                                    'Transaction Deadline',
                                    <div
                                        className={`width100 flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                        <input type="text"
                                               className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} saf1`}
                                               value={transactionDeadline}
                                               onChange={setTransactionDeadline}
                                               style={{width: `${isPhoneClientWidth ? 3 : .3}rem`}}
                                        />
                                        <div
                                            className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>Minutes
                                        </div>
                                    </div>
                                ]
                            ].map(v => <div
                                className={'flexCenter justifyContentSpaceBetween'}>
                                <div
                                    className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>{v[0]}</div>
                                <div
                                    className={`${isPhoneClientWidth ? 'paddingSmallPhone borderSmallGrayLinePhone' : 'paddingSmall borderSmallGrayLine'} borderRadius999 flexCenter boxSizingBorderBox`}
                                    style={{
                                        width: '40%', height: `${isPhoneClientWidth ? 2.5 : .7}rem`
                                    }}
                                >
                                    {v[1]}
                                </div>
                            </div>)}
                            <div onClick={toggleShowSettings}
                                 className={`saf1 flexCenter ${isPhoneClientWidth ? 'paddingTopBottomPhone borderRadiusPhone' : 'paddingTopBottom borderRadius'}  activeColor cursorPointer`}
                                 style={{background: '#FFF4F5'}}>
                                Close
                            </div>
                        </div>
                    </div>}
                </div>
            },
            getTask = () => requestApi('point/task', {address}),
            defaultAuthorizePoints = 110,
            twitterAuthorizePoints = defaultAuthorizePoints,
            twitterAuthorizeData = {
                authorizeKey: 'x',
                authorizePoints: twitterAuthorizePoints,
                title: 'Follow & Share',
                needBind: true,
            },
            authorizeData = checkData([data, 'authorizeData'], twitterAuthorizeData),
            {authorizeKey} = authorizeData,
            cloudflareHumanDiv = <div
                className={'childrenDisplayBlock flexCenter'}
                style={{
                    transform: `scale(${document.body.clientWidth / (isPhoneClientWidth ? 600 : 1500)})`,
                }}
                id={'cloudflareHuman'}/>,
            bindCheck = (aK = authorizeKey) => requestApi(`${aK}/bind/check`),
            {authorizeKey: twitterAuthorizeAuthorizeKey} = twitterAuthorizeData,
            showPointsChains = checkDataBool(data, 'showPointsChains'),
            pointsChains = [
                [1, 'Ethereum'],
                [130, 'Unichain'],
                // [1101, 'Polygon'],
                [42161, 'Arbitrum'],
                [10, 'OP Mainnet'],
                [8453, 'Base'],
                [56, 'BNB Chain'],
                [81457, 'Blast'],
            ],
            pointsChainIndex = checkData([data, 'pointsChainIndex'], checkData0(localStorage.getItem('pointsChainIndex'))),
            pointsChain = checkData([pointsChains, pointsChainIndex], pointsChains[0]),
            pointsChainId = pointsChain[0],
            lpPoint = () => requestApi('uniswap/lp/point', {chainId: pointsChainId}).then(([v] = []) =>
                setData({
                    v4postions: checkData0(v, 'amount'),
                    lpActivityPoints: checkData([v, 'point']),
                    v4postionsIsBNB: isBNBFunc(checkData([v, 'chainId'])),
                })),
            lpActivityPoints = checkData([data, 'lpActivityPoints']),
            lpActivityPointsIsNotNull = isNotNull(lpActivityPoints),
            twitterCode = checkData([new URLSearchParams(location.search).get('code')]),
            urlState = checkDataString(new URLSearchParams(location.search).get('state')).split('_'),
            isTwitterAuthorizeAuthorize = aK => aK === twitterAuthorizeAuthorizeKey,
            toggleShowAuthorize = (showAuthorize, isCloudflare, aD = authorizeData) => showAuthorize
                ? switchEthereumChain(
                    () => {
                        toggleShowLoading(true)
                        const aK = aD.authorizeKey
                        loginThenFunc(() => bindCheck(aK).then(isBinded => setData({
                            ...ternaryObject(
                                isTwitterAuthorizeAuthorize(aK),
                                {isTwitterBinded: isBinded}
                            ),
                            isCloudflare,
                            showAuthorize,
                            authorizeData: aD,
                            showLoading: false,
                        })), toggleShowLoading)
                    },
                    undefined,
                    true
                )
                : setData({showAuthorize}),
            redirectURI = `https://likwid.fi/${pNs0}`,
            cloudflareHuman = document.getElementById('cloudflareHuman'),
            grant = (() => {
                const url = 'https://x.com/UniswapFND/status/1882152511320277094'
                return <div
                    className={`flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}`}>
                    <img src={requireDefault('Uniswap')} alt=""
                         style={{width: `${isPhoneClientWidth ? 6 : 1.5}rem`}}/>
                    <div className={'saf1'} style={{fontSize: `${isPhoneClientWidth ? 1.1 : .5}rem`}}>Uniswap
                        Foundation
                    </div>
                    <div onClick={() => windowOpen(url)}
                         style={{padding: `${isPhoneClientWidth ? .6 : .2}rem ${isPhoneClientWidth ? .6 : 2.5}rem`}}
                         className={`backgroundGrey ${isPhoneClientWidth ? 'smallFontSizePhone borderSmallGrayLinePhone' : 'borderSmallGrayLine'}  borderRadius999 textAlignCenter cursorPointer activeColor`}>
                        {url}
                    </div>
                </div>
            })(),
            paths = {
                '/': <div>
                    {isPhoneClientWidth ? pageTopFootPhone(<div>
                            <div style={{
                                paddingTop: '9rem',
                                height: '59.92rem',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundImage: `url(${requireDefault('homeBGPhone1')})`
                            }} className={'boxSizingBorderBox'}>
                                <div className={'flexColumnCenter positionRelative zIndex1'}>
                                    <div className={'saf1'} style={{fontSize: '1.8rem'}}>Leverage your</div>
                                    <br/>
                                    <div>
                                        <span className={'saf1'} style={{fontSize: '1.8rem'}}>profits for</span>
                                        <span className={'saf1'}
                                              style={{fontSize: '1.8rem', marginLeft: '10rem'}}>any token</span>
                                    </div>
                                    <img className={'positionAbsolute marginTop0 zIndex-1'}
                                         style={{width: '15rem', top: '2rem'}}
                                         src={requireDefault('meme3-3')}
                                         alt=""/>
                                    <pre className={'textAlignCenter'}
                                         style={{fontSize: '.8rem', marginTop: '11rem'}}>{`The first fully permissionless, oracle-less, margin trading 
protocol based on uniswap V4.`}</pre>
                                    <div style={{marginTop: '2rem', transform: 'scale(2)'}}
                                         className={'flexCenter childrenSpacingLeftBigPhone'}>
                                        {docs}
                                        {addressData()}
                                    </div>
                                </div>
                                <div style={{marginTop: '7rem', padding: '0 1.5rem'}}
                                     className={'flex justifyContentSpaceBetween flexWrap'}>
                                    {[...Oracles, ...Oracles1].map((v, i) => <div
                                        className={'paddingBigPhone boxSizingBorderBox positionRelative zIndex1'}
                                        style={{
                                            width: '13rem',
                                            height: `${[2, 3].includes(i) ? 13.6 : 10.6}rem`,
                                            marginTop: '.5rem',
                                            borderRadius: '1.5rem',
                                            background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                        }}>
                                        <div className={'activeColor saf1 smallerFontSizePhone'}
                                             style={{opacity: .5}}>0{i + 1}</div>
                                        <div className={'childrenSpacingTopPhone'}
                                             style={{marginTop: '1.2rem'}}>
                                            <pre className={'saf1'} style={{fontSize: '1rem'}}>{v[1]}</pre>
                                            <pre style={{fontSize: '.67rem'}}>{v[2]}</pre>
                                        </div>
                                        <img className={'positionAbsolute zIndex-1'}
                                             style={{width: '40%', top: '.1rem', right: '.1rem'}}
                                             src={requireDefault(v[0])} alt=""/>
                                    </div>)}
                                </div>
                            </div>
                            <div style={{padding: '0 1.5rem'}}>
                                <div style={{marginTop: '20rem'}} className={'childrenSpacingTopBiggerPhone'}>
                                                <pre className={'saf1 textAlignCenter'} style={{fontSize: '1.99rem'}}>{`Likwid 
constant product formula`}</pre>
                                    <div className={'childrenSpacingTopBigPhone'}>
                                        {likwidFormula.map((v, i) => {
                                            const is1 = i === 1
                                            return <div
                                                className={`paddingPhone ${is1 ? '' : 'borderSmallGrayLine'} childrenSpacingTopPhone`}
                                                style={{
                                                    borderColor: '#EFEFEF', borderRadius: '.7rem', ...is1 ? {
                                                        background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                    } : {}
                                                }}>
                                                <div
                                                    className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div className={'saf1'}
                                                         style={{fontSize: '1.1rem'}}>{v[0]}</div>
                                                    <div className={is1 ? 'saf1' : ''}
                                                         style={{fontSize: '1.1rem'}}>{v[1]}</div>
                                                </div>
                                                <div style={{height: '1.4rem', borderRadius: '.15rem'}}
                                                     className={'flex overflowHidden'}>
                                                    <div
                                                        className={'height100 smallerFontSizePhone flexCenter colorWhite'}
                                                        style={{
                                                            width: '34.5%', background: '#627EEA'
                                                        }}>x=20
                                                    </div>
                                                    <div
                                                        className={'height100 smallerFontSizePhone flexCenter flexGrow1'}
                                                        style={{background: '#E8E8E8'}}>y={v[2]}
                                                    </div>
                                                    {v[3] && <div
                                                        className={'height100 smallerFontSizePhone flexCenter colorWhite backgroundActiveColor'}
                                                        style={{width: '15%'}}>{v[3]}
                                                    </div>}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div className={'childrenSpacingTopBigPhone'}>
                                        <div className={'flexCenter'}>
                                            <img src={requireDefault('formula1')} alt=""
                                                 style={{width: '15rem'}}/>
                                        </div>
                                        <pre style={{fontSize: '.85rem'}}>{formula1}</pre>
                                    </div>
                                </div>
                                <div style={{marginTop: '6rem'}}
                                     className={'childrenSpacingTopBiggerPhone'}>
                                                <pre className={'saf1 textAlignCenter'} style={{fontSize: '1.99rem'}}>{`Likwid 
hook on uniswap v4`}</pre>
                                    <div className={'childrenSpacingTop'}>
                                        {lkwidHook.map(v => {
                                            const [title] = v
                                            return <div
                                                className={'paddingBigPhone boxSizingBorderBox flex alignItemsCenter childrenSpacingLeftBigPhone'}
                                                style={{
                                                    height: '6.4rem',
                                                    borderRadius: '1.5rem',
                                                    background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                }}
                                            >
                                                <img src={requireDefault(title)} alt=""
                                                     style={{width: '4rem'}}/>
                                                <img src={requireDefault('Line 34')} alt=""
                                                     className={'height100'}/>
                                                <div className={'childrenSpacingTopBig'}>
                                                    <div className={'saf1'}
                                                         style={{fontSize: '1.2rem'}}>{title}</div>
                                                    <div className={'smallFontSizePhone'}>{v[1]}</div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div className={'flexCenter'}>
                                        <img src={requireDefault('Likwid hook on uniswap v4')} alt=""
                                             style={{width: '80%'}}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                height: '52.68rem',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                padding: '6rem 2.5rem 0 2.5rem',
                                backgroundPosition: 'center center',
                                backgroundImage: `url(${requireDefault('RoadmapPhone')})`
                            }} className={'boxSizingBorderBox'}>
                                <div className={'saf1 textAlignCenter'} style={{fontSize: '1.99rem'}}>
                                    Roadmap
                                </div>
                                <div style={{marginTop: '5rem'}}>
                                    {Roadmaps.map((v, i) => <div className={'flex justifyContentSpaceBetween'}
                                                                 style={{
                                                                     marginTop: i === 0 ? 0 : '3.1rem', ...i % 2 === 0 ? {} : {flexDirection: 'row-reverse'}, ...i > 1 ? {transform: `translate(${i === Roadmaps.length - 1 ? '-1rem' : '0'},-1.1rem)`} : {}
                                                                 }}
                                    >
                                        <div className={'childrenSpacingTopPhone'}>
                                            <div className={'saf1'} style={{fontSize: '1.4rem'}}>
                                                {v[0]}
                                            </div>
                                            <pre style={{fontSize: '.95rem'}}>{v[1]}</pre>
                                        </div>
                                        <div/>
                                    </div>)}
                                </div>
                            </div>
                            <div style={{marginTop: '3rem', marginBottom: '5rem'}}
                                 className={'childrenSpacingTopBiggerPhone'}>
                                <pre className={'saf1 textAlignCenter'} style={{fontSize: '1.99rem'}}>Grant</pre>
                                {grant}
                            </div>
                        </div>)
                        : <div>
                            {pageTop}
                            <div style={{
                                height: '21.58rem',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundImage: `url(${requireDefault('homeBG1')})`
                            }}>
                                <div className={'flexColumnCenter positionRelative zIndex1'}
                                     style={{paddingTop: '2rem'}}>
                                    <div className={'saf1 fontSizePhone'}>Leverage your</div>
                                    <div>
                                        <span className={'saf1 fontSizePhone'}>profits for</span>
                                        <span className={'saf1 fontSizePhone'}
                                              style={{marginLeft: '4.5rem'}}>any token</span>
                                    </div>
                                    <img className={'positionAbsolute marginTop0 zIndex-1'}
                                         style={{width: '7rem', top: '3rem'}}
                                         src={requireDefault('meme3-3')}
                                         alt=""/>
                                    <pre className={'textAlignCenter bigFontSize'} style={{marginTop: '5rem'}}>{`The first fully permissionless, oracle-less, margin trading protocol
based on uniswap V4.`}</pre>
                                    <div style={{marginTop: '5rem'}} className={'childrenSpacingTop'}>
                                        {[Oracles, Oracles1].map((v, i) => <div
                                            className={'flexCenter childrenSpacingLeftBig'}>
                                            {v.map((v, i1) => <div
                                                className={'borderRadiusPhone paddingBigger boxSizingBorderBox positionRelative zIndex1'}
                                                style={{
                                                    width: '8.2rem',
                                                    height: '4.55rem',
                                                    background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                }}>
                                                <div className={'activeColor saf1'}
                                                     style={{opacity: .5}}>0{i1 + (i * 3) + 1}</div>
                                                <div className={'childrenSpacingTopBig'} style={{marginTop: '.9rem'}}>
                                                    <pre className={'saf1'} style={{fontSize: '.5rem'}}>{v[1]}</pre>
                                                    <pre className={'smallFontSize'}>{v[2]}</pre>
                                                </div>
                                                <img className={'positionAbsolute zIndex-1'}
                                                     style={{width: '33%', top: '.1rem', right: '.1rem'}}
                                                     src={requireDefault(v[0])} alt=""/>
                                            </div>)}
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                paddingTop: '5rem',
                            }} className={'pagePaddingLeftRight childrenSpacingTopPhone'}>
                                <div className={'saf1'} style={{fontSize: '.83rem'}}>
                                    Likwid constant product formula
                                </div>
                                <div className={'flex alignItemsFlexEnd justifyContentSpaceBetween'}>
                                    <div className={'childrenSpacingTopBigger'} style={{width: '14.2rem'}}>
                                        {likwidFormula.map((v, i) => {
                                            const is1 = i === 1
                                            return <div
                                                className={`paddingBigger ${is1 ? '' : 'borderSmallGrayLine'} childrenSpacingTopBig`}
                                                style={{
                                                    borderColor: '#EFEFEF', borderRadius: '.3rem', ...is1 ? {
                                                        background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                    } : {}
                                                }}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div className={'saf1'}>{v[0]}</div>
                                                    <div className={is1 ? 'saf1' : ''}>{v[1]}</div>
                                                </div>
                                                <div style={{height: '.35rem', borderRadius: '.05rem'}}
                                                     className={'flex overflowHidden'}>
                                                    <div className={'height100 smallFontSize flexCenter colorWhite'}
                                                         style={{width: '34.5%', background: '#627EEA'}}>x=20
                                                    </div>
                                                    <div className={'height100 smallFontSize flexCenter flexGrow1'}
                                                         style={{background: '#E8E8E8'}}>y={v[2]}
                                                    </div>
                                                    {v[3] && <div
                                                        className={'height100 smallFontSize flexCenter colorWhite backgroundActiveColor'}
                                                        style={{width: '15%'}}>{v[3]}
                                                    </div>}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div className={'childrenSpacingTopBigPhone flexColumnCenter'}>
                                        <img src={requireDefault('formula1')} alt="" style={{width: '8.5rem'}}/>
                                        <pre className={'smallFontSize'}>{`This simple formula bridges the gap between lending and swapping, 
achieving the unification of the two largest sectors in DeFi.

where:
x,y: Represents the target ERC-20 token quantity.
x',y′: A mapped derivative token created by the protocol.`}</pre>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                paddingTop: '2.5rem', paddingBottom: '2.5rem',
                            }} className={'pagePaddingLeftRight childrenSpacingTopBigger'}>
                                <div className={'saf1'} style={{fontSize: '.83rem'}}> Likwid hook on uniswap v4</div>
                                <div className={'flex justifyContentSpaceBetween alignItemsFlexEnd'}>
                                    <div className={'childrenSpacingTop'}>
                                        {lkwidHook.map(v => {
                                            const [title] = v
                                            return <div
                                                className={'borderRadiusPhone paddingBigger boxSizingBorderBox flex alignItemsCenter childrenSpacingLeftBigger'}
                                                style={{
                                                    width: '11.3rem',
                                                    height: '2.6rem',
                                                    background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                }}
                                            >
                                                <img src={requireDefault(title)} alt="" style={{width: '1.5rem'}}/>
                                                <img src={requireDefault('Line 34')} alt=""
                                                     className={'height100'}/>
                                                <div className={'childrenSpacingTop'}>
                                                    <div className={'saf1'}
                                                         style={{fontSize: '.47rem'}}>{title}</div>
                                                    <div className={'smallFontSize'}>{v[1]}</div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <img src={requireDefault('Likwid hook on uniswap v4')} alt=""
                                         style={{width: '10rem'}}/>
                                </div>
                            </div>
                            <div style={{
                                height: '10.4rem',
                                marginBottom: '2rem',
                                paddingTop: '1rem',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundImage: `url(${requireDefault('Roadmap')})`
                            }} className={'boxSizingBorderBox pagePaddingLeftRight'}>
                                <div className={'saf1'} style={{fontSize: '.83rem'}}>Roadmap</div>
                                <div style={{padding: '0 0 0 2.3rem', marginTop: '4.7rem'}}
                                     className={'flex childrenFlexGrow1'}>
                                    {Roadmaps.map(v => <div className={'childrenSpacingTopSmall'}>
                                        <div className={'saf1'} style={{fontSize: '.5rem'}}>{v[0]}</div>
                                        <pre className={'smallFontSize'}>{v[1]}</pre>
                                    </div>)}
                                </div>
                            </div>
                            <div style={{
                                paddingTop: '1rem', paddingBottom: '2.5rem',
                            }} className={'pagePaddingLeftRight childrenSpacingTopBigger positionRelative'}>
                                <div className={'saf1'} style={{fontSize: '.83rem'}}>Grant</div>
                                <div style={{marginTop: '2rem'}}>{grant}</div>
                                <img src={requireDefault('Mask group1')} alt="" className={'positionAbsolute'}
                                     style={{left: 0, top: '3rem', width: '5rem'}}
                                />
                            </div>
                            {pageFoot}
                        </div>}
                    {loading}
                </div>,
                ...sessionDev ? {
                    'trade/*': (() => {
                        const marginSwapList = <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div
                                    className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone' : 'childrenSpacingLeftBig'}`}>
                                    {marginSwap.map((v, i) => {
                                        const isCurrent = marginSwapIndex === i
                                        return <div
                                            onClick={() => toggleMarginSwapIndex(i)}
                                            style={{padding: isPhoneClientWidth ? '.6rem 1rem' : '.2rem .5rem'}}
                                            className={`flexCenter borderRadius999 cursorPointer ${isCurrent ? `backgroundGrey saf1 ${isPhoneClientWidth ? 'borderSmallGrayLinePhone' : 'borderSmallGrayLine'}` : ''}`}
                                        >
                                            {v[0]}
                                        </div>
                                    })}
                                </div>
                                {settingContent()}
                            </div>,
                            marginSwapItem = marginSwap[marginSwapIndex][1](),
                            tradeButton = showApprove
                                ? approveContent([{
                                    symbol: marginToken0Symbol, needApprove: marginToken0DataNeedApprove
                                }])
                                : <div style={isPhoneClientWidth ? {marginBottom: '2rem'} : {}}
                                       onClick={() => {
                                           toggleShowApprove(marginToken0DataNeedApprove)
                                           const iA0 = marginToken0IsETH, amount = iA0 ? newAmountWei : undefined,
                                               margin = () => isMargin
                                                   ? (isBorrow ? borrowPositionManagerWriteContract : marginPositionManagerWriteContract)(
                                                       isBorrow ? 'borrow' : 'margin',
                                                       amount,
                                                       [
                                                           [
                                                               poolDataPoolId,
                                                               marginForOne,
                                                               ...isBorrow ? [] : [leverage],
                                                               newAmountWei,
                                                               ...isBorrow ? [] : [0],
                                                               isBorrow ? newBorrowAmountWei : 0,
                                                               maxSlippageAmount(isBorrow ? newBorrowAmountWei : borrowAmount),
                                                               address,
                                                               deadline
                                                           ]
                                                       ],
                                                       hash => {
                                                           waitForTransactionFunc(
                                                               hash,
                                                               () => {
                                                                   erc20CallBalanceOfTokens()
                                                                   setData({marginSuccess: true})
                                                                   setData({
                                                                       newAmount: undefined,
                                                                       newAmountWei: undefined,
                                                                       newBorrowAmount: undefined,
                                                                       newBorrowAmountWei: undefined,
                                                                       inputNewAmount: undefined
                                                                   })
                                                                   // searchPositionPool(0)
                                                                   toggleShowLoading()
                                                                   toggleShowMarginSelling()
                                                                   toastSuccessful(true)
                                                               },
                                                               () => toggleShowMarginSelling()
                                                           )
                                                       }
                                                       // marginPositionManagerCall('getPositionId', poolDataPoolId, marginForOne, address).then(
                                                       //     positionId => {
                                                       //         positionId = Number(positionId != 0 ? positionId : marginPositionManagerInterface.parseLog(logs[logs.length - 1]).args[1])
                                                       //         loginThenFunc(() => requestApi(
                                                       //             'margin/position',
                                                       //             {
                                                       //                 chainId,
                                                       //                 marginForOne,
                                                       //                 owner: address,
                                                       //                 poolId: poolDataPoolId,
                                                       //                 positionId: Number(positionId)
                                                       //             },
                                                       //             'post'
                                                       //         ).then(r => {
                                                       //             searchPositionPool(0)
                                                       //             toastSuccessful(r)
                                                       //             toggleShowLoading()
                                                       //             toggleShowMarginSelling()
                                                       //         }))
                                                       //     }
                                                       // )
                                                   )
                                                   : marginRouterWriteContract(
                                                       'exactInput',
                                                       amount,
                                                       [
                                                           [poolDataPoolId, zeroForOne, address, newAmountWei, maxSlippageAmount(transitionPriceWei), transitionPriceWei, deadline]
                                                       ],
                                                       hash => {
                                                           waitForTransactionFunc(
                                                               hash,
                                                               () => {
                                                                   erc20CallBalanceOfTokens()
                                                                   toastSuccessful(true)
                                                                   toggleShowLoading()
                                                                   toggleShowSwapping()
                                                               },
                                                               () => toggleShowSwapping()
                                                           )
                                                       }),
                                               mT0Allowance = () => iA0
                                                   ? margin()
                                                   : marginToken0Allowance().then(
                                                       mT0A => mT0A > 0
                                                           ? (
                                                               isBorrow
                                                                   ? erc20BorrowPositionApprove
                                                                   : (isMargin ? erc20MarginPositionManagerApprove : erc20MarginRouterApprove)
                                                           )(marginToken0TokenAddress, mT0A, hash => waitForTransactionFunc(hash, mT0Allowance))
                                                           : margin()
                                                   )
                                           mT0Allowance()
                                       }}
                                       className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingTopBottomPhone' : 'borderRadius paddingTopBottomBig'} flexCenter colorWhite saf1 backgroundActiveColor cursorPointer`}>
                                    {needApproveButton(marginToken0DataNeedApprove, isBorrow ? 'Borrow' : (isMargin ? `Margin ${isMarginSell ? 'Sell' : 'Buy'}` : 'Swap'))}
                                </div>
                        return <div>
                            {isPhoneClientWidth
                                ? pageTopFootPhone(
                                    <div className={'positionRelative zIndex1'}>
                                        <div style={{
                                            top: 0,
                                            height: '30.04rem',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center center',
                                            backgroundImage: `url(${requireDefault('tradeBGPhone')})`
                                        }} className={'positionAbsolute width100 zIndex-1'}/>
                                        <div className={'childrenSpacingTopBigPhone paddingBigPhone'}
                                             style={{paddingTop: '4rem', paddingBottom: '3rem'}}
                                        >
                                            {marginSwapList}
                                            {marginSwapItem}
                                        </div>
                                    </div>
                                )
                                : <div>
                                    {pageTop}
                                    <div style={{
                                        minHeight: '18rem',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        backgroundImage: `url(${requireDefault('tradeBG')})`
                                    }}>
                                        <div className={'flexCenter'} style={{paddingTop: '2rem'}}>
                                            <div style={{width: '30%'}} className={'childrenSpacingTopBig'}>
                                                {marginSwapList}
                                                {marginSwapItem}
                                            </div>
                                        </div>
                                    </div>
                                    {pageFoot}
                                </div>}
                            {selectTokenContent}
                            {maskContent(`You’re ${isBorrow ? 'Borrow' : `Margin ${isMarginSell ? 'Selling' : 'Buying'}`}`, showMarginSelling, toggleShowMarginSelling,
                                <div
                                    className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}>
                                    <div/>
                                    {
                                        [
                                            ['Supply', newAmount, marginToken0Data, marginToken0Symbol],
                                            ...isBorrow ? [['Borrow', newBorrowAmount, marginToken1Data, marginToken1Symbol]] : []
                                        ].map(v => <div
                                            className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                            {isBorrow && <div className={'colorGray'}>{v[0]}</div>}
                                            <div
                                                className={`${isPhoneClientWidth ? 'paddingBigPhone borderRadiusPhone borderSmallGrayLinePhone' : 'paddingBig borderRadius borderSmallGrayLine'} flex alignItemsCenter justifyContentSpaceBetween childrenSpacingLeft`}>
                                                <div
                                                    className={isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'}>
                                                    {v[1]}
                                                </div>
                                                <div
                                                    className={`borderRadius999 saf flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}
                                                    style={{
                                                        height: `${isPhoneClientWidth ? 2 : .8}rem`,
                                                        padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                                    }}>
                                                    {tokenIcon(v[2], `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                                    <div>{v[3]}</div>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                    <div
                                        className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                        {marginData(true)}
                                    </div>
                                    {tradeButton}
                                </div>)}
                            {maskContent(
                                'You’re Swapping',
                                showSwapping,
                                toggleShowSwapping,
                                <div
                                    style={{marginTop: `${isPhoneClientWidth ? 3 : .7}rem`}}
                                    className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}>
                                    <div
                                        className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                        {marginTokenDatas.map((v, i) => [<div
                                            className={isPhoneClientWidth ? 'paddingBigPhone borderRadiusPhone borderSmallGrayLinePhone childrenSpacingTopPhone' : 'paddingBig borderRadius borderSmallGrayLine childrenSpacingTop'}>
                                            <div
                                                className={`flexCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                <div
                                                    className={isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'}>{i === 0 ? newAmount : transitionPrice}</div>
                                                <div
                                                    className={`borderRadius999 saf flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone borderSmallGrayLinePhone' : 'childrenSpacingLeft borderSmallGrayLine'}`}
                                                    style={{
                                                        height: `${isPhoneClientWidth ? 2.5 : .8}rem`,
                                                        padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                                    }}>
                                                    {tokenIcon(v, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                                    <div>{v.symbol}</div>
                                                </div>
                                            </div>
                                            <div
                                                className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>{v.symbol}</div>
                                        </div>, ...i === 0 ? [<div className={'flexCenter'}>
                                            <img src={requireDefault('bottomArrow')} alt=""
                                                 style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                        </div>] : [],])}
                                    </div>
                                    {swapFee}
                                    {tradeButton}
                                </div>)}
                            {positionsPoolsContent}
                            {loading}
                        </div>
                    })(),
                    'pool/*': (() => {
                        const poolPositionsLength = poolPositions.length,
                            content = [
                                isHandelLiquidity
                                    ? <div className={isPhoneClientWidth ? '' : 'flexCenter'}
                                           style={{paddingTop: `${isPhoneClientWidth ? 0 : 2}rem`}}>
                                        <div style={isPhoneClientWidth ? {} : {width: '28%'}}
                                             className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}>
                                            <div
                                                className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div onClick={() => windowReplace('/pool')}
                                                         className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'} cursorPointer`}>
                                                        <img src={requireDefault('leftArrow')} alt=""
                                                             style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}/>
                                                        <div
                                                            className={`colorGray ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                                            {liquidityTitle}
                                                        </div>
                                                    </div>
                                                    {settingContent(true)}
                                                </div>
                                                <div
                                                    className={`backgroundWhite ${isPhoneClientWidth ? 'borderSmallGrayLinePhone borderRadiusPhone paddingBigPhone childrenSpacingTopPhone' : 'borderSmallGrayLine borderRadius paddingBig childrenSpacingTop'}`}>
                                                    {selectTokenContainer}
                                                </div>
                                            </div>
                                            <div
                                                className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                                <div
                                                    className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>
                                                    Deposit amounts
                                                </div>
                                                {tokenDatas.map((v, i) => {
                                                    const balance = checkData0(v.balance)
                                                    return <div
                                                        className={`${i === 0 ? 'backgroundGrey' : (isPhoneClientWidth ? 'borderSmallGrayLinePhone' : 'borderSmallGrayLine')} ${isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopBigPhone borderRadiusPhone' : 'paddingBig childrenSpacingTopBig borderRadius'}`}
                                                    >
                                                        <div
                                                            className={`flex alignItemsCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                            <input type="text"
                                                                   placeholder='0'
                                                                   ref={i === 0 ? focusInput : undefined}
                                                                   value={checkDataString(v, 'newAmount')}
                                                                   onChange={e => setTokenNewAmount(v, e.target.value)}
                                                                   className={`${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'} flexGrow1`}/>
                                                            <div
                                                                className={`borderRadius999 saf backgroundWhite flexCenter ${isPhoneClientWidth ? 'borderSmallGrayLinePhone childrenSpacingLeftSmallPhone' : 'borderSmallGrayLine childrenSpacingLeft'}`}
                                                                style={{
                                                                    height: `${isPhoneClientWidth ? 2.4 : .8}rem`,
                                                                    padding: `0 ${isPhoneClientWidth ? .7 : .3}rem`,
                                                                }}>
                                                                {tokenIcon(v, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                                                <div>{v.symbol}</div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={'flexCenter justifyContentSpaceBetween'}>
                                                            <div
                                                                className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>
                                                                {i === 0 && isETHPool ? getTokenUSDTPrice(token0IsETH ? token0NewAmount : token1NewAmount) : ''}
                                                            </div>
                                                            <div
                                                                className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                                <div
                                                                    className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>
                                                                    {balance} {v.symbol}
                                                                </div>
                                                                <div
                                                                    onClick={() => setTokenNewAmount(v, balance)}
                                                                    style={{fontSize: `${isPhoneClientWidth ? .6 : .15}rem`}}
                                                                    className={`backgroundActiveColor ${isPhoneClientWidth ? 'paddingSmallPhone' : 'paddingSmall'} borderRadius999 colorWhite cursorPointer`}>MAX
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                            {(() => {
                                                const eligible = (!isPoolAddIncrease || poolDataPoolIdIsNotNull)
                                                    && Number(checkData0(token0NewAmount)) <= Number(checkData0(token0Data, 'balance'))
                                                    && Number(checkData0(token1NewAmount)) <= Number(checkData0(token1Data, 'balance'))
                                                return <div
                                                    onClick={() => {
                                                        const func = () => eligible && switchEthereumChain(
                                                            () => toggleShowLiquidity(true),
                                                            undefined,
                                                            true
                                                        )
                                                        if (isPoolCreate) {
                                                            tokenABPool(token0Address, token1Address)
                                                                .then(v => isNotNull(v)
                                                                    ? toast('The pool already exists, please use to add liquidity')
                                                                    : func())
                                                        } else {
                                                            func()
                                                        }
                                                    }}
                                                    style={eligible ? {} : {color: '#7D7D7D'}}
                                                    className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingTopBottomPhone' : 'borderRadius paddingTopBottomBig bigFontSize'} 
                                                            flexCenter colorWhite saf1 ${eligible ? 'backgroundActiveColor' : 'backgroundGrey'} cursorPointer`}>
                                                    {liquidityButton}
                                                </div>
                                            })()}
                                        </div>
                                    </div>
                                    : [
                                        <div className={isPhoneClientWidth ? '' : 'flexCenter'}
                                             style={{paddingTop: `${isPhoneClientWidth ? 0 : 2}rem`}}>
                                            <div style={isPhoneClientWidth ? {} : {width: '52%'}}
                                                 className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div className={'saf1 flexGrow1'}>My Liquidity Positions</div>
                                                    <div
                                                        className={`${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} flexCenter`}>
                                                        {[['Create a Pair', 'create'], ['Add liquidity', 'add'],].map(v =>
                                                            <div
                                                                style={isPhoneClientWidth ? {
                                                                    paddingTop: '.5rem', paddingBottom: '.5rem',
                                                                } : {}}
                                                                onClick={() => windowReplace(`/pool/${v[1]}`)}
                                                                className={`flexCenter ${isPhoneClientWidth ? 'paddingPhone childrenSpacingLeftSmallPhone' : 'padding childrenSpacingLeftSmall'} backgroundActiveColor borderRadius999 cursorPointer`}>
                                                                <img src={requireDefault('add')} alt=""
                                                                     style={{width: `${isPhoneClientWidth ? .75 : .25}rem`}}/>
                                                                <div
                                                                    className={`colorWhite ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[0]}</div>
                                                            </div>)}
                                                    </div>
                                                </div>
                                                <div style={{height: `${isPhoneClientWidth ? 36 : 6}rem`}}
                                                     className={`backgroundWhite ${isPhoneClientWidth ? 'borderSmallGrayLinePhone borderRadiusPhone' : 'borderSmallGrayLine borderRadius'} flexColumn`}>
                                                    {poolPositionsLength > 0
                                                        ? [
                                                            <div
                                                                className={`boxSizingBorderBox ${isPhoneClientWidth ? 'paddingBigPhone smallFontSizePhone borderBottomSmallGrayLinePhone' : 'paddingBig smallFontSize borderBottomSmallGrayLine'}`}
                                                                style={{height: `${isPhoneClientWidth ? 10 : 15}%`}}>
                                                                Your positions ({poolPositionsLength})
                                                            </div>,
                                                            poolPositionsList('flexGrow1Column')
                                                        ]
                                                        : <div
                                                            className={`height100 flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}`}>
                                                            <img src={requireDefault('Group 427320250')}
                                                                 alt=""
                                                                 style={{width: `${isPhoneClientWidth ? 6 : 2}rem`}}/>
                                                            <pre
                                                                className={`textAlignCenter ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{`Your active liquidity positions will 
appear here.`}</pre>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>,
                                        <div className={isPhoneClientWidth ? '' : 'flexCenter'}
                                             style={{paddingTop: `${isPhoneClientWidth ? 0 : 1}rem`}}>
                                            <div style={isPhoneClientWidth ? {} : {width: '80%'}}
                                                 className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div className={'saf1'}>Pools</div>
                                                    <div
                                                        className={`borderRadius999 ${isPhoneClientWidth ? 'paddingBigger borderSmallGrayLinePhone childrenSpacingLeftSmallPhone' : 'paddingSmall borderSmallGrayLine childrenSpacingLeft'} flex alignItemsCenter backgroundWhite`}>
                                                        <img src={requireDefault('search')}
                                                             onClick={() => searchToken()}
                                                             alt="" className={'cursorPointer'}
                                                             style={{width: `${isPhoneClientWidth ? 1 : .3}rem`}}/>
                                                        <input placeholder='Search Tokens' type="text"
                                                               className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}
                                                               style={{width: `${isPhoneClientWidth ? 9 : 2.5}rem`}}
                                                               value={searchName}
                                                               onKeyUp={e => searchSymbolOnKeyUp(e, searchToken)}
                                                               onChange={e => setData({searchName: e.target.value})}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={isPhoneClientWidth ? 'overflowXScroll' : ''}>
                                                    {(() => {
                                                        const arr = ['#', 'Pool', ...['APR', 'TVL'].map(v => <div
                                                                className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'} flexGrow1`}>
                                                                <img src={requireDefault('bottomArrow1')}
                                                                     alt=""
                                                                     style={{width: `${isPhoneClientWidth ? .75 : .25}rem`}}/>
                                                                <div
                                                                    className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>{v}</div>
                                                            </div>), 'amount(X+X’/Y+Y’)', '1D vol', '7D vol', '1D vol/TVL'],
                                                            itemClass = i => `flex alignItemsCenter ${(i === 0 || i >= arr.length - 3) ? '' : 'flexGrow1'} ${[0, 1].includes(i) ? '' : (i === arr.length - 1 ? 'justifyContentFlexEnd' : 'justifyContentCenter')}`
                                                        return <div
                                                            className={'flexColumn'}
                                                            style={{height: `${isPhoneClientWidth ? 27 : 6.2}rem`, ...isPhoneClientWidth ? {width: '80rem'} : {}}}>
                                                            <div
                                                                className={`backgroundGrey boxSizingBorderBox flexCenter ${isPhoneClientWidth ? 'borderSmallGrayLinePhone' : 'borderSmallGrayLine'}`}
                                                                style={{
                                                                    height: `${isPhoneClientWidth ? 2.4 : .8}rem`,
                                                                    padding: `${isPhoneClientWidth ? 1.7 : .4}rem`,
                                                                    borderTopLeftRadius: `${isPhoneClientWidth ? .9 : .3}rem`,
                                                                    borderTopRightRadius: `${isPhoneClientWidth ? .9 : .3}rem`
                                                                }}>
                                                                {arr.map((v, i) => <div
                                                                    className={itemClass(i)}
                                                                    style={i === 0 ? {width: `${isPhoneClientWidth ? 2 : .6}rem`} : (i >= arr.length - 3 ? {width: `${isPhoneClientWidth ? 5 : 2.5}rem`} : {})}
                                                                >
                                                                    {typeof v === 'string' ? <div
                                                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}
                                                                        style={{
                                                                            color: '#7D7D7D',
                                                                        }}>{v}</div> : v}
                                                                </div>)}
                                                            </div>
                                                            <div ref={tokensPoolsScroll}
                                                                 onScroll={tokensPoolsOnScroll}
                                                                 className={'flexGrow1Column backgroundWhite overflowYScroll'}>
                                                                {pools.map((v, i) => {
                                                                    const {name} = v, nameArr = name.split('/')
                                                                    v = {
                                                                        ...v,
                                                                        currency0Symbol: checkData([v, 'currency0Symbol'], nameArr[0]),
                                                                        currency1Symbol: checkData([v, 'currency1Symbol'], nameArr[1]),
                                                                    }
                                                                    const tvl = ovl(v, 'tvl'),
                                                                        oneDayVol = ovl(v, 'oneDayVol')
                                                                    return <div
                                                                        style={{padding: `${isPhoneClientWidth ? 1 : .2}rem ${isPhoneClientWidth ? 1.7 : .4}rem`}}
                                                                        className={'flexCenter justifyContentSpaceBetween boxSizingBorderBox'}>
                                                                        {[
                                                                            <div
                                                                                style={{fontSize: `${isPhoneClientWidth ? .81 : .27}rem`}}
                                                                                className={'colorGray'}>{i + 1}</div>,
                                                                            <div
                                                                                className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                                                                {tokenIcon(v, `${isPhoneClientWidth ? 1.5 : .5}rem`)}
                                                                                <div
                                                                                    style={{fontSize: `${isPhoneClientWidth ? .8 : .27}rem`}}>
                                                                                    {v.name}
                                                                                </div>
                                                                            </div>,
                                                                            `${apr(v)}%`, tvl, v.amountFormat, oneDayVol, ovl(v, 'sevenDayVol'), divide(oneDayVol, tvl)].map((v, i) =>
                                                                            <div
                                                                                className={itemClass(i)}
                                                                                style={i === 0 ? {width: `${isPhoneClientWidth ? 2 : .6}rem`} : (i >= arr.length - 3 ? {width: `${isPhoneClientWidth ? 5 : 2.5}rem`} : {})}>
                                                                                {typeof v === 'string' ? <div
                                                                                    className={isPhoneClientWidth ? '' : ''}
                                                                                    style={isPhoneClientWidth ? {fontSize: '.8rem'} : {fontSize: '.27rem'}}>{v}</div> : v}
                                                                            </div>)}
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                    })()}
                                                </div>
                                            </div>
                                        </div>]]
                        return <div>
                            {isPhoneClientWidth ? pageTopFootPhone(<div className={'positionRelative zIndex1'}>
                                <div style={{
                                    top: 0,
                                    height: '30.04rem',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundImage: `url(${requireDefault('tradeBGPhone')})`
                                }} className={'positionAbsolute width100 zIndex-1'}/>
                                <div className={'childrenSpacingTopBiggerPhone paddingBigPhone'}
                                     style={{
                                         paddingTop: '4rem', paddingBottom: `${isHandelLiquidity ? 19 : 3}rem`
                                     }}
                                >
                                    {content}
                                </div>
                            </div>) : <div>
                                {pageTop}
                                <div style={{
                                    minHeight: '17rem',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundImage: `url(${requireDefault('tradeBG')})`
                                }}>
                                    {content}
                                </div>
                                {pageFoot}
                            </div>}
                            {positionsPoolsContent}
                            {selectTokenContent}
                            {liquidityContent}
                            {loading}
                        </div>
                    })(),
                    dashboard: (() => {
                        const tGAuthorizePoints = defaultAuthorizePoints,
                            tGAuthorizeAuthorizeKey = 'tg',
                            isTwitterAuthorized = checkData0(data, 'xPoint') > 0,
                            isTGAuthorized = checkData0(data, 'tgPoint') > 0,
                            twitterTGAuthorize = [
                                [isTwitterAuthorized, twitterAuthorizeData],
                                [isTGAuthorized, {
                                    authorizeKey: tGAuthorizeAuthorizeKey,
                                    authorizePoints: tGAuthorizePoints,
                                    title: 'Join TG Group',
                                }],
                            ],
                            content = <div className={'flexColumnCenter'}
                                           style={{
                                               paddingTop: `${isPhoneClientWidth ? 0 : 2}rem`,
                                               ...isPhoneClientWidth ? {paddingBottom: '10rem'} : {}
                                           }}>
                                <img className={'zIndex1'} src={requireDefault('airdrop')} alt=""
                                     style={{
                                         ...square(`${isPhoneClientWidth ? 12 : 3.3}rem`),
                                         marginBottom: `${isPhoneClientWidth ? -8.5 : -2.3}rem`,
                                         marginLeft: `${isPhoneClientWidth ? 16 : -5.5}rem`
                                     }}/>
                                <div
                                    className={`${isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'flex childrenSpacingLeftBigger'}`}>
                                    <div
                                        className={`boxSizingBorderBox ${isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBig'}`}
                                        style={{
                                            width: `${isPhoneClientWidth ? 28 : 11.5}rem`,
                                            padding: `${isPhoneClientWidth ? 1.5 : .5}rem ${isPhoneClientWidth ? 1.3 : .7}rem`,
                                            borderRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                            background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255, 255, 255, .2) 100%)'
                                        }}>
                                        <div
                                            className={`${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} flex alignItemsCenter`}>
                                            <img src={requireDefault('DROPLETS')} alt=""
                                                 style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                            <div>Droplets</div>
                                        </div>
                                        <div
                                            className={`saf1`}
                                            style={{fontSize: `${isPhoneClientWidth ? 1.7 : .5}rem`}}>
                                            {checkData0(data, 'points')}
                                        </div>
                                        <div style={{border: 'solid .02rem #999797', opacity: .5}}/>
                                        <div
                                            className={`flex childrenFlexGrow1 ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                            {
                                                [
                                                    ['Migrate', checkData0(data, 'migratePoints')],
                                                    ['LIKWID.FI', checkData0(data, 'LIKWIDFipoints')]
                                                ].map(v => <div
                                                    className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                                    <div
                                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} colorGray`}>{v[0]}</div>
                                                    <div
                                                        className={`${isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'} saf1`}>{v[1]}</div>
                                                </div>)
                                            }
                                        </div>
                                        <div style={{border: 'solid .02rem #999797', opacity: .5}}/>
                                        <div
                                            className={`saf1`}
                                            style={{fontSize: `${isPhoneClientWidth ? 1.7 : .4}rem`}}>
                                            Tasks
                                        </div>
                                        {
                                            [
                                                ['Swap any token', 'swapAnyToken', 100],
                                                ['Margin buy/sell any token', 'marginAnyToken', 200],
                                                ['Provide liquidity to any pools', 'provideAnyPools', 200],
                                                (() => {
                                                    const identifyTeamIsNotNull = isNotNull(checkData([data, 'identifyTeam']))
                                                    return ['Identify product defects and have them \nrecognized by the development team.', 'identifyTeam', 800, identifyTeamIsNotNull ? 'Done' : 'Report', () => !identifyTeamIsNotNull && windowOpen('https://t.me/likwid_fi')]
                                                })(),
                                                (() => {
                                                    const provideTeamIsNotNull = isNotNull(checkData([data, 'provideTeam']))
                                                    return ['Provide constructive suggestions for the product \nthat are adopted by the development team.', 'provideTeam', 800, provideTeamIsNotNull ? 'Done' : 'Report', () => !provideTeamIsNotNull && windowOpen('https://t.me/likwid_fi')]
                                                })(),
                                                ...twitterTGAuthorize.map(
                                                    v => {
                                                        const [isAuthorized, aD] = v
                                                        return [aD.title, `${aD.authorizeKey}Point`, aD.authorizePoints, isAuthorized ? 'Done' : 'Authorize', () => !isAuthorized && toggleShowAuthorize(true, undefined, aD), `Complete ${aD.authorizeKey.toLocaleUpperCase()} authorization before starting the task`]
                                                    }
                                                )
                                            ].map(v => {
                                                const isDone = v[3] === 'Done',
                                                    p = checkData([data, v[1]])
                                                return <div
                                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                    <div
                                                        className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} flexGrow1`}>
                                                <pre style={{lineHeight: '1.5'}}
                                                     className={`${isPhoneClientWidth ? '' : 'smallFontSize'} flexGrow1`}>
                                                    {v[0]}
                                                    {v[5] && <div
                                                        style={{borderColor: 'rgba(0,0,0,.3)', borderWidth: '.07rem'}}
                                                        className={`colorGray ${isPhoneClientWidth ? 'borderBottomSmallLinePhone smallFontSizePhone' : 'smallerFontSize'}`}>{v[5]}</div>}

                                                    {isPhoneClientWidth && v[3] &&
                                                        <div
                                                            className={'flex alignItemsCenter justifyContentSpaceBetween paddingTopPhone'}>
                                                            <div
                                                                style={{width: `${isPhoneClientWidth ? 3 : 1.2}rem`, ...isNotNull(p) ? {} : {color: '#CCCCCC'}}}
                                                                className={`saf1 ${isNotNull(p) ? 'activeColor' : ''} ${isPhoneClientWidth ? '' : 'smallFontSize'}`}>+ {percentageNum(checkData([p], v[2]))}</div>
                                                            <div
                                                                onClick={checkDataFunction(v, 4)}
                                                                className={`borderRadius999 ${isDone ? '' : 'backgroundActiveColor'} colorWhite saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} cursorPointer flexCenter`}
                                                                style={{
                                                                    ...isDone
                                                                        ? {background: '#4BD695'}
                                                                        : {},
                                                                    width: `${isPhoneClientWidth ? 6 : 1.6}rem`,
                                                                    height: v[3] ? `${isPhoneClientWidth ? 1.7 : .5}rem` : 0
                                                                }}>
                                                                {isDone ? [
                                                                    v[3],
                                                                    <img
                                                                        src={requireDefault('Ellipse 45')}
                                                                        alt=""
                                                                        style={{
                                                                            width: `${isPhoneClientWidth ? .7 : .25}rem`,
                                                                            marginLeft: `${isPhoneClientWidth ? .3 : .1}rem`
                                                                        }}
                                                                    />
                                                                ] : v[3]}
                                                            </div>
                                                        </div>
                                                    }
                                            </pre>
                                                    </div>
                                                    {(!isPhoneClientWidth || !v[3]) &&
                                                        <div
                                                            style={{width: `${isPhoneClientWidth ? 3 : 1.2}rem`, ...isNotNull(p) ? {} : {color: '#CCCCCC'}}}
                                                            className={`saf1 ${isNotNull(p) ? 'activeColor' : ''} ${isPhoneClientWidth ? '' : 'smallFontSize'}`}>+ {percentageNum(checkData([p], v[2]))}</div>}
                                                    {!isPhoneClientWidth && <div
                                                        onClick={checkDataFunction(v, 4)}
                                                        className={`borderRadius999 ${isDone ? '' : 'backgroundActiveColor'} colorWhite flexCenter saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} cursorPointer flexCenter`}
                                                        style={{
                                                            ...isDone
                                                                ? {background: '#4BD695'}
                                                                : {},
                                                            width: `${isPhoneClientWidth ? 6 : 1.6}rem`,
                                                            height: v[3] ? `${isPhoneClientWidth ? 1.7 : .5}rem` : 0
                                                        }}>
                                                        {isDone ? [
                                                            v[3],
                                                            <img
                                                                src={requireDefault('Ellipse 45')}
                                                                alt=""
                                                                style={{
                                                                    width: `${isPhoneClientWidth ? .7 : .25}rem`,
                                                                    marginLeft: `${isPhoneClientWidth ? .3 : .1}rem`
                                                                }}
                                                            />
                                                        ] : v[3]}
                                                    </div>}
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className={'childrenSpacingTop'}>
                                        <div
                                            className={`boxSizingBorderBox ${isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}`}
                                            style={{
                                                width: `${isPhoneClientWidth ? 28 : 11.5}rem`,
                                                padding: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                borderRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                background: 'rgba(255,185,194,.2)'
                                            }}>
                                        <pre
                                            className={`saf1 textAlignCenter`}
                                            style={{fontSize: `${isPhoneClientWidth ? 1.7 : .45}rem`}}>
                                            {`Likwid Total Pre-${isPhoneClientWidth ? '\n' : ''}committed TVL`}
                                        </pre>
                                            <div style={{borderRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,}}
                                                 className={`flexColumnCenter backgroundWhite paddingBigger`}>
                                                <div
                                                    className={`flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}`}>
                                                    <div>Totally TVL</div>
                                                    <div
                                                        className={`activeColor saf1 ${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'} ${opacityShow ? 'opacityShow' : 'opacityHidden'}`}>
                                                        ${toFixed(checkData0(data, 'lpTvl'), 2)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`boxSizingBorderBox ${isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}`}
                                            style={{
                                                width: `${isPhoneClientWidth ? 28 : 11.5}rem`,
                                                padding: `${isPhoneClientWidth ? 1.5 : .5}rem ${isPhoneClientWidth ? 1.5 : .7}rem`,
                                                borderRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                background: 'linear-gradient(180deg, rgba(255, 185, 194, .1) 0%, rgba(255, 255, 255, .1) 100%)'
                                            }}>
                                            <div
                                                className={`flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}`}>
                                                <div onClick={() => setData({showPointsChains: !showPointsChains})}
                                                     className={'positionRelative flexColumnCenter cursorPointer'}>
                                                    <div
                                                        className={`borderRadius999 backgroundWhite ${isPhoneClientWidth ? 'paddingPhone childrenSpacingLeftPhone' : 'padding childrenSpacingLeft'} flex alignItemsCenter`}>
                                                        <img src={requireDefault(`chainIcons/${pointsChainId}`)}
                                                             alt=""
                                                             style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                                        <div>{pointsChain[1]}</div>
                                                        <img
                                                            src={requireDefault(showPointsChains ? 'toTop' : 'toBottom')}
                                                            alt=""
                                                            style={{width: `${isPhoneClientWidth ? .6 : .2}rem`}}/>
                                                    </div>
                                                    {showPointsChains && <div
                                                        className={`positionAbsolute backgroundWhite ${isPhoneClientWidth ? 'borderRadiusPhone paddingBigPhone childrenSpacingTopPhone' : 'borderRadius paddingBig childrenSpacingTop'}`}
                                                        style={{top: `${isPhoneClientWidth ? 3 : 1}rem`}}
                                                    >
                                                        {
                                                            pointsChains.map((v, i) => <div
                                                                onClick={() => {
                                                                    setData({pointsChainIndex: i})
                                                                    localStorage.setItem('pointsChainIndex', i)
                                                                }}
                                                                style={{
                                                                    width: `${isPhoneClientWidth ? 9 : 3}rem`,
                                                                    height: `${isPhoneClientWidth ? 2.4 : .8}rem`
                                                                }}
                                                                className={`borderRadius999 flex alignItemsCenter ${isPhoneClientWidth ? 'paddingLeftPhone childrenSpacingLeftPhone' : 'paddingLeft childrenSpacingLeft'} ${pointsChainIndex == i ? 'backgroundActiveColor' : ''}`}>
                                                                <img src={requireDefault(`chainIcons/${v[0]}`)}
                                                                     alt=""
                                                                     style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                                                <div>{v[1]}</div>
                                                            </div>)
                                                        }
                                                    </div>}
                                                </div>
                                                <div
                                                    className={`${isPhoneClientWidth ? '' : 'flex childrenFlexGrow1'} width100`}>
                                                    {[
                                                        ['My uniswap V4 positions', '(only ETH & BNB Pools supported):', `${num8(checkData0(data, 'v4postions'))} ${checkDataBool(data, 'v4postionsIsBNB') ? 'BNB' : 'ETH'}`],
                                                        [[
                                                            'LP Activity Points',
                                                            ...pointsChainId === 130
                                                                ? [<span style={{color: '#FF0000'}}
                                                                         className={`saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}> (4x Acceleration)</span>]
                                                                : []
                                                        ], '(only ETH & BNB Pools supported):', lpActivityPointsIsNotNull ? `${num8(checkData0(lpActivityPoints))} Droplets` : 'Scoring after Pre-commitment is confirmed'],
                                                    ].map((v, i) => <div
                                                        style={i !== 0 ? (
                                                            isPhoneClientWidth
                                                                ? {borderTop: `dashed .3vw rgba(0,0,0,.4)`}
                                                                : {borderLeft: `dashed .1vw rgba(0,0,0,.4)`}
                                                        ) : {}}
                                                        className={`${isPhoneClientWidth ? 'childrenSpacingTopPhone paddingTopBottomBigPhone' : 'childrenSpacingTop paddingTopBottom'}`}>
                                                        <div
                                                            className={`textAlignCenter ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>{v[0]}</div>
                                                        {(lpActivityPointsIsNotNull || i === 0) && <div
                                                            className={`colorGray textAlignCenter ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}
                                                            style={i !== 0 ? {opacity: 0} : {}}>{v[1]}</div>}
                                                        <div
                                                            className={`textAlignCenter ${i !== 0 && !lpActivityPointsIsNotNull ? 'colorBlack' : `${i !== 0 ? 'activeColor' : ''} ${isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'}`} saf1`}>{v[2]}</div>
                                                    </div>)}
                                                </div>
                                                <div
                                                    className={`flexColumnCenter ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTop'}`}>
                                                    <div
                                                        className={`${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} textAlignCenter`}>
                                                        I commit to migrating my V4 position to the Likwid hook
                                                        after
                                                        the
                                                        Likwid mainnet is launched.
                                                    </div>
                                                    <div
                                                        onClick={() => !lpActivityPointsIsNotNull && switchEthereumChain(
                                                            () => loginThenFunc(
                                                                () => {
                                                                    toggleShowLoading(true)
                                                                    requestApi('uniswap/pre/committed', {
                                                                        positionsType: 0,
                                                                        chainId: pointsChainId,
                                                                    }, 'post').then(v => {
                                                                        toggleShowLoading()
                                                                        toastSuccessful(v)
                                                                        lpPoint()
                                                                    })
                                                                }
                                                            ),
                                                            undefined,
                                                            true
                                                        )}
                                                        className={`borderRadius999 ${lpActivityPointsIsNotNull ? 'backGroundBlueGray' : 'backgroundActiveColor'} colorWhite ${isPhoneClientWidth ? '' : 'smallFontSize'} cursorPointer flexCenter`}
                                                        style={{
                                                            padding: `0 ${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                            height: `${isPhoneClientWidth ? 1.7 : .5}rem`
                                                        }}>
                                                        Pre-commitment
                                                    </div>
                                                    <div
                                                        className={`activeColor textAlignCenter ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}`}>
                                                        Note: To claim LP Activity points, you must actually migrate
                                                        your v4 position to the LIKWID mainnet launch before redeeming
                                                        them.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        return <div>
                            {isPhoneClientWidth ? pageTopFootPhone(<div className={'positionRelative zIndex1'}>
                                    <div style={{
                                        top: 0,
                                        height: '30.04rem',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        backgroundImage: `url(${requireDefault('tradeBGPhone')})`
                                    }} className={'positionAbsolute width100 zIndex-1'}/>
                                    <div className={'childrenSpacingTopBiggerPhone paddingBigPhone'}
                                         style={{
                                             paddingTop: '4rem', paddingBottom: `${isHandelLiquidity ? 19 : 3}rem`
                                         }}
                                    >
                                        {content}
                                    </div>
                                </div>)
                                : <div>
                                    {pageTop}
                                    <div style={{
                                        minHeight: '17rem',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        backgroundImage: `url(${requireDefault('tradeBG')})`
                                    }}>
                                        {content}
                                    </div>
                                    {pageFoot}
                                </div>}
                            {
                                (() => {
                                    const showAuthorize = checkDataBool(data, 'showAuthorize'),
                                        {authorizePoints} = authorizeData
                                    return showAuthorize &&
                                        <div className={'positionFixed flexColumnCenter'}
                                             style={{
                                                 top: 0,
                                                 left: 0,
                                                 right: 0,
                                                 bottom: 0,
                                                 zIndex: 1000,
                                                 background: 'rgba(0,0,0,.56)',
                                             }}>
                                            <div
                                                className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox backgroundWhite`}
                                                style={{
                                                    width: isPhoneClientWidth ? '84%' : '9rem',
                                                    maxHeight: '100vh',
                                                    ...ternaryObject(
                                                        !isPhoneClientWidth,
                                                        {borderRadius: '.5rem'}
                                                    ),
                                                    padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                                }}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div className={'saf'}
                                                         style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                                        {authorizeData.title}
                                                    </div>
                                                    <img onClick={() => toggleShowAuthorize()}
                                                         className={'cursorPointer'}
                                                         src={requireDefault('close')}
                                                         alt=""
                                                         style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                                </div>
                                                <div
                                                    className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBigger'}>
                                                    <div
                                                        className={`${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} flexCenter`}>
                                                        <img src={requireDefault('integral')} alt=""
                                                             style={{width: `${isPhoneClientWidth ? 2.7 : .9}rem`}}/>
                                                        <div
                                                            className={`saf1 ${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'}`}>
                                                            {authorizePoints}
                                                        </div>
                                                    </div>
                                                    {
                                                        (() => {
                                                            const verify = () => requestApi(
                                                                    `${authorizeKey}/verify`,
                                                                    undefined,
                                                                    'post'
                                                                ).then(r => {
                                                                    toggleShowLoading()
                                                                    if (isNotNull(r)) {
                                                                        toastSuccessful(true)
                                                                        getTask().then(v => setData({
                                                                            points: percentageNum(checkData0(v, -1)),
                                                                            migratePoints: percentageNum(checkData0(v, -2)),
                                                                            LIKWIDFipoints: percentageNum(checkData0(v, -3)),
                                                                            xPoint: checkData([v, 10, 'point']),
                                                                            tgPoint: checkData([v, 11, 'point']),
                                                                        }))
                                                                        toggleShowAuthorize()
                                                                    } else if (authorizeKey === tGAuthorizeAuthorizeKey) {
                                                                        toast('Send my code on LIKWID Community')
                                                                        setData({tGShareDone: false})
                                                                    }
                                                                }),
                                                                isTwitterBinded = checkDataBool(data, 'isTwitterBinded')
                                                            return (isTwitterBinded || !authorizeData.needBind)
                                                                ? <div
                                                                    className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTop'}>
                                                                    {
                                                                        {
                                                                            x: () => {
                                                                                const twitterFollowDone = checkDataBool(data, 'twitterFollowDone'),
                                                                                    twitterShareDone = checkDataBool(data, 'twitterShareDone')
                                                                                return [
                                                                                    ['Follow @likwid_fi on X', 'Follow',
                                                                                        () => {
                                                                                            setData({twitterFollowDone: true})
                                                                                            windowOpen('https://x.com/intent/user?screen_name=likwid_fi')
                                                                                        }, true, twitterFollowDone],
                                                                                    ['Share likwid platform on X', 'Share',
                                                                                        () => {
                                                                                            setData({twitterShareDone: true})
                                                                                            windowOpen(`https://x.com/share?text=${encodeURIComponent(`The testnet is Officially LIVE! Dive in, test it out, and experience the future of permissionless and oracle-free margin trading protocol in Web3🌐  
https://likwid.fi

Share this post and earn points! Let’s build the future together! 🚀💧"
#LIKWID #LIKWIDFI`)}`)
                                                                                        }, twitterFollowDone, twitterShareDone],
                                                                                    [`Get ${twitterAuthorizePoints} droplets`, 'Verify', () => {
                                                                                        toggleShowLoading(true)
                                                                                        verify()
                                                                                    }, twitterFollowDone && twitterShareDone],
                                                                                ]
                                                                            },
                                                                            tg: () => {
                                                                                const tGFollowDone = checkDataBool(data, 'tGFollowDone'),
                                                                                    tGShareDone = checkDataBool(data, 'tGShareDone')
                                                                                return [
                                                                                    ['Join the LIKWID Community', 'Join',
                                                                                        () => {
                                                                                            setData({tGFollowDone: true})
                                                                                            windowOpen(likwidMemeTG)
                                                                                        }, true, tGFollowDone],
                                                                                    ['Send my code on LIKWID Community', 'Send',
                                                                                        () => {
                                                                                            setData({tGShareDone: true})
                                                                                            requestApi('tg/code', {address}).then(code =>
                                                                                                (isPhoneClientWidth ? windowReplaceExternal : windowOpen)(`https://telegram.me/share/url?url=%2fcode%20likwid_${code}`))
                                                                                        }, tGFollowDone, tGShareDone],
                                                                                    [`Get ${tGAuthorizePoints} droplets`, 'Verify', () => {
                                                                                        toggleShowLoading(true)
                                                                                        verify()
                                                                                    }, tGFollowDone && tGShareDone],
                                                                                ]
                                                                            }
                                                                        }[authorizeKey]().map(v => {
                                                                            const isTrue = v[3], isDone = v[4]
                                                                            return <div
                                                                                className={'flexCenter justifyContentSpaceBetween'}>
                                                                                <div
                                                                                    className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>{v[0]}</div>
                                                                                <div style={{
                                                                                    padding: `${isPhoneClientWidth ? .2 : .1}rem 0`,
                                                                                    width: `${isPhoneClientWidth ? 4 : 1.3}rem`,
                                                                                    ...ternaryObject(
                                                                                        isDone,
                                                                                        {background: isDone ? '#4BD695' : '#FF7789'}
                                                                                    )
                                                                                }}
                                                                                     onClick={() => isTrue && !isDone && v[2]()}
                                                                                     className={`${isTrue ? 'backgroundActiveColor' : 'backgroundWhite'} flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone borderSmallGrayLinePhone' : 'childrenSpacingLeftSmall borderSmallGrayLine'} borderRadius999 cursorPointer`}>
                                                                                    <div
                                                                                        className={`${isTrue ? 'colorWhite' : 'colorGray'} ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}`}>{isDone ? 'Done' : v[1]}</div>
                                                                                    {isDone &&
                                                                                        <img
                                                                                            src={requireDefault('Ellipse 45')}
                                                                                            alt=""
                                                                                            style={{width: `${isPhoneClientWidth ? .7 : .25}rem`}}
                                                                                        />}
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                                : [
                                                                    <div
                                                                        className={`textAlignCenter ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}>
                                                                        Complete {authorizeKey.toLocaleUpperCase()} authorization
                                                                        before
                                                                        starting the
                                                                        task
                                                                    </div>,
                                                                    checkDataBool(data, 'isCloudflare')
                                                                        ? cloudflareHumanDiv
                                                                        : <div
                                                                            onClick={() => {
                                                                                const code_challenge = require('uuid').v4().slice(0, 10)
                                                                                windowReplaceExternal(
                                                                                    'https://twitter.com/i/oauth2/authorize?response_type=code' +
                                                                                    '&client_id=MUpkUS14Y3VFaWNGV1hFQnpoNUk6MTpjaQ' +
                                                                                    `&redirect_uri=${redirectURI}` +
                                                                                    '&scope=offline.access%20tweet.read%20users.read' +
                                                                                    `&state=${[
                                                                                        code_challenge,
                                                                                    ].join('_')}` +
                                                                                    `&code_challenge=${code_challenge}` +
                                                                                    '&code_challenge_method=plain'
                                                                                )
                                                                            }}
                                                                            className={`saf1 backgroundActiveColor colorWhite flexCenter cursorPointer width100 ${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone borderRadiusPhone' : 'childrenSpacingLeftBig borderRadius'}`}
                                                                            style={{
                                                                                padding: `${isPhoneClientWidth ? .75 : .25}rem 0`,
                                                                            }}>
                                                                            Authorize
                                                                        </div>
                                                                ]
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                })()
                            }
                            {positionsPoolsContent}
                            {selectTokenContent}
                            {liquidityContent}
                            {loading}
                        </div>
                    })()
                } : {},
            },
            pathNames = Object.keys(paths), [firstPath] = pathNames, useRefEffect = (fn = () => {
            }, inputs = []) => {
                const didMountRef = useRef(false)
                useEffect(() => {
                    fn(didMountRef.current)
                    if (!didMountRef.current) didMountRef.current = true
                }, inputs);
            }, maxSecond = 5, setMarginSecond = checkData0(data, 'setMarginSecond'),
            reloadMargin = setMarginSecond === maxSecond,
            setTransitionPriceSecond = checkData0(data, 'setTransitionPriceSecond'),
            reloadSwap = setTransitionPriceSecond === maxSecond;
        [
            [
                () => {
                    setData({init: true})
                    const getTop = () => setData({
                        isTop: (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) === 0
                    })
                    getTop()
                    window.addEventListener('scroll', getTop)
                },
            ],
            [
                () => {
                    if (devCount === 10) {
                        sessionDev ? sessionStorage.removeItem('dev') : sessionStorage.setItem('dev', 1)
                        location.reload()
                    }
                },
                [devCount]
            ],
            ...isDashboard
                ? [
                    [
                        () => {
                            const getLPTvl = () => {
                                setData({opacityShow: false})
                                return requestApi('uniswap/lp/tvl').then(lpTvl => setTimeout(() => setData({
                                    lpTvl,
                                    opacityShow: true
                                }), 500)).catch(() => setTimeout(
                                    () => setData({opacityShow: true}), 500
                                )).then(() => setTimeout(getLPTvl, 5000))
                            }
                            getLPTvl()
                        }
                    ],
                    [
                        () => {
                            if (address) {
                                getTask().then(v => {
                                        setData({
                                            points: percentageNum(checkData0(v, -1)),
                                            xPoint: checkData([v, 10, 'point']),
                                            tgPoint: checkData([v, 11, 'point']),
                                            migratePoints: percentageNum(checkData0(v, -2)),
                                            LIKWIDFipoints: percentageNum(checkData0(v, -3)),
                                            swapAnyToken: checkData([v, 7, 'point']),
                                            marginAnyToken: checkData([v, 8, 'point']),
                                            provideAnyPools: checkData([v, 9, 'point']),
                                            identifyTeam: checkData([v, 12, 'point']),
                                            provideTeam: checkData([v, 13, 'point']),
                                        })
                                        loginThenFunc(lpPoint)
                                    }
                                )
                            }
                        },
                        [address]
                    ],
                    [
                        () => {
                            if (address) {
                                loginThenFunc(lpPoint)
                            }
                        },
                        [pointsChainId]
                    ],
                    [
                        () => {
                            isNotNullOrEmpty(twitterCode) && address && token() && bindCheck().then(
                                isTwitterBinded => !isTwitterBinded && getTask().then(v => checkData0(v, 10, 'point') <= 0 && toggleShowAuthorize(true, true))
                            )
                        },
                        [isNotNull(address)]
                    ],
                    [
                        () => {
                            // console.log('cloudflareUpdate');
                            const setIntervalCloudflareUpdate = checkData([data, 'setIntervalCloudflareUpdate'])
                            if (isNotNull(setIntervalCloudflareUpdate)) {
                                clearInterval(setIntervalCloudflareUpdate)
                            }
                            setData({
                                setIntervalCloudflareUpdate: setInterval(() =>
                                    setData({cloudflareUpdate: checkData0(data, 'cloudflareUpdate') + 1}), 1000)
                            })
                            if (isNotNull(cloudflareHuman)) {
                                _turnstileCb(cloudflareHuman)
                            }
                        },
                        [cloudflareHuman],
                    ],
                    [
                        () => {
                            if (isNotNullOrEmpty(cloudflareHumanToken)) {
                                requestApi(
                                    `${authorizeKey}/bind`,
                                    {
                                        redirectURI,
                                        code: twitterCode,
                                        token: cloudflareHumanToken,
                                        codeVerifier: checkDataString(urlState, 0)
                                    },
                                    'post'
                                ).then(r => isNotNull(r) && setData({
                                    ...ternaryObject(
                                        isTwitterAuthorizeAuthorize(authorizeKey),
                                        {isTwitterBinded: true}
                                    ),
                                    showAuthorize: true
                                }))
                            }
                        },
                        [cloudflareHumanToken]
                    ]
                ]
                : [],
            ...isHome
                ? []
                : [
                    [
                        () => {
                            if (address) {
                                slaveContractETH.getBalance(address).then(ethBalance => setData({ethBalance: numberETH(ethBalance)}))
                            }
                        },
                        [address]
                    ],
                    [
                        () => {
                            const {current} = focusInput
                            current && current.focus()
                        }
                    ],
                    [
                        () => {
                            getEthUSDTPrice().then(ethUSDT => setData({ethUSDT}))
                        }
                    ],
                    [
                        () => {
                            searchToken()
                            isSelectToken && requestApi(
                                `token/recommended/${chainId}`
                            ).then(v => setData({
                                recommendTokens: v.map(v => ({
                                    ...v,
                                    avatar: v.logo,
                                    tokenAddress: v.address,
                                }))
                            }))
                            !isHome && !isSelectToken && searchToken(true)
                        },
                    ],
                    [
                        () => {
                            if (tokenRecentSearchTokens.length > 0) {
                                if (isSelectToken && address && showSelectToken) {
                                    axios({
                                        method: 'post',
                                        url: rpc,
                                        headers: {'Content-Type': 'application/json'},
                                        data: JSON.stringify({
                                            jsonrpc: '2.0',
                                            method: 'alchemy_getTokenBalances',
                                            headers: {'Content-Type': 'application/json'},
                                            params: [`${address}`, 'erc20'],
                                            id: chainId
                                        })
                                    }).then(response =>
                                            setData({
                                                yourTokens: checkDataArray(response, 'data', 'result', 'tokenBalances')
                                                    .filter(token => token['tokenBalance'] !== '0x0000000000000000000000000000000000000000000000000000000000000000' &&
                                                        tokenRecentSearchTokens.map(v => checkDataString(v, 'tokenAddress').toLowerCase()).includes(checkDataString(token, 'contractAddress').toLowerCase())
                                                    ).map(v => {
                                                        const tokenMetadata = checkDataObject(tokenRecentSearchTokensMetadatas, checkDataString(v, 'contractAddress').toLowerCase())
                                                        return {
                                                            balance: numberETH(utils.hexToNumber(v['tokenBalance']), tokenMetadata.decimals),
                                                            ...tokenMetadata
                                                        }
                                                    })
                                            })
                                        // {
                                        //     // console.log(response);
                                        //     // const nonZeroBalances =
                                        //     //     response['data']['result']['tokenBalances'].filter(token => {
                                        //     //         return token['tokenBalance'] !== '0'
                                        //     //     })
                                        //     // for (const i in nonZeroBalances) {
                                        //     //     const token = nonZeroBalances[i]
                                        //     //     let balance = token['tokenBalance']
                                        //     //     console.log(Web3.utils.hexToNumber(balance));
                                        //     //     // const tokenAddress = token['contractAddress']
                                        //     //     // axios({
                                        //     //     //     method: 'post',
                                        //     //     //     url: rpc,
                                        //     //     //     headers: {'Content-Type': 'application/json'},
                                        //     //     //     data: JSON.stringify({
                                        //     //     //         jsonrpc: '2.0',
                                        //     //     //         method: 'alchemy_getTokenMetadata',
                                        //     //     //         params: [`${tokenAddress}`],
                                        //     //     //         id: chainId
                                        //     //     //     })
                                        //     //     // }).then(metadata => {
                                        //     //     //     metadata = metadata.data.result
                                        //     //     //     console.log(metadata);
                                        //     //     //     balance = balance / Math.pow(10, metadata['decimals']);
                                        //     //     //     balance = balance.toFixed(2);
                                        //     //     //     console.log(`${tokenAddress} ${metadata['name']}: ${balance} ${metadata['symbol']}`)
                                        //     //     // }).catch(error => console.log('error', error))
                                        //     // }
                                        // }
                                    ).catch(() => {
                                    })
                                }
                            }
                        },
                        [JSON.stringify(tokenRecentSearchTokens), address, showSelectToken]
                    ],
                    ...tokenDatas.map(v => {
                        const {tokenAddress} = v
                        return [
                            () => {
                                if (tokenAddress) {
                                    address ? erc20CallBalance(tokenAddress).then(([balance, decimals]) => setTokenData(v, {
                                        decimals, balance: numberETH(balance, decimals)
                                    })) : erc20CallDecimals(tokenAddress).then(decimals => setTokenData(v, {decimals}))
                                }
                            },
                            [tokenAddress, chainId, address]
                        ]
                    }),
                    ...(() => {
                        const func = () => {
                            if (poolDataPoolIdIsNotNull) {
                                const needOpacityShow = !isPool && (!isNotNullOrEmpty(newAmountStr) || isBorrow)
                                if (needOpacityShow) {
                                    setData({opacityShow: false})
                                }
                                marginHookManagerCall('getReserves', poolDataPoolId).then(({
                                                                                               0: reserve0, 1: reserve1
                                                                                           }) => {
                                        needOpacityShow
                                            ? setTimeout(
                                                () => setData({
                                                    reserve0,
                                                    reserve1,
                                                    opacityShow: true
                                                }),
                                                500
                                            )
                                            : setData({
                                                reserve0,
                                                reserve1,
                                            })
                                    }
                                ).catch(() => {
                                    if (needOpacityShow) {
                                        setTimeout(
                                            () => setData({opacityShow: true}),
                                            500
                                        )
                                    }
                                })
                            }
                        }
                        return [
                            [
                                func,
                                [chainId, poolDataPoolId]
                            ],
                            [
                                () => {
                                    if (reloadMargin) {
                                        func()
                                    }
                                },
                                [reloadMargin]
                            ]
                        ]
                    })(),
                    [
                        () => {
                            if (isSwap) {
                                if (greaterThan(newAmount, swapMax)) {
                                    toast(`The maximum amount is  ${swapMax}`)
                                }
                            }
                        },
                        [newAmount, swapMax]
                    ],
                    [
                        () => {
                            if (isPool && poolDataPoolIdIsNotNull) {
                                marginLiquidityCall('getPoolSupplies', marginHookManagerAddress, poolDataPoolId).then(totalLiquidity => marginLiquidityCall('getPoolLiquidities', poolDataPoolId, address).then(ownerLiquidity => {
                                    ownerLiquidity = ownerLiquidity[liquidityLevel - 1]
                                    setData({
                                        ownerLiquidity, currentShares: divide(ownerLiquidity, totalLiquidity[0]),
                                    })
                                }))
                            }
                        },
                        [chainId, address, poolDataPoolId]
                    ],
                    [
                        () => {
                            if (isPool && address) {
                                searchPositionPool(1)
                            }
                        },
                        [address]
                    ],
                    ...(() => {
                        const func = () => {
                            if (isTrade && poolDataPoolIdIsNotNull) {
                                marginFeesCall('getInitialLTV', marginHookManagerAddress, poolDataPoolId)
                                    .then(_initialLTV => marginFeesCall('getLiquidationLTV', marginHookManagerAddress, poolDataPoolId)
                                        .then(_liquidationLTV => marginHookManagerCall('getStatus', poolDataPoolId)
                                            .then(v => marginFeesCall('getPoolFees', marginHookManagerAddress, poolDataPoolId)
                                                .then(({
                                                           _fee, _marginFee, _protocolFee, _protocolMarginFee
                                                       }) => setData({
                                                    swapReserve0: checkData0(v, 'realReserve0'),
                                                    swapReserve1: checkData0(v, 'realReserve1'),
                                                    initialLTV: numToPercentage(_initialLTV),
                                                    liquidationLTV: numToPercentage(_liquidationLTV),
                                                    fee: numToPercentage(plus(_marginFee, _protocolMarginFee)),
                                                    swapFee: numToPercentage(plus(_fee, _protocolFee)),
                                                    _fee
                                                })))))
                            }
                        }
                        return [
                            [func, [chainId, poolDataPoolId]],
                            [
                                () => {
                                    if (isMargin ? reloadMargin : reloadSwap) {
                                        func()
                                    }
                                },
                                [isMargin ? reloadMargin : reloadSwap]
                            ]
                        ]
                    })(),
                    [
                        () => {
                            if (isSwap && poolDataPoolIdIsNotNull) {
                                const swapReserve = marginToken0TokenAddress === poolData.currency0 ? swapReserve1 : swapReserve0
                                greaterThan(swapReserve, 0) && getAmountInFunc(
                                    BigInt(toInt(minus(swapReserve, 1))), undefined, undefined).then(swapMax =>
                                    setData({swapMax: lessThanOrEqualTo(swapMax, 0) ? 0 : numberETH(minus(swapMax, 1), marginToken0DataDecimals)})
                                )
                            }
                        },
                        [swapReserve0, swapReserve1, poolDataPoolId, zeroForOne]],
                    ...(() => {
                        const func = () => {
                            if (isTrade && poolDataPoolIdIsNotNull) {
                                marginFeesCall('getBorrowRate', marginHookManagerAddress, poolDataPoolId, marginForOne)
                                    .then(v => setData({apy: numToPercentage(v)}))
                            }
                        }
                        return [
                            [
                                func,
                                [chainId, poolDataPoolId, marginForOne]
                            ],
                            [
                                () => {
                                    if (reloadMargin) {
                                        func()
                                    }
                                },
                                [reloadMargin]
                            ]
                        ]
                    })(),
                    ...(() => {
                        const func = () => {
                            if (isTrade && poolDataPoolIdIsNotNull) {
                                setTransitionPrice()
                            }
                        }
                        return [[func, [chainId, poolDataPoolId, zeroForOne, newAmountWei]], [() => {
                            if (reloadMargin) {
                                func()
                            }
                        }, [reloadMargin]],]
                    })(),
                    [
                        () => {
                            if (isMargin) {
                                setTimeout(() => setData({setMarginSecond: (setMarginSecond === maxSecond ? 0 : setMarginSecond) + 1}), 1000)
                            }
                        },
                        [setMarginSecond]
                    ],
                    [() => {
                        if (isSwap) {
                            setTimeout(() => setData({setTransitionPriceSecond: (setTransitionPriceSecond === maxSecond ? 0 : setTransitionPriceSecond) + 1}), 1000)
                            if (poolDataPoolIdIsNotNull && setTransitionPriceSecond === maxSecond) {
                                setData({opacityShow: false})
                                setTransitionPrice(true)
                            }
                        }
                    },
                        [setTransitionPriceSecond, poolDataPoolId]],
                    ...(() => {
                        const func = () => {
                            if (isTrade && poolDataPoolIdIsNotNull) {
                                setData({unitPrice: num8(divide(newAmount, transitionPrice))})
                            }
                        }
                        return [
                            [
                                func,
                                [chainId, poolDataPoolId, newAmount, transitionPrice]
                            ],
                            [
                                () => {
                                    if (reloadMargin) {
                                        func()
                                    }
                                },
                                [reloadMargin]
                            ],
                        ]
                    })(),
                    ...[
                        [
                            () => marginFeesCall('minMarginLevel').then(minMarginLevel =>
                                setData({minMarginLevel: divide(minMarginLevel, 1000000)})
                            )
                        ],
                        [
                            () => {
                                if (poolDataPoolIdIsNotNull) {
                                    getAmountOut(toInt(divide(newAmountWei, minMarginLevel)), undefined, undefined, marginToken0DataDecimals).then(borrowMaxAmount =>
                                        setNewBorrowAmount(borrowMaxAmount, borrowMaxAmount)
                                    )
                                }
                            },
                            [newAmountWei, marginForOne, minMarginLevel, marginToken0DataDecimals]
                        ],
                        [
                            () => setData({
                                borrowMarginLevel:
                                    toFixed(
                                        equalTo(newBorrowAmount, borrowMaxAmount)
                                            ? minMarginLevel
                                            : divide(minMarginLevel, divide(newBorrowAmount, borrowMaxAmount)),
                                        2
                                    )
                            }),
                            [minMarginLevel, newBorrowAmount, borrowMaxAmount]
                        ],
                        [
                            () => {
                                borrowMaxAmountToast()
                            },
                            [newBorrowAmount, borrowMaxAmount]
                        ]
                    ].map(v => [
                        () => {
                            if (isBorrow) {
                                v[0]()
                            }
                        },
                        v[1]
                    ]),
                    ...(() => {
                        const func = () => {
                            if (isMargin && poolDataPoolIdIsNotNull) {
                                (isBorrow ? borrowPositionManagerCall : marginPositionManagerCall)(
                                    'getMarginMax', poolDataPoolId, marginForOne, ...isBorrow ? [] : [leverage]
                                ).then(({marginMax}) => setData({marginMax: numberETH(marginMax, marginToken0DataDecimals)}))
                            }
                        }
                        return [
                            [func, [chainId, poolDataPoolId, zeroForOne, leverage, marginToken0DataDecimals]], [() => {
                                if (reloadMargin) {
                                    func()
                                }
                            },
                                [reloadMargin]
                            ]
                        ]
                    })(),
                    [
                        () => {
                            if (!leverageIsComposite) {
                                toast(`Influence must be greater than ${leverageMin} and less than ${leverageMax}`)
                            }
                        },
                        [leverage]
                    ],
                    [
                        () => {
                            if (!isNotNullOrEmpty(newAmountStr) || isBorrow) {
                                setData({
                                    entryToken: num8(reserve0DivideReserve1)
                                })
                            }
                        },
                        [newAmountStr, reserve0DivideReserve1]
                    ],
                    [
                        () => {
                            if (isMargin) {
                                setData({newAmount: undefined, newAmountWei: undefined, inputNewAmount: undefined})
                            }
                        },
                        [isMarginSell, poolDataPoolId]
                    ],
                    ...(() => {
                        const func = (needToast = true) => {
                            if (isMargin && poolDataPoolIdIsNotNull && newAmountNumber > 0 && !isBorrow) {
                                if (marginIsComposite && leverageIsComposite) {
                                    setData({opacityShow: false})
                                    marginPositionManagerCall('getMarginTotal', poolDataPoolId, marginForOne, leverage, newAmountWei)
                                        .then(({marginWithoutFee: marginTotal, borrowAmount}) => setTimeout(
                                            () => setData({
                                                marginTotal, borrowAmount,
                                                ...isNotNullOrEmpty(newAmountStr)
                                                    ? {
                                                        entryToken: num8(divide(
                                                            ...(() => {
                                                                const arr = [
                                                                    numberETH(borrowAmount, marginToken1DataDecimals),
                                                                    numberETH(marginTotal, marginToken0DataDecimals)
                                                                ]
                                                                return isMarginSell ? arr.reverse() : arr
                                                            })()
                                                        ))
                                                    }
                                                    : {},
                                                opacityShow: true
                                            }), 500)).catch(() =>
                                        setTimeout(() => setData({
                                            opacityShow: true
                                        }), 500))
                                } else {
                                    needToast && toast(`The maximum amount is ${marginMax}`)
                                }
                            }
                        }
                        return [
                            [func, [isMarginSell, chainId, zeroForOne, newAmountWei, marginMax]], [() => {
                                if (reloadMargin) {
                                    func(false)
                                }
                            },
                                [reloadMargin]
                            ]
                        ]
                    })(),
                    ...(() => {
                        const func = () => {
                            if (isMargin && poolDataPoolIdIsNotNull && isETHPool && !token0IsETH) {
                                getAmountOut(parseEtherNum(entryToken, token0Decimals), !zeroForOne).then(entryTokenETH => setData({entryTokenETH}))
                            }
                        }
                        return [
                            [func, [chainId, poolDataPoolId, zeroForOne, entryToken, isETHPool, token0IsETH, token0Decimals]], [() => {
                                if (reloadMargin) {
                                    func()
                                }
                            }, [reloadMargin]
                            ],
                        ]
                    })(),
                    ...(() => {
                        const func = () => {
                            if (isMargin && poolDataPoolIdIsNotNull && isETHPool && !token0IsETH) {
                                getAmountOut(parseEtherNum(liqToken, token0Decimals)).then(liqTokenETH => setData({liqTokenETH}))
                            }
                        }
                        return [
                            [func, [chainId, poolDataPoolId, zeroForOne, liqToken, isETHPool, token0IsETH, token0Decimals]], [() => {
                                if (reloadMargin) {
                                    func()
                                }
                            },
                                [reloadMargin]
                            ],
                        ]
                    })(), ...(() => {
                        const func = () => {
                            if (isMargin && poolDataPoolIdIsNotNull && isETHPool && !marginToken0IsETH) {
                                getAmountOut(parseEtherNum(showLiqToken, marginToken0DataDecimals)).then(showLiqTokenETH => setData({showLiqTokenETH}))
                            }
                        }
                        return [
                            [func, [chainId, poolDataPoolId, zeroForOne, showLiqToken, isETHPool, marginToken0IsETH, marginToken0DataDecimals]],
                            [
                                () => {
                                    if (reloadMargin) {
                                        func()
                                    }
                                },
                                [reloadMargin]
                            ],
                        ]
                    })(),
                    [
                        () => {
                            if (isMargin && poolDataPoolIdIsNotNull && address) {
                                marginPositionManagerCall('getPositionId', poolDataPoolId, marginForOne, address)
                                    .then(marginPositionId => setData({marginPositionId}))
                            }
                        },
                        [poolDataPoolId, marginForOne, address]
                    ],
                    ...(() => {
                        const func = () => {
                            if (isMargin && marginPositionId) {
                                marginPositionManagerCall('getPosition', marginPositionId).then(({
                                                                                                     marginAmount,
                                                                                                     marginTotal,
                                                                                                     borrowAmount,
                                                                                                 }) => setData({
                                    positionMarginAmount: marginAmount,
                                    positionMarginTotal: marginTotal,
                                    positionBorrowAmount: borrowAmount,
                                }))
                            }
                        }
                        return [
                            [
                                func,
                                [chainId, marginPositionId]
                            ],
                            [
                                () => {
                                    if (reloadMargin) {
                                        func()
                                    }
                                },
                                [reloadMargin]
                            ]
                        ]
                    })(),
                    [
                        () => {
                            if (showRepaying && !repayTokenDataNewAmountEligible) {
                                toast(`The maximum amount is ${repayTokenDataBorrowAmount}`)
                            }
                        },
                        [showRepaying, repayTokenDataNewAmountWei, repayTokenDataBorrowAmount]
                    ],
                    [
                        () => {
                            if (showRepaying && !repayTokenDataBorrowTokenIsETH && repayTokenDataNewAmountEligible) {
                                getRepayTokenPrice(repayTokenDataNewAmountWei).then(price => {
                                    getRepayTokenPrice(parseEtherNum(repayTokenDataBorrowAmount, repayTokenDataBorrowDecimals)).then(borrowPrice => {
                                        const afterRepayTokenDataBorrowAmountWei = parseEtherNum(afterRepayTokenDataBorrowAmount, repayTokenDataBorrowDecimals)
                                        getRepayTokenPrice(afterRepayTokenDataBorrowAmountWei > 0 ? afterRepayTokenDataBorrowAmountWei : 0).then(afterBorrowPrice => setData({
                                            repayTokenData: {
                                                ...repayTokenData, price, borrowPrice, afterBorrowPrice
                                            }
                                        }))
                                    })
                                })
                            }
                        },
                        [repayTokenDataPoolId, repayTokenDataNewAmountWei]
                    ],
                    [
                        () => {
                            if (isSelectTokenNotNeedToken && token1AddressIsNotNull &&
                                (!isPoolIncrease || !urlPoolId || checkDataBool(data, 'urlPoolGetEnd'))) {
                                tokenABPool(token0Address, token1Address).then(v =>
                                    v
                                        ? setData({poolData: v})
                                        : toast('Pool does not exist')
                                )
                            }
                        },
                        [token1AddressIsNotNull]
                    ],
                    [
                        () => {
                            if (isSwap && marginToken0TokenAddress !== marginToken1TokenAddress) {
                                tokenABPool(marginToken0TokenAddress, marginToken1TokenAddress).then(v => setData({
                                    poolData: checkDataObject(v)
                                }))
                            }
                        },
                        [marginToken0TokenAddress, marginToken1TokenAddress]
                    ],
                    [
                        () => {
                            if (showClosePosition) {
                                estimatePNL(repayTokenDataPositionId, range).then(closePNL => setData({closePNL}))
                            }
                        },
                        [repayTokenDataNewAmountWei, repayTokenDataPositionId]
                    ],
                    [
                        () => {
                            if (showSelectToken && searchNameIsETHAddress && !allTokensIncludesSearchName &&
                                !searchAddressTokening
                            ) {
                                setData({searchAddressTokening: true})
                                erc20ContractData(searchName, true).then(
                                    v => setData({
                                        searchAddressTokens: v ? [v] : undefined,
                                        searchAddressTokening: undefined
                                    })
                                )
                            } else {
                                setData({searchAddressTokens: undefined})
                            }
                        },
                        [searchNameToLowerCase]
                    ],
                    [
                        () => {
                            setData({searchName: ''})
                        },
                        [showSelectToken]
                    ]
                ]
        ].map(v => useEffect(checkDataFunction(v, 0), checkDataArray(v, 1)));
        [
            [
                isLoadEnd => !isHome && isLoadEnd && getTokensPools(),
                [page]
            ],
            [
                isLoadEnd => {
                    if (!isHome && isLoadEnd && address) {
                        getPositionPool(0)
                    }
                },
                [positionPage, isHistory, isBorrowPositions]
            ],
            [
                isLoadEnd => {
                    if (!isHome && isLoadEnd && address) {
                        getPositionPool(1)
                    }
                },
                [poolPositionPage]
            ],
            [
                isLoadEnd => {
                    if (isLoadEnd) {
                        location.reload()
                    }
                },
                [chainId]
            ],
            [
                isLoadEnd => {
                    isLoadEnd && prevAddress.current && sessionStorage.removeItem('token')
                    prevAddress.current = address
                    isDashboard && token() && bindCheck(twitterAuthorizeAuthorizeKey).then(isTwitterBinded => setData({isTwitterBinded}))
                },
                [address]
            ]
        ].map(v => useRefEffect(...v));

        function replaceHome() {
            windowReplace(firstPath)
        }

        // useAppKitAccount({
        //     onConnect: () => {
        //     },
        //     onDisconnect: () => {
        //     },
        // })
        // useAppKitNetwork()
        return [contextHolder,
            <Routes onChang={(() => document.title = `LIKWID${pathname ? `-${pNs1 ? pathname : pNs0}` : ''}`)()}>
                <Route path='*' element={<Navigate to={firstPath}/>}/>
                {pathNames.map(v => <Route key={v} path={v} element={paths[v]}/>)}
            </Routes>]
    })
// createWeb3Modal({
//     wagmiConfig,
//     projectId,
//     chains,
//     themeVariables: {'--w3m-accent': '#FFB9C2'},
//     featuredWalletIds: ['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150', '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'],
//     chainImages: (() => {
//         const walletConnectChainIcon = chain => requireDefault(`walletConnectChainIcons/${chain}`)
//         return {
//             1: walletConnectChainIcon('ETH'),
//             56: walletConnectChainIcon('BNB'),
//             97: walletConnectChainIcon('BNB'),
//             59144: walletConnectChainIcon('Linea'),
//             8453: walletConnectChainIcon('Base'),
//             534352: walletConnectChainIcon('Scroll'),
//             42161: walletConnectChainIcon('Arbitrum'),
//             10: walletConnectChainIcon('Optimism'),
//             1101: walletConnectChainIcon('Polygon zkEVM'),
//             81457: walletConnectChainIcon('Blast'),
//             60808: walletConnectChainIcon('BOB'),
//             421614: walletConnectChainIcon('Arbitrum'),
//             2442: walletConnectChainIcon('Polygon zkEVM'),
//             111: walletConnectChainIcon('BOB'),
//             167000: walletConnectChainIcon('Taiko'),
//             28516: walletConnectChainIcon('Vizing'),
//             28518: walletConnectChainIcon('Vizing'),
//         }
//     })()
// })
createAppKit({
    projectId,
    networks: chains,
    adapters: [wagmiAdapter],
    metadata: {
        name: 'LIKWID',
        description: 'LIKWID',
        url: 'https://likwid.fi',
        icons: ['https://likwid.fi/logo.png'],
    },
    features: {
        socials: [],
        send: false,
        email: false,
        swaps: false,
        onramp: false,
        emailShowWallets: false
    },
    themeVariables: {'--w3m-accent': '#FFB9C2'},
    chainImages: (() => {
        const walletConnectChainIcon = chain => requireDefault(`walletConnectChainIcons/${chain}`)
        return {
            1: walletConnectChainIcon('ETH'),
            56: walletConnectChainIcon('BNB'),
            97: walletConnectChainIcon('BNB'),
            1301: walletConnectChainIcon('Unichain'),
            59144: walletConnectChainIcon('Linea'),
            8453: walletConnectChainIcon('Base'),
            534352: walletConnectChainIcon('Scroll'),
            42161: walletConnectChainIcon('Arbitrum'),
            10: walletConnectChainIcon('Optimism'),
            1101: walletConnectChainIcon('Polygon zkEVM'),
            81457: walletConnectChainIcon('Blast'),
            60808: walletConnectChainIcon('BOB'),
            421614: walletConnectChainIcon('Arbitrum'),
            2442: walletConnectChainIcon('Polygon zkEVM'),
            111: walletConnectChainIcon('BOB'),
            10143: walletConnectChainIcon('Monad'),
            167000: walletConnectChainIcon('Taiko'),
            28516: walletConnectChainIcon('Vizing'),
            28518: walletConnectChainIcon('Vizing'),
            11155111: walletConnectChainIcon('ETH'),
        }
    })()
})
export default () => <WagmiProvider config={wagmiConfig}>
    <QueryClientProvider client={queryClient}><Profile/></QueryClientProvider>
</WagmiProvider>