export default {
    "address": "0xda54E290F2bdDD9b3df7e2f1f23Da8Dbd3e6e597",
    "abi": [
        {
            "type": "constructor",
            "inputs": [
                {
                    "name": "initialOwner",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "ONE_BILLION",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "ONE_MILLION",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "YEAR_SECONDS",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "dynamicFee",
            "inputs": [
                {
                    "name": "status",
                    "type": "tuple",
                    "internalType": "struct HookStatus",
                    "components": [
                        {
                            "name": "realReserve0",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "realReserve1",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "mirrorReserve0",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "mirrorReserve1",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "marginFee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "blockTimestampLast",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "interestRatio0X112",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "interestRatio1X112",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "rate0CumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "rate1CumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "marginTimestampLast",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "lastPrice1X112",
                            "type": "uint224",
                            "internalType": "uint224"
                        },
                        {
                            "name": "key",
                            "type": "tuple",
                            "internalType": "struct PoolKey",
                            "components": [
                                {
                                    "name": "currency0",
                                    "type": "address",
                                    "internalType": "Currency"
                                },
                                {
                                    "name": "currency1",
                                    "type": "address",
                                    "internalType": "Currency"
                                },
                                {
                                    "name": "fee",
                                    "type": "uint24",
                                    "internalType": "uint24"
                                },
                                {
                                    "name": "tickSpacing",
                                    "type": "int24",
                                    "internalType": "int24"
                                },
                                {
                                    "name": "hooks",
                                    "type": "address",
                                    "internalType": "contract IHooks"
                                }
                            ]
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "_fee",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "dynamicFeeDurationSeconds",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "dynamicFeeUnit",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "feeTo",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getBorrowRate",
            "inputs": [
                {
                    "name": "hook",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                },
                {
                    "name": "marginForOne",
                    "type": "bool",
                    "internalType": "bool"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getBorrowRate",
            "inputs": [
                {
                    "name": "status",
                    "type": "tuple",
                    "internalType": "struct HookStatus",
                    "components": [
                        {
                            "name": "realReserve0",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "realReserve1",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "mirrorReserve0",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "mirrorReserve1",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "marginFee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "blockTimestampLast",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "interestRatio0X112",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "interestRatio1X112",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "rate0CumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "rate1CumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "marginTimestampLast",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "lastPrice1X112",
                            "type": "uint224",
                            "internalType": "uint224"
                        },
                        {
                            "name": "key",
                            "type": "tuple",
                            "internalType": "struct PoolKey",
                            "components": [
                                {
                                    "name": "currency0",
                                    "type": "address",
                                    "internalType": "Currency"
                                },
                                {
                                    "name": "currency1",
                                    "type": "address",
                                    "internalType": "Currency"
                                },
                                {
                                    "name": "fee",
                                    "type": "uint24",
                                    "internalType": "uint24"
                                },
                                {
                                    "name": "tickSpacing",
                                    "type": "int24",
                                    "internalType": "int24"
                                },
                                {
                                    "name": "hooks",
                                    "type": "address",
                                    "internalType": "contract IHooks"
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "marginForOne",
                    "type": "bool",
                    "internalType": "bool"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getBorrowRateByReserves",
            "inputs": [
                {
                    "name": "realReserve",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "mirrorReserve",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "rate",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getBorrowRateCumulativeLast",
            "inputs": [
                {
                    "name": "status",
                    "type": "tuple",
                    "internalType": "struct HookStatus",
                    "components": [
                        {
                            "name": "realReserve0",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "realReserve1",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "mirrorReserve0",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "mirrorReserve1",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "marginFee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "blockTimestampLast",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "interestRatio0X112",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "interestRatio1X112",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "rate0CumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "rate1CumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "marginTimestampLast",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "lastPrice1X112",
                            "type": "uint224",
                            "internalType": "uint224"
                        },
                        {
                            "name": "key",
                            "type": "tuple",
                            "internalType": "struct PoolKey",
                            "components": [
                                {
                                    "name": "currency0",
                                    "type": "address",
                                    "internalType": "Currency"
                                },
                                {
                                    "name": "currency1",
                                    "type": "address",
                                    "internalType": "Currency"
                                },
                                {
                                    "name": "fee",
                                    "type": "uint24",
                                    "internalType": "uint24"
                                },
                                {
                                    "name": "tickSpacing",
                                    "type": "int24",
                                    "internalType": "int24"
                                },
                                {
                                    "name": "hooks",
                                    "type": "address",
                                    "internalType": "contract IHooks"
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "marginForOne",
                    "type": "bool",
                    "internalType": "bool"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getBorrowRateCumulativeLast",
            "inputs": [
                {
                    "name": "hook",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                },
                {
                    "name": "marginForOne",
                    "type": "bool",
                    "internalType": "bool"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getInterests",
            "inputs": [
                {
                    "name": "status",
                    "type": "tuple",
                    "internalType": "struct HookStatus",
                    "components": [
                        {
                            "name": "realReserve0",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "realReserve1",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "mirrorReserve0",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "mirrorReserve1",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "marginFee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "blockTimestampLast",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "interestRatio0X112",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "interestRatio1X112",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "rate0CumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "rate1CumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "marginTimestampLast",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "lastPrice1X112",
                            "type": "uint224",
                            "internalType": "uint224"
                        },
                        {
                            "name": "key",
                            "type": "tuple",
                            "internalType": "struct PoolKey",
                            "components": [
                                {
                                    "name": "currency0",
                                    "type": "address",
                                    "internalType": "Currency"
                                },
                                {
                                    "name": "currency1",
                                    "type": "address",
                                    "internalType": "Currency"
                                },
                                {
                                    "name": "fee",
                                    "type": "uint24",
                                    "internalType": "uint24"
                                },
                                {
                                    "name": "tickSpacing",
                                    "type": "int24",
                                    "internalType": "int24"
                                },
                                {
                                    "name": "hooks",
                                    "type": "address",
                                    "internalType": "contract IHooks"
                                }
                            ]
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "interest0",
                    "type": "uint112",
                    "internalType": "uint112"
                },
                {
                    "name": "interest1",
                    "type": "uint112",
                    "internalType": "uint112"
                }
            ],
            "stateMutability": "pure"
        },
        {
            "type": "function",
            "name": "getInterests",
            "inputs": [
                {
                    "name": "hook",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                }
            ],
            "outputs": [
                {
                    "name": "interest0",
                    "type": "uint112",
                    "internalType": "uint112"
                },
                {
                    "name": "interest1",
                    "type": "uint112",
                    "internalType": "uint112"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getMarginLevels",
            "inputs": [],
            "outputs": [
                {
                    "name": "_minMarginLevel",
                    "type": "uint24",
                    "internalType": "uint24"
                },
                {
                    "name": "_liquidationMarginLevel",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "stateMutability": "pure"
        },
        {
            "type": "function",
            "name": "getPoolFees",
            "inputs": [
                {
                    "name": "hook",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                }
            ],
            "outputs": [
                {
                    "name": "_fee",
                    "type": "uint24",
                    "internalType": "uint24"
                },
                {
                    "name": "_marginFee",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "liquidationMarginLevel",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "minMarginLevel",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "owner",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "rateStatus",
            "inputs": [],
            "outputs": [
                {
                    "name": "rateBase",
                    "type": "uint24",
                    "internalType": "uint24"
                },
                {
                    "name": "useMiddleLevel",
                    "type": "uint24",
                    "internalType": "uint24"
                },
                {
                    "name": "useHighLevel",
                    "type": "uint24",
                    "internalType": "uint24"
                },
                {
                    "name": "mLow",
                    "type": "uint24",
                    "internalType": "uint24"
                },
                {
                    "name": "mMiddle",
                    "type": "uint24",
                    "internalType": "uint24"
                },
                {
                    "name": "mHigh",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "setDynamicFeeDurationSeconds",
            "inputs": [
                {
                    "name": "_dynamicFeeDurationSeconds",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "setDynamicFeeUnit",
            "inputs": [
                {
                    "name": "_dynamicFeeUnit",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "setFeeTo",
            "inputs": [
                {
                    "name": "_feeTo",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "setRateStatus",
            "inputs": [
                {
                    "name": "_status",
                    "type": "tuple",
                    "internalType": "struct RateStatus",
                    "components": [
                        {
                            "name": "rateBase",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "useMiddleLevel",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "useHighLevel",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "mLow",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "mMiddle",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "mHigh",
                            "type": "uint24",
                            "internalType": "uint24"
                        }
                    ]
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "transferOwnership",
            "inputs": [
                {
                    "name": "newOwner",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "event",
            "name": "OwnershipTransferred",
            "inputs": [
                {
                    "name": "user",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "newOwner",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                }
            ],
            "anonymous": false
        }
    ]
}