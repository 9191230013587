export default {
    "address": "0x0Df0e93b0aFBBfF7c6cc3c005eFAf7d85C0eA71A",
    "abi": [
        {
            "type": "constructor",
            "inputs": [
                {
                    "name": "initialOwner",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "_manager",
                    "type": "address",
                    "internalType": "contract IPoolManager"
                },
                {
                    "name": "_hook",
                    "type": "address",
                    "internalType": "contract IMarginHookManager"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "exactInput",
            "inputs": [
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct MarginRouter.SwapParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "zeroForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "to",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "amountIn",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "amountOutMin",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "amountOut",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "amountOut",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "payable"
        },
        {
            "type": "function",
            "name": "exactOutput",
            "inputs": [
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct MarginRouter.SwapParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "zeroForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "to",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "amountIn",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "amountOutMin",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "amountOut",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "amountIn",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "payable"
        },
        {
            "type": "function",
            "name": "handelSwap",
            "inputs": [
                {
                    "name": "sender",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct MarginRouter.SwapParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "zeroForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "to",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "amountIn",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "amountOutMin",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "amountOut",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "hook",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "contract IMarginHookManager"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "owner",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "poolManager",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "contract IPoolManager"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "transferOwnership",
            "inputs": [
                {
                    "name": "newOwner",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "unlockCallback",
            "inputs": [
                {
                    "name": "data",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "event",
            "name": "OwnershipTransferred",
            "inputs": [
                {
                    "name": "user",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "newOwner",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Swap",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "sender",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "amount0",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "amount1",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "fee",
                    "type": "uint24",
                    "indexed": false,
                    "internalType": "uint24"
                }
            ],
            "anonymous": false
        },
        {
            "type": "error",
            "name": "InsufficientOutputReceived",
            "inputs": []
        },
        {
            "type": "error",
            "name": "LockFailure",
            "inputs": []
        },
        {
            "type": "error",
            "name": "NotPoolManager",
            "inputs": []
        },
        {
            "type": "error",
            "name": "NotSelf",
            "inputs": []
        }
    ]
}