export default {
    "address": "0x5B775Dee7ACA25bc05a0094a11BB122e02A28888",
    "abi": [
        {
            "type": "constructor",
            "inputs": [
                {
                    "name": "initialOwner",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "_manager",
                    "type": "address",
                    "internalType": "contract IPoolManager"
                },
                {
                    "name": "_mirrorTokenManager",
                    "type": "address",
                    "internalType": "contract IMirrorTokenManager"
                },
                {
                    "name": "_marginLiquidity",
                    "type": "address",
                    "internalType": "contract IMarginLiquidity"
                },
                {
                    "name": "_marginFees",
                    "type": "address",
                    "internalType": "contract IMarginFees"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "MINIMUM_LIQUIDITY",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "ONE_BILLION",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "ONE_MILLION",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "SQRT_RATIO_1_1",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint160",
                    "internalType": "uint160"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "YEAR_SECONDS",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "addLiquidity",
            "inputs": [
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct AddLiquidityParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "amount0",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "amount1",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "tickLower",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "tickUpper",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "level",
                            "type": "uint8",
                            "internalType": "uint8"
                        },
                        {
                            "name": "to",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "liquidity",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "payable"
        },
        {
            "type": "function",
            "name": "addPositionManager",
            "inputs": [
                {
                    "name": "_marginPositionManager",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "afterAddLiquidity",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct IPoolManager.ModifyLiquidityParams",
                    "components": [
                        {
                            "name": "tickLower",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "tickUpper",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "liquidityDelta",
                            "type": "int256",
                            "internalType": "int256"
                        },
                        {
                            "name": "salt",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "int256",
                    "internalType": "BalanceDelta"
                },
                {
                    "name": "",
                    "type": "int256",
                    "internalType": "BalanceDelta"
                },
                {
                    "name": "",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes4",
                    "internalType": "bytes4"
                },
                {
                    "name": "",
                    "type": "int256",
                    "internalType": "BalanceDelta"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "afterDonate",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes4",
                    "internalType": "bytes4"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "afterInitialize",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "uint160",
                    "internalType": "uint160"
                },
                {
                    "name": "",
                    "type": "int24",
                    "internalType": "int24"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes4",
                    "internalType": "bytes4"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "afterRemoveLiquidity",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct IPoolManager.ModifyLiquidityParams",
                    "components": [
                        {
                            "name": "tickLower",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "tickUpper",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "liquidityDelta",
                            "type": "int256",
                            "internalType": "int256"
                        },
                        {
                            "name": "salt",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "int256",
                    "internalType": "BalanceDelta"
                },
                {
                    "name": "",
                    "type": "int256",
                    "internalType": "BalanceDelta"
                },
                {
                    "name": "",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes4",
                    "internalType": "bytes4"
                },
                {
                    "name": "",
                    "type": "int256",
                    "internalType": "BalanceDelta"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "afterSwap",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct IPoolManager.SwapParams",
                    "components": [
                        {
                            "name": "zeroForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "amountSpecified",
                            "type": "int256",
                            "internalType": "int256"
                        },
                        {
                            "name": "sqrtPriceLimitX96",
                            "type": "uint160",
                            "internalType": "uint160"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "int256",
                    "internalType": "BalanceDelta"
                },
                {
                    "name": "",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes4",
                    "internalType": "bytes4"
                },
                {
                    "name": "",
                    "type": "int128",
                    "internalType": "int128"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "beforeAddLiquidity",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct IPoolManager.ModifyLiquidityParams",
                    "components": [
                        {
                            "name": "tickLower",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "tickUpper",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "liquidityDelta",
                            "type": "int256",
                            "internalType": "int256"
                        },
                        {
                            "name": "salt",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes4",
                    "internalType": "bytes4"
                }
            ],
            "stateMutability": "pure"
        },
        {
            "type": "function",
            "name": "beforeDonate",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes4",
                    "internalType": "bytes4"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "beforeInitialize",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "key",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "uint160",
                    "internalType": "uint160"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes4",
                    "internalType": "bytes4"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "beforeRemoveLiquidity",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct IPoolManager.ModifyLiquidityParams",
                    "components": [
                        {
                            "name": "tickLower",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "tickUpper",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "liquidityDelta",
                            "type": "int256",
                            "internalType": "int256"
                        },
                        {
                            "name": "salt",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes4",
                    "internalType": "bytes4"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "beforeSwap",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "key",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct IPoolManager.SwapParams",
                    "components": [
                        {
                            "name": "zeroForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "amountSpecified",
                            "type": "int256",
                            "internalType": "int256"
                        },
                        {
                            "name": "sqrtPriceLimitX96",
                            "type": "uint160",
                            "internalType": "uint160"
                        }
                    ]
                },
                {
                    "name": "",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes4",
                    "internalType": "bytes4"
                },
                {
                    "name": "",
                    "type": "int256",
                    "internalType": "BeforeSwapDelta"
                },
                {
                    "name": "",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "getAmountIn",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                },
                {
                    "name": "zeroForOne",
                    "type": "bool",
                    "internalType": "bool"
                },
                {
                    "name": "amountOut",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "amountIn",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getAmountOut",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                },
                {
                    "name": "zeroForOne",
                    "type": "bool",
                    "internalType": "bool"
                },
                {
                    "name": "amountIn",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "amountOut",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getHookPermissions",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct Hooks.Permissions",
                    "components": [
                        {
                            "name": "beforeInitialize",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "afterInitialize",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "beforeAddLiquidity",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "afterAddLiquidity",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "beforeRemoveLiquidity",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "afterRemoveLiquidity",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "beforeSwap",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "afterSwap",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "beforeDonate",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "afterDonate",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "beforeSwapReturnDelta",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "afterSwapReturnDelta",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "afterAddLiquidityReturnDelta",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "afterRemoveLiquidityReturnDelta",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                }
            ],
            "stateMutability": "pure"
        },
        {
            "type": "function",
            "name": "getReserves",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                }
            ],
            "outputs": [
                {
                    "name": "_reserve0",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "_reserve1",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getStatus",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                }
            ],
            "outputs": [
                {
                    "name": "_status",
                    "type": "tuple",
                    "internalType": "struct HookStatus",
                    "components": [
                        {
                            "name": "realReserve0",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "realReserve1",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "mirrorReserve0",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "mirrorReserve1",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "marginFee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "blockTimestampLast",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "interestRatio0X112",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "interestRatio1X112",
                            "type": "uint112",
                            "internalType": "uint112"
                        },
                        {
                            "name": "rate0CumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "rate1CumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "marginTimestampLast",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "lastPrice1X112",
                            "type": "uint224",
                            "internalType": "uint224"
                        },
                        {
                            "name": "key",
                            "type": "tuple",
                            "internalType": "struct PoolKey",
                            "components": [
                                {
                                    "name": "currency0",
                                    "type": "address",
                                    "internalType": "Currency"
                                },
                                {
                                    "name": "currency1",
                                    "type": "address",
                                    "internalType": "Currency"
                                },
                                {
                                    "name": "fee",
                                    "type": "uint24",
                                    "internalType": "uint24"
                                },
                                {
                                    "name": "tickSpacing",
                                    "type": "int24",
                                    "internalType": "int24"
                                },
                                {
                                    "name": "hooks",
                                    "type": "address",
                                    "internalType": "contract IHooks"
                                }
                            ]
                        }
                    ]
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "handleAddLiquidity",
            "inputs": [
                {
                    "name": "sender",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "key",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "amount0",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "amount1",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "handleMargin",
            "inputs": [
                {
                    "name": "_positionManager",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct MarginParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "marginForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "leverage",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "marginAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "marginTotal",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "borrowAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "borrowMinAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "recipient",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "marginWithoutFee",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "borrowAmount",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "handleRelease",
            "inputs": [
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct ReleaseParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "marginForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "payer",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "repayAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "releaseAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "rawBorrowAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "handleRemoveLiquidity",
            "inputs": [
                {
                    "name": "sender",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "key",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                },
                {
                    "name": "amount0",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "amount1",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "hookStatusStore",
            "inputs": [
                {
                    "name": "",
                    "type": "bytes32",
                    "internalType": "PoolId"
                }
            ],
            "outputs": [
                {
                    "name": "realReserve0",
                    "type": "uint112",
                    "internalType": "uint112"
                },
                {
                    "name": "realReserve1",
                    "type": "uint112",
                    "internalType": "uint112"
                },
                {
                    "name": "mirrorReserve0",
                    "type": "uint112",
                    "internalType": "uint112"
                },
                {
                    "name": "mirrorReserve1",
                    "type": "uint112",
                    "internalType": "uint112"
                },
                {
                    "name": "marginFee",
                    "type": "uint24",
                    "internalType": "uint24"
                },
                {
                    "name": "blockTimestampLast",
                    "type": "uint32",
                    "internalType": "uint32"
                },
                {
                    "name": "interestRatio0X112",
                    "type": "uint112",
                    "internalType": "uint112"
                },
                {
                    "name": "interestRatio1X112",
                    "type": "uint112",
                    "internalType": "uint112"
                },
                {
                    "name": "rate0CumulativeLast",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "rate1CumulativeLast",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "marginTimestampLast",
                    "type": "uint32",
                    "internalType": "uint32"
                },
                {
                    "name": "lastPrice1X112",
                    "type": "uint224",
                    "internalType": "uint224"
                },
                {
                    "name": "key",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "initialize",
            "inputs": [
                {
                    "name": "key",
                    "type": "tuple",
                    "internalType": "struct PoolKey",
                    "components": [
                        {
                            "name": "currency0",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "currency1",
                            "type": "address",
                            "internalType": "Currency"
                        },
                        {
                            "name": "fee",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "tickSpacing",
                            "type": "int24",
                            "internalType": "int24"
                        },
                        {
                            "name": "hooks",
                            "type": "address",
                            "internalType": "contract IHooks"
                        }
                    ]
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "kLast",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "margin",
            "inputs": [
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct MarginParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "marginForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "leverage",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "marginAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "marginTotal",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "borrowAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "borrowMinAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "recipient",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "tuple",
                    "internalType": "struct MarginParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "marginForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "leverage",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "marginAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "marginTotal",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "borrowAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "borrowMinAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "recipient",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "marginFees",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "contract IMarginFees"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "marginLiquidity",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "contract IMarginLiquidity"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "marginOracle",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "mirrorTokenManager",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "contract IMirrorTokenManager"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "owner",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "poolManager",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "contract IPoolManager"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "positionManagers",
            "inputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bool",
                    "internalType": "bool"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "release",
            "inputs": [
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct ReleaseParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "marginForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "payer",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "repayAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "releaseAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "rawBorrowAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "payable"
        },
        {
            "type": "function",
            "name": "removeLiquidity",
            "inputs": [
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct RemoveLiquidityParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "level",
                            "type": "uint8",
                            "internalType": "uint8"
                        },
                        {
                            "name": "liquidity",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "amount0",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "amount1",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "setFeeStatus",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                },
                {
                    "name": "_marginFee",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "setMarginFees",
            "inputs": [
                {
                    "name": "_marginFees",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "setMarginOracle",
            "inputs": [
                {
                    "name": "_oracle",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "transferOwnership",
            "inputs": [
                {
                    "name": "newOwner",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "unlockCallback",
            "inputs": [
                {
                    "name": "data",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "event",
            "name": "Burn",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "sender",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "liquidity",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "amount0",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "amount1",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Initialize",
            "inputs": [
                {
                    "name": "id",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "currency0",
                    "type": "address",
                    "indexed": true,
                    "internalType": "Currency"
                },
                {
                    "name": "currency1",
                    "type": "address",
                    "indexed": true,
                    "internalType": "Currency"
                },
                {
                    "name": "fee",
                    "type": "uint24",
                    "indexed": false,
                    "internalType": "uint24"
                },
                {
                    "name": "tickSpacing",
                    "type": "int24",
                    "indexed": false,
                    "internalType": "int24"
                },
                {
                    "name": "hooks",
                    "type": "address",
                    "indexed": false,
                    "internalType": "contract IHooks"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Mint",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "sender",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "to",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "liquidity",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "amount0",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "amount1",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "OwnershipTransferred",
            "inputs": [
                {
                    "name": "user",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "newOwner",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Sync",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "realReserve0",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "realReserve1",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "mirrorReserve0",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "mirrorReserve1",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                }
            ],
            "anonymous": false
        },
        {
            "type": "error",
            "name": "HookNotImplemented",
            "inputs": []
        },
        {
            "type": "error",
            "name": "InsufficientLiquidityBurnt",
            "inputs": []
        },
        {
            "type": "error",
            "name": "InsufficientLiquidityMinted",
            "inputs": []
        },
        {
            "type": "error",
            "name": "InvalidInitialization",
            "inputs": []
        },
        {
            "type": "error",
            "name": "InvalidPool",
            "inputs": []
        },
        {
            "type": "error",
            "name": "LockFailure",
            "inputs": []
        },
        {
            "type": "error",
            "name": "NotPoolManager",
            "inputs": []
        },
        {
            "type": "error",
            "name": "NotPositionManager",
            "inputs": []
        },
        {
            "type": "error",
            "name": "NotSelf",
            "inputs": []
        },
        {
            "type": "error",
            "name": "PairNotExists",
            "inputs": []
        }
    ]
}